import {
  IonAlert,
  IonContent,
  IonImg,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { useState } from "react";
import SignUpForm from "../../components/Auth/sign-forms/sign-up-form.component";
import { useUser } from "../../utils/contexts/user.context";
import "./signup-form.styles.scss";

const RegisterFormPage: React.FC = () => {
  const [showAlert, setShowAlert] = useState(true);
  const [segment, setSegment] = useState("particular");
  const { idToken, uid, username } = useUser();

  
  return (
    <IonPage>
      <div className="bg-white">
          <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8 bg-white shadow-xl" aria-label="Global">
              <div className="flex lg:flex-1">
                <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Ai4Chef</span>
                <IonImg className="h-8 w-auto" alt="Ai4Chef" src={process.env.PUBLIC_URL + "icon-192x192.png"}/>
                </a>
              </div>
              <div className="flex lg:hidden">
                <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
                  <span className="sr-only">Open main menu</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
              </div>
              <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                <a href="/login" className="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a>
              </div>
            </nav>
            <div className="lg:hidden" role="dialog" aria-modal="true">
              <div className="fixed inset-0 z-50"></div>
              <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                  <a href="" className="-m-1.5 p-1.5">
                    <span className="sr-only">Your Company</span>
                    <IonImg className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
                  </a>
                  <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700">
                    <span className="sr-only">Close menu</span>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      <a href="" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Home</a>
                      <a href="/about" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Sobre</a>
                      <a href="/register" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Aderir</a>
                    </div>
                    <div className="py-6">
                      <a href="/login" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          </div>
      <IonContent className="ion-padding form-content">
        
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={"Conte-nos mais sobre si."}
          message={"Por favor preencha os seguintes campos."}
          buttons={["OK"]}
        />        
        <div className="form-wrapper">
          <SignUpForm idToken={idToken} uid={uid} username={username} segment={segment}/>
        </div>
      </IonContent>
    </IonPage>
  );
};
/*
<IonSegment color="light" onIonChange={e => setSegment(e.detail.value)} value={segment}>
          <IonSegmentButton value="particular">
            <IonLabel>Particulares</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="collective">
            <IonLabel>Entidade Coletiva</IonLabel>
          </IonSegmentButton>
        </IonSegment>
*/
export default RegisterFormPage;