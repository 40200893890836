// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mytableTitle {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  padding: 0.2rem 2rem;
}

.table {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.componentTable {
  padding-bottom: 10px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

tr:first-child th:last-child {
  border-top-right-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/App/reusable/table/table-component.styles.scss"],"names":[],"mappings":"AAEA;EAEE,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EAA+B,8BAAA;AAD/B;;AAEA;EAA8B,+BAAA;AAE9B;;AAAA;EAAgC,2BAAA;AAIhC;;AAHA;EAA+B,4BAAA;AAO/B","sourcesContent":["\n\n.mytableTitle{\n  //color: #ffffff;\n  font-weight: bold;\n  font-size: 1.2rem;\n  text-align: center;\n  padding: 0.2rem 2rem;\n}\n\n.table{\n  width: 100%;\n  height: 100%;\n  border-radius: 10px;\n}\n\n.componentTable{\n  padding-bottom:     10px;\n}\n\ntr:last-child td:first-child { border-bottom-left-radius:5px; }\ntr:last-child td:last-child { border-bottom-right-radius:5px; }\n\ntr:first-child th:first-child { border-top-left-radius:5px; }\ntr:first-child th:last-child { border-top-right-radius:5px; }\n\n\n\n// @media (max-width: 768px) {\n//   .myTable{\n//     height: max-content;\n//   }\n\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
