import {
  IonContent,
  IonText,
  IonModal,
} from "@ionic/react";

import { useState, useEffect } from 'react';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import InfoComponent from "../reusable/info/info.component";
import Loading from "../reusable/loading/loading";
import { IoTrash } from "react-icons/io5";
import Toaster from "../reusable/Toaster";

const RestProdCategoriesPage = () => {

  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [changedList, setChangedList] = useState(false);
  const [prod_category, setProd_category] = useState("");
  const [prod_category_info, setProd_category_info] = useState("");

  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();
    const getData = async (endpoint, params = {}) => {
      const url = params
        ? `${endpoint}/${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `${endpoint}`;
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal
        });
        eval(`setData(response.data)`);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getData("list-prod-categories");

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);

  const deleteCategory = async (prod_category_id: string) => {
    const response = await axiosPrivate.post(`/delete-prod-categories/`,
      JSON.stringify({ prod_category_id: prod_category_id }),
    );

    if (response.status === 200) {
      Toaster.show('Categoria apagada com sucesso.', 'success')
      setLoading(true);
      setChangedList(!changedList);
    } else {
      Toaster.show('Erro ao apagar categoria.', 'error')
      setLoading(false);
    }
  };

  const handleSubmit = async () => {

    if (prod_category === "" || prod_category_info === "") {
      Toaster.show("Preencha os campos.", "error");
      return;
    }

    const data = {
      prod_category: prod_category,
      prod_category_info: prod_category_info,
    };
    try {
      const response = await axiosPrivate.post(`/create-prod-categories/`,
        JSON.stringify(data),
      );

      if (response.status === 201) {
        setLoading(true);
        setChangedList(!changedList);
        Toaster.show('Receita guardada com sucesso.', 'success')
      } else {
        Toaster.show('Erro ao adicionar categoria.', 'error')
      }
    } catch (error) {
      Toaster.show('Erro ao adicionar categoria.', 'error')
    }
  }

  const handleUpdate = async () => {

    try {

      const response = await axiosPrivate.post(`/rest_prod_apply_changes/`,
        {},
      );

      if (response.status === 200) {
        Toaster.show('Categorias atualizadas com sucesso.', 'success')
      } else {
        Toaster.show('Erro ao atualizar categorias.', 'error')
      }
    } catch (error) {
      console.log(error)
      Toaster.show('Erro ao atualizar categorias.', 'error')
    }
  }

  const darkMode = useDarkMode();

  return (
    <IonContent>
      
      {!loading && <div className='w-[70%] m-auto text-black'>
        <div className="flex items-center justify-between py-4">
          <IonText style={{ color: darkMode ? "white" : "black" }} className="text-4xl font-semibold text-blue-dark">Classes de produtos</IonText>
          <div className="flex flex-col gap-6">
            <button onClick={() => setModalVisibleCreate(true)} style={{ background: darkMode ? "#422655" : '' }} className="text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300">
              + Nova Classe
            </button>
            <button onClick={handleUpdate} style={{ background: darkMode ? "#422655" : '' }} className="text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300">
              Reclassificar produtos
            </button>
          </div>
        </div>
        <InfoComponent text="">
          <div>
            <p>Defina as classes de produtos do seu menu. Deste modo terá análises focadas para cada uma das suas classes.</p>
            <p>Quando terminar as alterações, sejam elas uma remoções ou adições, deverá clicar em reclassificar produtos.</p>
          </div>
        </InfoComponent>

        {data.length > 0 ? <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
          <thead className='bg-blue-dark rounded text-white'>
            <tr>
              <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                Nome
              </th>
              <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                Informação
              </th>
              <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: darkMode ? '#262853' : '' }} className="bg-white divide-y divide-gray-200">
            {data.map((item, index) => (
              <tr key={index}>
                <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                  {item.prod_category}
                </td>
                <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                  {item.prod_category_info}
                </td>
                <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
                  <div className="flex space-x-4">
                    <button onClick={() => deleteCategory(item.prod_category_id)}>
                      <IoTrash size={32} className="text-red-500 text-2xl hover:scale-150 transition-transform duration-300" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table> : <div style={{ color: darkMode ? "white" : "black" }} className="text-center text-2xl text-blue-dark">Não existem classes criadas.</div>}
      </div>}
      <IonModal isOpen={modalCreate} onDidDismiss={() => setModalVisibleCreate(false)}>
        <IonContent>
          <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "20px" }}>
            <div style={{ width: "100%" }} >
              <h1 style={{ textAlign: "center", padding: "20px" }} className="ml-2">Nova Classe</h1>
              <div className="mb-4">
                <label style={{ color: darkMode ? "white" : "black" }} className="block text-gray-700 text-sm font-bold mb-2">Nome da Categoria</label>
                <input style={{ backgroundColor: darkMode ? '#262853' : '' }}
                  className="w-full bg-gray-200 border rounded py-2 px-3"
                  value={prod_category}
                  onChange={(e) => setProd_category(e.target.value)} />
              </div>

              <div className="mb-4">
                <label style={{ color: darkMode ? "white" : "black" }} className="block text-gray-700 text-sm font-bold mb-2">Descrição</label>
                <textarea style={{ backgroundColor: darkMode ? '#262853' : '' }}
                  className="w-full bg-gray-200 border rounded py-2 px-3"
                  value={prod_category_info}
                  onChange={(e) => setProd_category_info(e.target.value)} />
              </div>
              <div style={{ padding: "20px" }} className="flex justify-center">
                <button
                  onClick={() => { setModalVisibleCreate(false); handleSubmit() }}
                  type="submit"
                  className="bg-blue-dark p-5 hover:bg-blue-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
                >
                  Submeter Categoria
                </button>
              </div>
            </div>
          </div>
          <Loading isOpen={loading} />
        </IonContent>
      </IonModal>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default RestProdCategoriesPage;
