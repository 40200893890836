
class CategoryFormatter {

   static categories = [
    'Peixe', 'Carne'];

   static default = 'Carne';

   static format(input: string): string {
    const capitalized = input.charAt(0).toUpperCase() + input.slice(1);
    const replaced = capitalized.replace(/_/g, ' e ');
    return replaced;
  }

  static getAll() {
    return this.categories;
  }

  static setAll(categories){
    this.default = categories[0] || '';
    this.categories = categories;
  }

}
export default CategoryFormatter;