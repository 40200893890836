
import UploadSAFTForm from "../../../components/App/sales/upload-saft-form.component";
import Page from "../page";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../components/App/reusable/loading/loading";
import { FaCloudDownloadAlt } from "react-icons/fa";

const UploadSAFTPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'UPLOAD SAFT PAGE',
  });
  return (
      <Page title={"Upload de ficheiro SAFT"} icon={<FaCloudDownloadAlt size={32}/>}>
      {tokenVerified ? (<UploadSAFTForm />) : <Loading isOpen={true} />}
      </Page>
  );
};

export default UploadSAFTPage;
