import {
  IonAlert,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonText,
  IonTitle,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

import { Redirect } from "react-router";
//import { createUser } from "../../../utils/Firebase";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [message, setMessage] = useState("");
  const [noMatch, setNoMatch] = useState(false);
  const [result, setResult] = useState(false);
  const [status, setStatus] = useState({ loading: false, error: false });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(<FaEye size={32}/>);
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [passwordIconConf, setPasswordIconConf] = useState(<FaEye size={32}/>);

  const togglePassword = () => {
    if (!showPassword) {
      setShowPassword(true);
    } else setShowPassword(false);
  };
  const changeIcon = () => {
    showPassword ? setPasswordIcon(<FaEye size={32}/>) : setPasswordIcon(<FaEyeSlash size={32} />);
  };

  const togglePasswordConf = () => {
    if (!showPasswordConf) {
      setShowPasswordConf(true);
    } else setShowPasswordConf(false);
  };
  const changeIconConf = () => {
    showPasswordConf ? setPasswordIconConf(<FaEye size={32}/>) : setPasswordIconConf(<FaEyeSlash size={32} />);
  };

  useEffect(() => {
    const checkPass = () => {
      if (password !== passConfirm) {
        setNoMatch(true);
      } else {
        setNoMatch(false);
      }
    };
    checkPass();
  }, [passConfirm, password]);

  const onClickHandler = async () => {
    try {
      if (noMatch) {
        throw new Error("passwords-do-not-match");
      } else if (password === "") {
        throw new Error("no-password");
      }
      setStatus({ loading: true, error: false });
      //await createUser(email, password);
      setStatus({ loading: false, error: false });
      setResult(true);
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          setMessage("Este email já está registado.");
          break;
        case "auth/weak-password":
          setMessage("A password é fraca.");
          break;
        case "auth/invalid-email":
          setMessage("Email inválido.");
          break;
        default:
          setMessage("Por favor tente mais tarde.");
          console.log(error.message);
      }
      setStatus({ loading: false, error: true });
    }
  };

  if (result) {
    return <Redirect to="/register/completion" />;
  }

  return (
    <>
      <IonList className="sign-up-list">
        <IonItem className="sign-up-label">
          <IonLabel position={email === "" ? "floating" : "stacked"}>Email</IonLabel>
          <IonInput
            type="email"
            onIonChange={(event) => setEmail(event.detail.value)}
          />
        </IonItem>
        <IonItem>
          <IonLabel  position={password === "" ? "floating" : "stacked"}>Password</IonLabel>
          <IonInput
            type={showPassword ? "text" : "password"}
            onIonChange={(event) => setPassword(event.detail.value)}
          />
          <IonButton
            className="eye-btn"
            slot="end"
            fill="clear"
            onClick={() => {
              togglePassword();
              changeIcon();
            }}
          >
            {passwordIcon}
          </IonButton>
        </IonItem>
        <IonItem className="sign-in-label">
          <IonLabel position={passConfirm === "" ? "floating" : "stacked"}>Confirmar Password</IonLabel>
          <IonInput
            onIonChange={(event) => setPassConfirm(event.detail.value)}
            type={showPasswordConf ? "text" : "password"}
          />
          <IonButton
            className="eye-btn"
            slot="end"
            fill="clear"
            onClick={() => {
              togglePasswordConf();
              changeIconConf();
            }}
          >
            {passwordIconConf}
          </IonButton>
        </IonItem>
      </IonList>
      {status.error && <IonText color="danger">Algo falhou!</IonText>}
      {noMatch && (
        <IonText color="danger">As passwords não são iguais!</IonText>
      )}

      <div className="btn-container">
        <IonButton expand="block" onClick={onClickHandler}>
          Criar Conta
        </IonButton>
        <IonButton expand="block" fill="clear" routerLink="/login">
          Já tem uma conta?
        </IonButton>
      </div>
      <IonAlert
        isOpen={status.error}
        onDidDismiss={() => setStatus({ ...status, error: false })}
        header={"Algo correu mal"}
        message={message}
        buttons={["OK"]}
      />
      <IonLoading isOpen={status.loading} />
    </>
  );
};

export default SignUp;
