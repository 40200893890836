import { useState, useEffect } from 'react';
import Image from './image';
import {  IonSearchbar } from '@ionic/react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useApi } from '../../../../utils/hooks/useAxiosPrivate';
import { useHistory } from 'react-router';
import Loading from '../loading/loading';

const News = ({ darkMode }) => {

  const { get, setCache, getCache } = useApi();
  const history = useHistory();
  const [alreadyRedirect, setAlreadyRedirect] = useState(false);
  const [news, setNews] = useState([]);
  const [searchQuery, setSearchQuery] = useState("Restauração");
  const [loading, setLoading] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-PT');
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    //const requestHandler = new RequestHandler("dash0_", idToken);
    const getGraph = async ( params = {}) => {
      //setLoading(true);
      const url = params
        ? `scrape_news/?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `scrape_news`;

      try {
        const cachedData = getCache(url);
        if (cachedData) {
          setNews(cachedData);
          setLoading(false);
          return;
        }
        const response = await get(url, {
          signal: controller.signal
        });
        
        setNews(response.data);
        setCache(url, response.data);
        setLoading(false);
      } catch (error) {
        switch (error.message) {
          case "Unauthorized":
            setLoading(false);
            if (isMounted && !alreadyRedirect) {
              setAlreadyRedirect(true);
              history.goBack();
            }
            break;
          default:
            setLoading(false);
            console.error(error);
        }
      }
    };

    getGraph({ query: searchQuery });

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [searchQuery]);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      setLoading(true)
      setSearchQuery(e.target.value);
    }
  };

  return (
    <div className={`dark-mode-${darkMode ? 'enabled' : 'disabled'}`}>
      <div className={`search ${darkMode ? 'dark' : 'light'}`}>
        <IonSearchbar
          value={searchQuery}
          onKeyDown={handleKeyUp}
          placeholder="Pesquisar por noticia"
          style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }}
        />
      </div>

      <Carousel
        infinite={true}
        responsive={responsive}
        showDots={false}
        className="py-2"
      >
        {news.map((article, index) => (
          <div style={{ height: "100%" }} key={index}>
            <div style={{ height: "100%", backgroundColor: darkMode ? "#422655" : 'white' }} className={`w-[90%] m-auto rounded-lg hover:scale-105 transition-transform duration-300`}>
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                <Image className="rounded-t-lg h-[16vh] w-full object-cover" src={article.image_url} alt="Imagem não disponível" /> {/* Use the Image component here */}
              </a>
              <div className="px-5 text-[14px] pt-2" style={{ height: '100%' }}>
                <span className='flex text-gray-400 right'>{formatDate(article.timestamp)}</span>
                <p className='test-gray-800 text-[14px] py-3'>{article.title.slice(0,80)}...</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <Loading isOpen={loading} />
    </div>
  );
}

export default News;
