import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonGrid, IonModal, IonRow,  IonText } from "@ionic/react";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import toast from 'react-hot-toast';
import InfoComponent from "../../reusable/info/info.component";
import Loading from "../../reusable/loading/loading";
import Paginate from "../../reusable/pagination/paginate";
import Toaster from "../../reusable/Toaster";


const Reprocess = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [currentPageIngreds, setCurrentPageIngreds] = useState(1);
  const [totalPagesIngreds, setTotalPagesIngreds] = useState(1);

  const today = new Date();
  const nowDate = today.toISOString();
  today.setHours(23, 59, 59, 0);
  const formattedToday = today.toISOString();

  const datetime = useRef<null | HTMLIonDatetimeElement>(null);
  const reset = () => {
    datetime.current?.reset();
  };
  const cancel = () => {
    datetime.current?.cancel();
  };
  const confirm = () => {
    datetime.current?.confirm();
  };

  function convertDateFormat(inputDate: string): string {
    // Parse the input date string
    const dateObj = new Date(inputDate);

    // Extract year, month, and day components
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Month starts from 0
    const day = dateObj.getDate().toString().padStart(2, '0');

    // Form the desired output format
    const outputDate = year + month + day;

    return outputDate;
  }

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController();

    const getGraph = async (url) => {
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        setData(response.data.results.data);
        setTotalPagesIngreds(response.data.results.total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    const apiUrl = `ingred_list?${currentPageIngreds}`;

    getGraph(apiUrl);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [change, currentPageIngreds]);

  const handleReprocessAll = async () => {

    setLoading(true);
    if (date == null) {
      Toaster.show("Selecione uma data para reprocessar os ingredientes.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(`/reprocess_ingreds/`,
        JSON.stringify({ "start_date": convertDateFormat(date), "ingred_id": [] }),
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show('Reprocessamento efetuado com sucesso.',  "success")
        setLoading(false);
      } else {
        Toaster.show('Erro ao reprocesar ingredientes. Por favor, tente mais tarde.', "error")
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show('Erro ao reprocesar ingredientes. Por favor, tente mais tarde.', "error")
      setLoading(false);
    }
  };


  const handleReprocessSelected = async () => {


    setLoading(true);
    if (date == null) {
      Toaster.show("Selecione uma data para reprocessar os ingredientes.", "error");
      setLoading(false);
      return;
    }

    if (selectedIngredients.length === 0) {
      Toaster.show("Selecione pelo menos um ingrediente para reprocessar.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(`/reprocess_ingreds/`,
        JSON.stringify({ "start_date": convertDateFormat(date), "ingred_id": selectedIngredients.map(ingredient => ingredient.id) }),
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show('Reprocessamento efetuado com sucesso.', "success")
        setLoading(false);
      } else {
        Toaster.show('Erro ao reprocesar ingredientes. Por favor, tente mais tarde.', "error")
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show('Erro ao reprocesar ingredientes. Por favor, tente mais tarde.', "error")
      setLoading(false);
    }
  };

  const darkMode = useDarkMode();

  function formatDate(dateString, hour = true) {
    const date = new Date(dateString);
    if (!hour) {
      const formattedDate = date.toLocaleDateString('pt-PT')
      return formattedDate;
    }
    const formattedDate = date.toLocaleDateString('pt-PT', {
      hour: 'numeric',
      minute: 'numeric'
    });
    return formattedDate;
  }

  const handleCheckboxChange = (ingr_id) => {
    setSelectedIngredients((prevSelectedIngredients) => {
      if (prevSelectedIngredients.find((item) => item.id === ingr_id)) {
        return prevSelectedIngredients.filter((item) => item.id !== ingr_id);
      } else {
        return [...prevSelectedIngredients, { id: ingr_id }];
      }
    });
  };



  return (
    <IonContent>
      
      <IonGrid style={{ padding: "70px", paddingTop: "50px" }}>
        <IonRow>
          <IonCol size="12">
            <IonText style={{ color: darkMode ? "white" : "black" }} className="text-4xl font-semibold mt-10 text-blue-dark">Reprocessar ingredientes</IonText>
          </IonCol>
        </IonRow>
        <div style={{ padding: "30px", paddingTop: "20px" }}>
          <IonRow>
            <IonCol size="12">
              <IonButton onClick={() => setShowModal(true)}>{date != null ? `Selecionada a data: ${formatDate(date, false)}` : "Selecione uma data"}</IonButton>
            </IonCol>
            <IonCol size="12">
              <IonButton id="update-all">
                Atualizar todos
              </IonButton>
            </IonCol>
            <IonAlert
              header="Atenção"
              trigger="update-all"
              subHeader="Aplicar alterações a todos os ingredientes."
              message={date == null ? "Estará a alterar valores calculados a partir de hoje para todos os ingredientes" : "Estará a alterar valores calculados a partir de: " + formatDate(date, false) + " para todos os ingredientes"}
              buttons={[
                {
                  text: 'Cancelar',
                  role: 'cancelar',
                  handler: () => {
                  },
                },
                {
                  text: 'OK',
                  role: 'confirmar',
                  handler: () => {
                    handleReprocessAll();
                  },
                },
              ]}
            >
            </IonAlert>
            <IonCol size="12">
              <IonButton id="update-selected">
                Atualizar selecionados
              </IonButton>
            </IonCol>
            <IonAlert
              header="Atenção"
              trigger="update-selected"
              subHeader="Aplicar alterações aos ingredientes selecionados."
              message={date == null ? "Estará a alterar valores calculados a partir de hoje para os ingredientes selecionados" : "Estará a alterar valores calculados a partir de: " + formatDate(date, false) + " para os ingredientes selecionados"}
              buttons={[
                {
                  text: 'Cancelar',
                  role: 'cancelar',
                  handler: () => {
                  },
                },
                {
                  text: 'OK',
                  role: 'confirmar',
                  handler: () => {
                    handleReprocessSelected();
                  },
                },
              ]}
            >
            </IonAlert>
          </IonRow>
          <InfoComponent text="">
            <div>
              <p>Poderá reprocessar um ou mais ingredientes atualizando valores previamente calculados segundo as suas alterações. Poderá aplicar as alterações a todos ou apenas aos ingredientes selecionados.</p>
              <p>A data selecionada irá indicar o ponto a partir da qual as alterações terão efeito.</p>
            </div>
          </InfoComponent>

          {data.length > 0 && (
            <IonRow >
              <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                <thead className='bg-blue-dark text-white rounded'>
                  <tr>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Nome</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Classe</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Descrição</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Tipo</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Data Criação</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Selecionar</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: darkMode ? '#262853' : '' }} className="bg-white divide-y divide-gray-200">
                  {data.map((ingredient) => (
                    <tr key={ingredient.ingr_id}>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        {ingredient.ingr_name}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        {ingredient.ingr_class}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        {ingredient.ingr_descrip}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        {ingredient.ingr_degree_process}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="py-2 whitespace-no-wrap text-gray-600">
                        {formatDate(ingredient.ztimestamp)}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        <input
                          type="checkbox"
                          checked={selectedIngredients.find((item) => item.id === ingredient.ingr_id) !== undefined}
                          onChange={() => handleCheckboxChange(ingredient.ingr_id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </IonRow>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
          <Paginate currentPage={currentPageIngreds} setCurrentPage={setCurrentPageIngreds} totalPages={totalPagesIngreds} loading={loading} />
        </div>

      </IonGrid>

      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonGrid style={{ height: "100%", margin: "0px" }}>
          <IonRow style={{ height: "100%" }}>
            <IonCol style={{ height: "100%" }}>
              <IonDatetime
                style={{ width: "100%", height: "100%", maxWidth: "100%" }}
                presentation="date"
                value={nowDate}
                max={formattedToday}
                ref={datetime}
              >
                <span slot="title">Selecione uma data</span>
                <IonButtons slot="buttons">
                  <IonButton color="danger" onClick={() => {
                    reset();
                    setDate(null);
                    setShowModal(false);
                  }}>
                    Resetar
                  </IonButton>
                  <IonButton color="primary" onClick={() => {
                    cancel();
                    setShowModal(false);
                  }}>
                    Cancelar
                  </IonButton>
                  <IonButton
                    color="primary"
                    onClick={() => {
                      confirm();
                      const selectedDate = datetime.current.value;
                      setDate(selectedDate);
                      setShowModal(false);
                    }}
                  >
                    Confirmar
                  </IonButton>
                </IonButtons>
              </IonDatetime>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>

      <Loading isOpen={loading} />
    </IonContent>
  );
}

export default Reprocess;