import { FaTrash } from "react-icons/fa";
import { useState, useEffect } from 'react';
import { IonAlert, IonContent, IonIcon, IonModal, IonText } from '@ionic/react';
import { useLocation } from "react-router";
import toast from 'react-hot-toast';
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import SkuCreate from "../sku/sku-create";
import BackComponent from "../../reusable/goBack/go-back-component";
import InfoComponent from "../../reusable/info/info.component";
import Loading from "../../reusable/loading/loading";
import { useCat } from "../../../../utils/contexts/dataCat.context";
import "./ingreds.styles.scss";
import Toaster from "../../reusable/Toaster";
const IngredDetail = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ingrId = queryParams.get('id');
  const [ingrName, setIngrName] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const [selectedSKU, setselectedSKU] = useState("");
  const [refProd, setRefProd] = useState(Object);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [changedList, setChangedList] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(-1);

  const { Categories } = useCat();
  const [selectedCategories, setSelectedCategories] = useState([]);
  // const handleCategoryChange = (sku_id, event) => {
  //   setSelectedCategories(prevState => {
  //     const existingItemIndex = prevState.findIndex(item => item.id === sku_id);

  //     if (existingItemIndex !== -1) {
  //       // If the item exists, replace its category with the new one
  //       const updatedItem = { ...prevState[existingItemIndex], category: event.target.value };
  //       return prevState.map((item, index) => index === existingItemIndex ? updatedItem : item);
  //     } else {
  //       // If the item doesn't exist, add a new one
  //       const newCategory = { id: sku_id, category: event.target.value };
  //       return [...prevState, newCategory];
  //     }
  //   });
  // };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getIngreds = async (endpoint, ingrId = {}) => {
      try {
        // Make the API request using axios
        const response = await axiosPrivate.get(endpoint, {
          params: { ingr_id: ingrId },
          signal: controller.signal
        });


        setData(response.data);
        setIngrName(response.data["ingred"][0].ingr_name);
        setLoading(false);
        const refProd = response.data["assigns"].find((item: { ass_state: string; }) => item.ass_state === "REF");
        refProd ? setRefProd(refProd.sku) : setRefProd("");


      } catch (error) {
        console.error(error);
      }
    };

    getIngreds("list_ingr_skus", ingrId);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);



  const deleteSku = async (sku_assign_id: Number) => {
    setLoading(true);
    const body = {
      sku_id: sku_assign_id,
      ingr_id: ingrId,

    };
    const response = await axiosPrivate.post(`/sku_assign_delete/`,
      JSON.stringify(body),
    );

    if (response.status === 200) {
      Toaster.show('Associação apagada com sucesso.',   "success")
      setChangedList(!changedList);
    } else {
      Toaster.show('Erro ao apagar associação.', "error")
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('pt-PT', {
      hour: 'numeric',
      minute: 'numeric'
    });
    return formattedDate;
  }

  const handleRef = async () => {
    if (selectedSKU === "") {
      Toaster.show("Selecione primeiro um item.", "error")
      return;
    }
    const body = {
      ingr_id: ingrId,
      sku_id: selectedSKU,
      sku_id_ref: refProd
    };
    try {
      const response = await axiosPrivate.post(`/make_ref/`,
        JSON.stringify(body),
      );

      if (response.status === 200) {
        Toaster.show("Produto selecionado feito referência", "success")
        setChangedList(!changedList);
      }
      else {
        Toaster.show("Ocorreu um erro, tente mais tarde.", "error")
      }
    } catch (error) {
      Toaster.show("Ocorreu um erro, tente mais tarde.", "error")
    }


  };

  // const handleCategory = async () => {
  //   if (selectedCategories.length === 0) {
  //     Toaster.show("Mude primeiro uma categoria.")
  //     return;
  //   }
  //   const body = {
  //     changes: selectedCategories
  //   };
  //   try {
  //     const response = await axiosPrivate.post(`/sku_change_category/`,
  //       JSON.stringify(body),
  //     );

  //     if (response.status === 201) {
  //       Toaster.show("Categoria(s) alterada(s) com sucesso.")
  //       setChangedList(!changedList);
  //     }
  //     else {
  //       Toaster.show("Ocorreu um erro, tente mais tarde.")
  //     }
  //   } catch (error) {
  //     Toaster.show("Ocorreu um erro, tente mais tarde.")
  //   }


  // };

  const handleCheckboxChange = (sku_id) => {
    setselectedSKU((prevselectedSKU) => {
      if (prevselectedSKU === sku_id) {
        return ""; // deselect the sku if it's already selected
      } else {
        return sku_id;
      }
    });
  };

  const getValue = (item) => {

    const selectedCategory = selectedCategories.find((categoryItem) => categoryItem.id === item.sku_id);
    return selectedCategory ? selectedCategory.category : item.sku_category;
  }

  const darkMode = useDarkMode();

  return (
    <IonContent>
      {!loading && <div className="w-[60%] mx-auto text-black">
        <BackComponent></BackComponent>
        <div className="flex items-center justify-between">
          <IonText style={{ color: darkMode ? "white" : "black" }} className="text-4xl font-semibold mt-10 text-blue-dark">{data["ingred"][0].ingr_name} {data["ingred"][0].ingr_class
          }</IonText>
          <div className="flex flex-col gap-8 pt-10">
            <button onClick={() => setModalVisibleCreate(true)} style={{ background: darkMode ? "#422655" : '' }} className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300">+ Associar Sku</button>
            <button onClick={() => handleRef()} style={{ background: darkMode ? "#422655" : '' }} className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300">Tornar Referência</button>
            {/* <button onClick={() => handleCategory()} style={{ background: darkMode ? "#422655" : '' }} className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300">Alterar Categoria(s)</button> */}
          </div>
        </div>

        <InfoComponent text="">
          <div>
            <p>Ingrediente criado a {formatDate(data["ingred"][0].ztimestamp)}</p>
            <br></br>
            <p>Deverá indicar um SKU de preferência/referência entre a seguinte lista.Selecione o SKU de referência desejado e precione "Tornar Referência".</p>
          </div>
        </InfoComponent>

        {data["skus"].length > 0 ? <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
          <thead className='bg-blue-dark text-white rounded'>
            <tr>
              <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                Nome
              </th>
              <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                Refência
              </th>

              <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                Categoria
              </th>
              <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                Quantidade
              </th>
              <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                Actions
              </th>

            </tr>
          </thead>
          <tbody style={{ backgroundColor: darkMode ? '#262853' : '' }} className="bg-white divide-y divide-gray-200">
            {data["skus"].map((item: any, index: any) => (
              <tr key={index}>
                <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                  {item.sku_prod_name}
                </td>
                <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600 text-center">
                  <input
                    type="checkbox"
                    checked={selectedSKU === "" ? refProd === item.sku_id : selectedSKU === item.sku_id}
                    onChange={() => handleCheckboxChange(item.sku_id)}
                  />
                </td>
                <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                  {/* <select value={getValue(item)} className="p-2 rounded-xl bg-transparent outline-none" onChange={(event) => handleCategoryChange(item.sku_id, event)}>
                    {Categories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select> */}
                  {item.sku_category}
                </td>
                <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                  {item.sku_packing} {item.sku_price_type}
                </td>
                <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                  <button onClick={() => setIsOpenDelete(item.sku_id)} className="hover:scale-125 transition-transform duration-30">
                    <FaTrash title="Eliminar" className="text-red-500 text-2xl" />
                  </button>
                  <IonAlert
                    header="Atenção"
                    isOpen={isOpenDelete !== -1 && isOpenDelete === item.sku_id}
                    subHeader="Eliminar item."
                    message={`Estará a eliminar o item ${item.sku_prod_name}. Deseja continuar?`}
                    buttons={[
                      {
                        text: 'Cancelar',
                        role: 'cancelar',
                        handler: () => {

                        },
                      },
                      {
                        text: 'OK',
                        role: 'confirmar',
                        handler: () => {

                          deleteSku(item.sku_id);
                        },
                      },
                    ]}
                    onDidDismiss={() => setIsOpenDelete(-1)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table> : <div style={{ color: darkMode ? "white" : "black" }} className="text-center text-2xl text-blue-dark">Não existem ingredientes associados a esta receita.</div>}
      </div>}
      <IonModal className="myModal" isOpen={modalCreate} onDidDismiss={() => setModalVisibleCreate(false)} showBackdrop={false}>
        <div className="p-8" style={{ overflowY: "auto", overflowX: "hidden"}}>
          <SkuCreate ingrName={ingrName} modalCloser={setModalVisibleCreate} setChange={setChangedList} change={changedList} />
        </div>
      </IonModal>
      <Loading isOpen={loading} />
    </IonContent>
  );

};

export default IngredDetail;