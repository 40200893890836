import { createContext, useState, useEffect, useContext } from "react";

export interface UserProviderInterface {
  //user: User;
  idToken: string;
  setIdToken: (idToken: string) => void;
  uid: string;
  username: string;
}

export const UserContext = createContext<UserProviderInterface>({
  idToken: null,
  setIdToken: () => {},
  uid: null,
  username: null,
});

// export const UserProvider = ({ children }) => {
//   const [user, setUser] = useState("")
//   const [idToken, setIdToken] = useState("");
//   const [uid, setUID] = useState("");
//   const [username, setUsername] = useState("");

//   useEffect(() => {
//     const unsubscribe = onAuthStateChangedListener(async (user: User) => {
//       if (user) {
//         const idToken = await user.getIdToken();
//         setUsername(user.email);
//         setIdToken(idToken);
//         setUID(user.uid);
//       }
//     });
//     return unsubscribe;
//   }, []);


//   return (
//     <UserContext.Provider
//       value={{
//         idToken,
//         uid,
//         username,
//       }}
//     >
//       {children}
//     </UserContext.Provider>
//   );
// };^

export const UserProvider = ({ children }) => {
  const [idToken, setIdToken] = useState("");
  const [uid, setUID] = useState("");
  const [username, setUsername] = useState("");

  const updateIdToken = (newIdToken: string) => {
    setIdToken(newIdToken);
  };

  return (
    <UserContext.Provider
      value={{
        idToken,
        username,
        uid,
        setIdToken: updateIdToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};


export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within UserProvider");
  }
  return context;
};

// export const UserContext = createContext({
//   loggedIn: false
// });

// const Context = ({children}) => {
//   const [user, setUser] = useState(() => ({
//     loggedIn: false,
//   }));
//   return <UserContext.Provider value={user}>{children}</UserContext.Provider>
// }

// export default Context;