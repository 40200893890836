import { useState, useEffect, useRef } from 'react';
import { IonContent, IonGrid } from '@ionic/react';
import useDarkMode from '../../../pages/Profile/usedarkmode';
import { useApi } from '../../../utils/hooks/useAxiosPrivate';
import ChartComponent from '../reusable/charts/chartComponent';
import SelectComponent from '../reusable/select/select';
import MonthsFormatter from '../reusable/select/months';
import KpiFormatter from '../reusable/select/kpi';
import Loading from '../reusable/loading/loading';
import InfoComponent from '../reusable/info/info.component';
import CardComponent from '../reusable/cardComponent';

type ChartOptions = Record<string, any>;


const LongTerm = () => {
  const [loading, setLoading] = useState(true);
  const [chartLoaded, setChartLoaded] = useState(false);
  const { get } = useApi();
  const [nweeks, setNweeks] = useState<number>(4);
  const [month, setMonth] = useState('Jan');
  const [kpi, setKpi] = useState('quantity');
  const chartRef = useRef(null);
  const [graphData, setGraphData] = useState<Record<string, ChartOptions>>({
    q41_1: {}, q41_2: {}, q42_1: {}, q42_2: {}, q43_1: {}, q43_2: {}, q44_1: {}, q44_2: {},
  }); 
  const [kpis, setKpis] = useState({ absolute: [0, 0, 0, 0], variation: [0, 0, 0, 0] });
  const [lastSalesDate, setLastSalesDate] = useState(0);
  const darkMode = useDarkMode();

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  useEffect(() => {
    const controller = new AbortController();

    const fetchGraphData = async () => {
      try {
        const promises = [
          get(`dash4_q41_1/?kpi=${kpi}`),
          get(`dash4_q41_2/?kpi=${kpi}`),
          get(`dash4_q42_1/?month=${month}&kpi=${kpi}`),
          get(`dash4_q42_2/?month=${month}&kpi=${kpi}`),
          get(`dash4_q43_1/?month=${month}&kpi=${kpi}`),
          get(`dash4_q43_2/?month=${month}&kpi=${kpi}`),
          get(`dash4_q44_1/?month=${month}&kpi=${kpi}`),
          get(`dash4_q44_2/?month=${month}&kpi=${kpi}`),
          get('dash4_sales_last_date'),
        ];

        const responses = await Promise.all(promises);
        const [
          q41_1Data,
          q41_2Data,
          q42_1Data,
          q42_2Data,
          q43_1Data,
          q43_2Data,
          q44_1Data,
          q44_2Data,
          lastSalesDateData,
        ] = responses;

        setGraphData({
          q41_1: q41_1Data.data,
          q41_2: q41_2Data.data,
          q42_1: q42_1Data.data,
          q42_2: q42_2Data.data,
          q43_1: q43_1Data.data,
          q43_2: q43_2Data.data,
          q44_1: q44_1Data.data,
          q44_2: q44_2Data.data,
        });
        setLastSalesDate(lastSalesDateData.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const fetchKpiData = async () => {
      try {
        const response = await get(`get_sales_kpi/?nweeks=${52}`);
        setKpis(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (nweeks) {
      fetchGraphData();
      fetchKpiData();
    }

    return () => {
      controller.abort();
    };
  }, [nweeks, month, kpi]);

  const kpiDescription = {
    quantity: 'Items vendidos representa o número de produtos individuais vendidos pelo restaurante',
    total_sales: 'Total de vendas representa o valor total de vendas gerado pelo restaurante',
    numero_faturas: 'Número de faturas representa o número total de faturas emitidas pelo restaurante',
  };

  return (
    <IonContent className="ion-padding">
      <IonGrid>
        {lastSalesDate !== 0 && (
          <div className="px-16 pb-10">
            <InfoComponent>
              <p>
                Dados disponíveis até a data:{' '}
                {new Date(lastSalesDate).toLocaleDateString('pt-PT', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            </InfoComponent>
          </div>
        )}

        {kpis && (
          <div className="px-16">
            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
              <CardComponent
                title={'Total de Vendas'}
                value={`${kpis['absolute'][1]}€`}
                variation={kpis['variation'][1]}
              />
              <CardComponent
                title={'Nº Total de Faturas'}
                value={`${kpis['absolute'][0]}`}
                variation={kpis['variation'][0]}
              />
              <CardComponent
                title={'Valor médio p/Fatura'}
                value={`${kpis['absolute'][3]}€`}
                variation={kpis['variation'][3]}
              />
              <CardComponent
                title={'Itens vendidos'}
                value={`${kpis['absolute'][2]}`}
                variation={kpis['variation'][2]}
              />
            </div>
          </div>
        )}

        <IonGrid>
          <div className="px-14 flex items-center">
            <div className="w-[30%]">
              <SelectComponent formatter={KpiFormatter} darkMode={darkMode} onChange={setKpi} />
            </div>
            <div className="w-[70%]">
              <InfoComponent>
                <div>
                  <p>{kpiDescription[kpi]}</p>
                </div>
              </InfoComponent>
            </div>
          </div>

          <div className="grid xl:grid-cols-2 gap-4 padding">
            <ChartComponent
              chartRef={chartRef}
              option={graphData.q41_1}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
            <ChartComponent
              chartRef={chartRef}
              option={graphData.q41_2}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
            <div className='xl:col-span-2 py-10'>
            <SelectComponent formatter={MonthsFormatter} darkMode={darkMode} onChange={setMonth} />
            </div>
            <ChartComponent
              chartRef={chartRef}
              option={graphData.q42_1}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
            <ChartComponent
              chartRef={chartRef}
              option={graphData.q43_1}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
            <ChartComponent
              chartRef={chartRef}
              option={graphData.q44_1}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
            <ChartComponent
              chartRef={chartRef}
              option={graphData.q42_2}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
            <ChartComponent
              chartRef={chartRef}
              option={graphData.q43_2}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
            <ChartComponent
              chartRef={chartRef}
              option={graphData.q44_2}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
          </div>
        </IonGrid>
      </IonGrid>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default LongTerm;