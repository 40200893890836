import Select from 'react-select'


const SelectComponent = ({ formatter, darkMode, onChange, value = null }) => {

  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
      backgroundColor: darkMode ? "#2C1B60" : "#dddd",
      color: darkMode ? "white" : "black",
    }),
    control: (provided) => ({
      ...provided,
      width: 200, 
      backgroundColor: darkMode ? "#2C1B60" : "#ddd",
      boxShadow: 'none' ,
      border: 'none' 
    }),
    menu: (provided) => ({
      ...provided,

      backgroundColor: darkMode ? "#2C1B60" : "white",
      zIndex: 9999, 
      width: 200, 
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: darkMode ? "#2C1B60" : "white",
      zIndex: 9999, 
      // '::-webkit-scrollbar': {
      //   display: 'none',
      // },
      // msOverflowStyle: 'none',  
      // scrollbarWidth: 'none', 
    }),
    option: (provided, state) => ({
      ...provided,
      color: darkMode ? 'white' : 'black',
      backgroundColor: state.isSelected ? darkMode ? '#422655' : 'white' : state.isFocused ? darkMode ? '#262853' : '#eeee' : darkMode ? '#2C1B60' : '#dddd',
    }),
  };




  const selectOptions: { value: string; label: string }[] = formatter.getAll().map((option) => (
    { value: option, label: formatter.format(option) }
  ));
  return (
    <Select
      placeholder={value || formatter.format(formatter.default)}
      onChange={(choice: { value: string }) => {
        onChange(choice.value);
      }}
      options={selectOptions}
      styles={customStyles}
    />
  );

}
  
export default SelectComponent;