// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-background);
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: var(--scrollbar-background);
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-background);
  border-radius: 10px;
}

.myModal {
  align-items: center;
  justify-content: center;
}
.myModal::part(content) {
  width: 60%;
  height: 75%;
}`, "",{"version":3,"sources":["webpack://./src/pages/App/consultas/queryStyles.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;EACA,kDAAA;AAAF;;AAIA;EACE,WAAA;EACA,6CAAA;EACA,mBAAA;AADF;;AAKA;EACE,6CAAA;EACA,mBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,uBAAA;AAFF;AAGE;EACE,UAAA;EACA,WAAA;AADJ","sourcesContent":["\n.scrollbar {\n  scrollbar-width: thin;\n  scrollbar-color: var(--scrollbar-thumb-background);\n\n}\n\n.scrollbar::-webkit-scrollbar {\n  width: 10px;\n  background-color: var(--scrollbar-background);\n  border-radius: 10px;\n\n}\n\n.scrollbar::-webkit-scrollbar-thumb {\n  background: var(--scrollbar-thumb-background);\n  border-radius: 10px;\n}\n\n.myModal{\n  align-items: center;\n  justify-content: center; \n  &::part(content){\n    width: 60%;\n    height: 75%; \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
