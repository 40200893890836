
import FoodCost from "../../../components/App/foodcost/foodcost-graphs-recipes.component";
import Page from "../page";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../components/App/reusable/loading/loading";
import { IoPricetag } from "react-icons/io5";

const FoodCostPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'FOODCOST RECIPES PAGE - RECIPES',
  });
  return (
      <Page title={"Visão de Receitas"} icon={<IoPricetag size={32}/>}>
        {tokenVerified ? (<FoodCost />) : <Loading isOpen={true} />}
      </Page>
  );
};
export default FoodCostPage;
