import React, { useState, useEffect, useCallback } from 'react';
import { IonContent, IonGrid, IonCol, IonRow, IonModal } from '@ionic/react';
import { LuSearch } from "react-icons/lu";
import { Link } from 'react-router-dom';
import { LuSearchX } from "react-icons/lu";

import { DarkModeProvider } from '../../../utils/contexts/theme.context';
import useDarkMode from '../../../pages/Profile/usedarkmode';
import { useApi } from '../../../utils/hooks/useAxiosPrivate';
import Loading from '../reusable/loading/loading';
import './events.styles.scss';
import '../general.styles.scss';
import Toaster from '../reusable/Toaster';
import InfoComponent from '../reusable/info/info.component';

interface Event {
  an_id: string;
  an_nome: string;
  an_descr: string;
  an_obs: string;
  an_dt_ini: string;
  an_dt_fim: string;
  price_ini: number;
}

interface SelledItems {
  hide: string;
  kpi: string;
  natureza: string;
  tipo: string;
}


const Events: React.FC = () => {
  const { get, post } = useApi();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [newEvent, setNewEvent] = useState({ name: '', description: '' });
  const [selledItemsToDisplay, setSelledItemsToDisplay] = useState<SelledItems[]>([]);
  const [selledItems, setSelledItems] = useState<SelledItems[]>([]);
  const [refDate, setRefDate] = useState('');
  const [type, setType] = useState('KPI Agregado');
  const [nature, setNature] = useState('Quantidades');
  const [selectedKpi, setSelectedKpi] = useState<string>("");


  const darkMode = useDarkMode();

  const getMaxDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 15);
    return date.toISOString().split('T')[0];
  };

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get('list_events');
      console.log(response.data);
      setEvents(response.data);
    } catch (error) {
      console.error(error);
      Toaster.show('Erro ao carregar listas de eventeamento', 'error');
    } finally {
      setLoading(false);
    }
  }, []);


  const handleCreateEvent = async () => {
    setShowModalCreate(false);
    try {

      const event = {
        name: newEvent.name,
        description: newEvent.description,
        refDate: refDate,
        type: type,
        nature: nature,
        kpi: selectedKpi,
      }
      await post(`create_event/`, event);
      fetchEvents();
    } catch (error) {
      console.error('Erro ao criar analise:', error);
    } finally {
      setNewEvent({ name: '', description: '' });
    }
  };


  const handleFetchSelledItems = async () => {
    setLoading(true);
    try {
      const response = await get('event_options');
      setSelledItems(response.data);
    } catch (error) {
      console.error('Erro buscar event_options:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);


  useEffect(() => {
    if (showModalCreate && selledItems.length === 0) {
      handleFetchSelledItems();
    }
  }, [showModalCreate]);

  useEffect(() => {
    if (selledItems) {
      setLoading(true);
      const summarizedSelledItems = selledItems.filter((item) => item.tipo === type && item.natureza === nature);
      setSelledItemsToDisplay(summarizedSelledItems);
      setLoading(false);
    }
  }, [nature, selledItems, type]);

  const renderEventTable = () => (
    <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
      <thead className="bg-blue-dark text-white rounded">
        <tr>
          {['Nome', 'Data de Criação', 'Descritivo', 'Estado', 'Actions'].map((header) => (
            <th key={header} style={{ background: darkMode ? "#422655" : "" }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody style={{ backgroundColor: darkMode ? "#262853" : "" }} className="bg-white divide-y divide-gray-200">
        {events.map((item, index) => (
          <tr key={index}>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.an_nome}
            </td>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.an_dt_ini}
            </td>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.an_descr}
            </td>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.an_obs}
            </td>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              <div className="flex space-x-4">
                {item.an_obs !== "Disponivel" ? (
                  <div>
                    <LuSearchX title="Indisponível" size={32} />
                  </div>
                ) : (
                  <Link to={`/app/eventning/custom/${item.an_obs.match(/\d+/)?.[0] || '0'}`} className="hover:scale-125 transition-transform duration-30">
                    <LuSearch title="Consultar" size={32} />
                  </Link>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <DarkModeProvider>
      <IonContent className="ion-padding">
        <IonRow className="paddingButton">
          <button
            onClick={() => setShowModalCreate(true)}
            style={{ background: darkMode ? "#422655" : "" }}
            className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
          >
            + Criar Evento
          </button>
        </IonRow>
        <IonGrid>
          <IonGrid>
            <IonRow style={{ paddingBottom: "30px", paddingLeft: "50px", paddingRight: "50px" }}>
              <IonCol>
                {events.length > 0 ? (
                  renderEventTable()
                ) : (
                  <div style={{ color: darkMode ? "white" : "black" }} className="text-center text-2xl mt-10">
                    Sem eventos. Crie um novo evento.
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonGrid>
        <Loading isOpen={loading} />
      </IonContent>

      {/* Modal Create */}
      <IonModal className='myModal' isOpen={showModalCreate} onDidDismiss={() => setShowModalCreate(false)}>
        <div className="overflow-auto p-10">
          <h2
            style={{
              color: darkMode ? "white" : "black",
              paddingBottom: "20px",
              textAlign: "center",
            }}
          >
            Criar novo evento
          </h2>

          <div className="flex flex-col gap-8">
            <div className='flex flex-col gap-2'>
              <label className={`text-xl font-bold ${darkMode ? "text-white" : "text-black"}`}>Nome</label>
              <input
                type="text"
                placeholder="Nome do Evento"
                value={newEvent.name}
                style={{
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                }}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, name: e.target.value })
                }
                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
              />
            </div>
            <div className='flex flex-col gap-2'>
              <label className={`text-xl font-bold ${darkMode ? "text-white" : "text-black"}`}>Explicação do Evento</label>
              <textarea
                placeholder="Explicação do Evento"
                value={newEvent.description}
                style={{
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                }}
                onChange={(e) =>
                  setNewEvent({
                    ...newEvent,
                    description: e.target.value,
                  })
                }
                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
              />
            </div>
            <div className='flex flex-col gap-2'>
              <label className={`text-xl font-bold ${darkMode ? "text-white" : "text-black"}`}>Data de Referência do Evento</label>
              <input
                type="date"
                value={refDate}
                max={getMaxDate()}
                style={{
                  color: darkMode ? 'white' : 'black',
                  backgroundColor: darkMode ? '#422653d4' : '#d9d9d9',
                }}
                onChange={(e) => setRefDate(e.target.value)}
                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
              />
            </div>
            <InfoComponent>
              <div>
              <p>
                Selecione o KPI que deseja analisar. Pode selecionar apenas 1 KPI por evento.
              </p>
              <p>
                Selecione se pretende analisar por valor de vendas ou qunatidade vendida.
              </p>
              </div>
            </InfoComponent>

            <div className='flex justify-between gap-8 flex-col 1600:flex-row'>
              <div className='flex relative bg-zinc-300 w-fit rounded-full'>
                <div className={`rounded-full px-4 py-2 w-52 flex justify-center h-10 `} onClick={() => setType("KPI Agregado")}>
                  <p className='z-[2]'>KPI Agregado</p>
                </div>
                <div className={`rounded-full px-4 py-2 w-52 flex justify-center h-10 `} onClick={() => setType("KPI Granular")}>
                  <p className='z-[2]'>KPI Granular</p>
                </div>
                <div className={`absolute bg-indigo-400 top-0 w-52 h-10 rounded-full z-[1] transition-all duration-500 ease-in-out ${type === "KPI Agregado" ? "translate-x-0" : "translate-x-[100%]"}`}></div>
              </div>
              <div className='flex relative bg-zinc-300 w-fit rounded-full'>
                <div className={`rounded-full px-4 py-2 w-52 flex justify-center h-10 `} onClick={() => setNature("Quantidades")}>
                  <p className='z-[2]'>Quantidades</p>
                </div>
                <div className={`rounded-full px-4 py-2 w-52 flex justify-center h-10 `} onClick={() => setNature("Valor de Vendas")}>
                  <p className='z-[2]'>Valor de Vendas</p>
                </div>
                <div className={`absolute bg-indigo-400 top-0 w-52 h-10 rounded-full z-[1] transition-all duration-500 ease-in-out ${nature === "Quantidades" ? "translate-x-0" : "translate-x-[100%]"}`}></div>
              </div>
            </div>

            <div className='overflow-auto max-h-96 pr-2'>
                <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                  <thead className="bg-blue-dark text-white rounded">
                    <tr>
                      {[`${type} - ${nature}`, 'Selecionar'].map((header) => (
                        <th key={header} style={{ background: darkMode ? "#422655" : "" }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: darkMode ? "#262853" : "" }} className="bg-white divide-y divide-gray-200">
                    {selledItemsToDisplay.map((item, index) => (
                      <tr key={index}>
                        <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                          {item.kpi}
                        </td>
                        <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                          <input checked={selectedKpi === item.kpi} type='checkbox' onChange={() => setSelectedKpi(item.kpi)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
            <div className="flex justify-between gap-x-3 pt-5">
              <button
                onClick={handleCreateEvent}
                disabled={
                  newEvent.name === "" ||
                  newEvent.description === ""
                }
                style={{ background: darkMode ? "#422655" : "" }}
                className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
              >
                Criar
              </button>
              <button
                onClick={() => setShowModalCreate(false)}
                style={{ background: darkMode ? "#422655" : "" }}
                className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
        <Loading isOpen={loading} />
      </IonModal>
    </DarkModeProvider>
  );
};

export default Events;