// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbarContent {
    --offset-bottom: auto!important;
    --overflow: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/theme/scrollbar.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,cAAc;IACd;MACE,aAAa;IACf;EACF","sourcesContent":[".scrollbarContent {\n    --offset-bottom: auto!important;\n    --overflow: hidden;\n    overflow: auto;\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
