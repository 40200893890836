import { useState, useEffect } from "react";
import { IonContent, IonText } from "@ionic/react";
import { useLocation } from "react-router";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import BackComponent from "../../reusable/goBack/go-back-component";
import InfoComponent from "../../reusable/info/info.component";
import Loading from "../../reusable/loading/loading";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const RecipeTec = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipeId = queryParams.get("id");
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getGraph = async (endpoint, params = {}) => {
      const url = params
        ? `${endpoint}?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `${endpoint}`;
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        eval(`setData(response.data)`);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    getGraph("ingred_sku_list", { recipe_id: recipeId });
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);


  const darkMode = useDarkMode();

  // const downloadPDF = () => {
  //   const div = document.getElementById(`detail-recipe-${recipeId}`);
  //   html2canvas(div)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF();
  //       const imgProps = pdf.getImageProperties(imgData);
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //       pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //       pdf.save(`detail-recipe-${recipeId}.pdf`);
  //     });
  // }
  const IngredientList = ({ ingredients, recipe, darkMode }: { ingredients: any[], recipe: any[], darkMode: boolean }) => {
    return (
      <ul style={{ backgroundColor: darkMode ? "#262853" : "" }} className="bg-white  divide-y divide-gray-200" >
        <li>
          <h2>Detalhes da Receita</h2>
          <ul className="px-4 py-4">
            <li>
              <strong>Nome:</strong> {recipe[0].recipe_name}
            </li>
            <li>
              <strong>Classe:</strong> {recipe[0].recipe_class}
            </li>
            <li>
              <strong>Estado:</strong> {recipe[0].recipe_status}
            </li>
            <li>
              <strong>Número de doses:</strong> {recipe[0].recipe_num_doses === null ? "Não definido" : recipe[0].recipe_num_doses}
            </li>
          </ul>
        </li>
        <li>
          <h2 style={{ color: darkMode ? "white" : "black" }}>Ingredientes</h2>
          {ingredients.map((item: any, index: any) => {
            return (
              <li key={index} className="py-4 px-4">
                <div style={{ color: darkMode ? "white" : "black" }}><strong>Ingrediente:</strong> {item["ingred"][0].ingr_name}</div>
                <ul>
                  <li style={{ color: darkMode ? "white" : "black" }} className="whitespace-no-wrap text-gray-600">
                    <strong>Descrição:</strong> {item.ingred[0].ingr_descrip === null ? "Não definida" : item.ingred[0].ingr_descrip}
                  </li>
                  <li style={{ color: darkMode ? "white" : "black" }} className="whitespace-no-wrap text-gray-600">
                    <strong>Categoria:</strong> {item.ingred[0].ingr_category === null ? "Não definida" : item.ingred[0].ingr_category}
                  </li>
                  <li style={{ color: darkMode ? "white" : "black" }} className="whitespace-no-wrap text-gray-600">
                    <strong>Quantidade:</strong> {item.quantity.ingr_qt === null ? "Não definida" : item.quantity.ingr_qt} {item.quantity.ingr_qt_unit}
                  </li>
                  <li>
                    <strong>Nível de processamento:</strong> {item.ingred[0].ingr_degree_process === null ? "Não definido" : item.ingred[0].ingr_degree_process}
                  </li>
                  {/* <li style={{ color: darkMode ? "white" : "black" }} className="whitespace-no-wrap text-gray-600">
                  <strong>Quantidade:</strong> {item["qnt"][0]?.ingr_qt} {item["qnt"][0]?.ingr_qt_unit}
                </li> */}
                  {/* <li>
                  {item.skus.length >= 1 && <h2  style={{ color: darkMode ? "white" : "black" }}>Produtos</h2>}
                  <ul className="pl-10">
                    {item.skus.map((sku: any, index: any) => {
                      return (
                        <li key={index} className="pb-4 px-4" style={{ color: darkMode ? "white" : "black" }}>
                          <div className="pl-10 bg-zinc-400"></div>
                          <div><strong>Produto:</strong> {sku.sku_prod_name}</div>
                          <ul >
                            <li><strong>Embalagem:</strong> {sku.sku_packing}</li>
                            <li><strong>Categoria:</strong> {sku.sku_category}</li>
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </li> */}
                  {item.children && <div className="px-8 "><IngredientList recipe={item.children.recipes} ingredients={item.children.ingreds} darkMode={darkMode} /></div>}
                </ul>
              </li>
            )
          })}
        </li>
        <div className="p-8 ">
          <strong>Instruções de preparação:</strong> {recipe[0].recipe_intructions === null || recipe[0].recipe_intructions === "" ? "Não definidas" : recipe[0].recipe_intructions}
        </div>
      </ul>
    );
  };
  return (
    <IonContent>
      {data && (
        <div
          style={{ paddingBottom: "50px" }}
          className="w-[60%] mx-auto text-black"
        >
          <BackComponent></BackComponent>
          <div className="flex items-center mt-10">
            <IonText
              style={{ color: darkMode ? "white" : "black" }}
              className="text-4xl font-semibold text-blue-dark"
            >
              {data["recipes"][0].recipe_name} {data["recipes"][0].recipe_class}
            </IonText>
          </div>

          <InfoComponent text="">
            <p>
              Ficha técnica para a receita{" "}
              {data["recipes"][0].recipe_name}. Receita criada a{" "}
              {formatDate(data["recipes"][0].ztimestamp)}.
            </p>
          </InfoComponent>
          {data && data["ingreds"].length > 0 ? (
            <div style={{ backgroundColor: darkMode ? "#262853" : "" }} className="relative min-w-full bg-white shadow-2xl rounded px-4 py-4 select-text">
              {/* <button className="top-10 absolute right-4" onClick={downloadPDF}>Download</button> */}
              <div style={{ color: darkMode ? "white" : "black" }} id={`detail-recipe-${recipeId}`}>
                <IngredientList recipe={data["recipes"]} ingredients={data["ingreds"]} darkMode={darkMode} />
              </div>

            </div>
          ) : (
            <div
              style={{ color: darkMode ? "white" : "black" }}
              className="text-center text-2xl text-blue-dark"
            >
              Não existem ingredientes associados a esta receita.
            </div>
          )}
        </div>
      )}
      <Loading isOpen={loading} />
    </IonContent>
  );
};

{/* <table cellspacing="0" cellpadding="15">
  <tbody>
    <tr class="first-row">
      <th colspan="3">&#8595; Alfreds Futterkiste</th>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" disabled></td>
    </tr>
    <tr class="second-row">
      <td class="spacer"></td>
      <th colspan="2">&#8595; Level 2</th>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" disabled></td>
    </tr>
    <tr class="third-row">
      <td class="spacer"></td>
      <td class="spacer"></td>
      <th>&#8595; Level 3</th>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" disabled></td>
    </tr>
    <tr class="fourth-row">
      <th colspan="3">&#8595; Maria Anders</th>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" disabled></td>
    </tr>
    <tr class="fifth-row">
      <th colspan="3">&#8595; Germany</th>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" value="0"></td>
      <td><input type="text" disabled></td>
    </tr>
  </tbody>
</table> */}

export default RecipeTec;
