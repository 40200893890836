
import Home from "../../../components/App/market/home";
import Page from "../page";
import { useEffect, useState } from "react";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import Loading from "../../../components/App/reusable/loading/loading";
import { FaHome } from "react-icons/fa";

const HomePage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'HOME PAGE',
  });

  return (
    <Page title={"Home"} icon={<FaHome size={32}/>}>
      {tokenVerified ? (<Home />) : <Loading isOpen={true} />}
    </Page>

  );
};

export default HomePage;
