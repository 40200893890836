/* Core CSS required for Ionic components to work properly */
// import "@ionic/react/css/core.css";
import "./theme/Core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { Redirect, Route, Switch } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import NotFoundPage from "./pages/NotFound";
import LoginPage from "./pages/Auth/SignIn";
//import LoginPage from "./pages/App/signIn-signUp/SignIn-SignUp";

import RegisterPage from "./pages/Auth/SignUp";
import AppTabs from "./AppTabs";
import RegisterFormPage from "./pages/Auth/SignUp-Form";
import { DarkModeProvider } from "./utils/contexts/theme.context";
import { AuthProvider } from "./utils/contexts/AuthProvider";
import { Toaster } from "react-hot-toast";

setupIonicReact();
const App = () => {
  return (
    <AuthProvider>
      <DarkModeProvider>
        <IonReactRouter>
          <IonApp>
            <Toaster />
            <Routing />
          </IonApp>
        </IonReactRouter>
      </DarkModeProvider>
    </AuthProvider>
  );
};

const Routing = () => {
  return (
    <IonRouterOutlet>
      <Switch>
        <Redirect exact path="/" to="/login" />
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/register">
          <RegisterPage />
        </Route>
        <Route exact path="/register/completion">
          <RegisterFormPage />
        </Route>
        <Route path="/app">
          <AppTabs />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
        
      </Switch>
    </IonRouterOutlet>
  );
};

// DO NOT DELETE THIS!!!!!!!!!!!!!!!!!
// const ProtectedRoute = ({...props}: Route) => {
//   const {currentUser} = useUser();

//   if(!currentUser) {
//     <Redirect  />
//   }

//   return (
//     <Route {...props}>{props.children}</Route>
//   )
// }

export default App;
