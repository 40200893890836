import {
  InputChangeEventDetail,
  IonButton,
  IonCard,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import { useEffect, useState } from "react";

import useDarkMode from "../../../pages/Profile/usedarkmode";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import InfoComponent from "../reusable/info/info.component";
import Loading from "../reusable/loading/loading";
import { width } from "@mui/system";
import Toaster from "../reusable/Toaster";

const SaftRestaurantPage = () => {
  const axiosPrivate = useAxiosPrivate();

  // State Variables
  const [rule1, setRule1] = useState([]);
  const [rule2, setRule2] = useState([]);
  const [rule3, setRule3] = useState([]);
  const [loading, setLoading] = useState(true);
  const [changedList, setChangedList] = useState(false);
  const [descriptive, setDescriptive] = useState("");
  const [exclude, setExclude] = useState("");
  const [value, setValue] = useState(0);

  useEffect(() => {
    let isMounted = true;

    const controller = new AbortController();
    const getRules = async (endpoint: string) => {
      setLoading(true);
      try {
        const response = await axiosPrivate.get(endpoint, {
          signal: controller.signal,
        });

        const data = response.data;
        setRule1(
          data.find((rule) => rule.sales_dt_rule_type === "MAP-IDSITE") ?? ""
        );
        setRule2(
          data.find((rule) => rule.sales_dt_rule_type === "EXCLUDE") ?? ""
        );
        setRule3(
          data.find((rule) => rule.sales_dt_rule_type === "DAY-START") ?? ""
        );
        setValue(
          data.find((rule) => rule.sales_dt_rule_type === "DAY-START" ?? "")
            ?.sales_dt_rule_value
        );


        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    getRules("saft-rules-list");

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);

  const handleRule1Submit = async () => {
    const data = {
      saft_rule_id: rule1 ? rule1["saft_rule_id"] : 0,
      sales_dt_rule_name: "Associa site ao descritivo do SAFT",
      sales_dt_rule_tag: descriptive,
      sales_dt_rule_target: "companyid",
      sales_dt_rule_type: "MAP-IDSITE",
      sales_dt_rule_value: 0,
    };

    try {
      const response = await axiosPrivate.post(
        "/create-update-saft-rules/",
        JSON.stringify(data)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Regra 1 atualizada com sucesso.", "success");
        setChangedList(!changedList);
      } else {
        Toaster.show("Erro ao atualizar regra 1.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao atualizar regra 1.", "error");
    }
  };

  const handleRule2Submit = async () => {
    const data = {
      saft_rule_id: rule2 ? rule2["saft_rule_id"] : 0,
      sales_dt_rule_name: "Excluir facturas com prodcode",
      sales_dt_rule_tag: exclude,
      sales_dt_rule_target: "productcode",
      sales_dt_rule_type: "EXCLUDE",
      sales_dt_rule_value: 0,
    };

    try {
      const response = await axiosPrivate.post(
        "/create-update-saft-rules/",
        JSON.stringify(data)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Regra 2 atualizada com sucesso.", "success");
        setChangedList(!changedList);
      } else {
        Toaster.show("Erro ao atualizar regra 2.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao atualizar regra 2.", "error");
    }
  };

  const handleRule3Submit = async () => {
    const data = {
      saft_rule_id: rule3 ? rule3["saft_rule_id"] : 0,
      sales_dt_rule_name: "Definir inicio do dia",
      sales_dt_rule_tag: "n/d",
      sales_dt_rule_target: "systementrydate",
      sales_dt_rule_type: "DAY-START",
      sales_dt_rule_value: value,
    };

    try {
      const response = await axiosPrivate.post(
        "/create-update-saft-rules/",
        JSON.stringify(data)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Regra 3 atualizada com sucesso.", "success");
        setChangedList(!changedList);
      } else {
        Toaster.show("Erro ao atualizar regra 3.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao atualizar regra 3.", "error");
    }
  };

  const handleApplyRules = async () => {
    try {
      const response = await axiosPrivate.post("/apply_saft_rules/");

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Regras aplicadas com sucesso.", "success");
      } else {
        Toaster.show("Erro ao aplicar regras.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao aplicar regras.", "error");
    }
  }

  const darkMode = useDarkMode();

  return (
    <IonContent>
      <div className="w-[60%] m-auto mt-10">
        <div className="flex justify-between px-2">
          <h1 className="my-auto">Filtros SAFT</h1>
          <button onClick={handleApplyRules} style={{ background: darkMode ? "#422655" : '' }} className="text-white w-52 bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300">
            <p>Reprocessar dados e Aplicar Regras</p>
          </button>
        </div>
        <IonCard>
          <IonList>
            <InfoComponent text="Text de ajuda...">
              <div>
                <p>
                  <strong>
                    <span style={{ fontSize: "11pt" }}>
                      Regra de Mapeamento
                    </span>
                  </strong>
                  <span style={{ fontSize: "11pt" }}>&nbsp;</span>
                </p>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    Pressup&otilde;e-se que cada unidade de neg&oacute;cios da
                    entidade jur&iacute;dica a que os dados dizem respeito tem
                    um descritivo &uacute;nico e esse &eacute; usado para
                    associar os dados com o Restaurante cliente definido na
                    aplica&ccedil;&atilde;o.&nbsp;
                  </span>
                </p>
              </div>
            </InfoComponent>
            <IonItem className="pt-4">
              <IonInput
                label="Descritivo de atividade:"
                name="name"
                placeholder={rule1 ? rule1["sales_dt_rule_tag"] : ""}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                  setDescriptive(e.detail.value);
                }}
              ></IonInput>
            </IonItem>
            <IonButton className="ml-2 py-3" onClick={handleRule1Submit}>
              Regra 1
            </IonButton>
          </IonList>
        </IonCard>

        <IonCard>
          <IonList>
            <InfoComponent text="Text de ajuda...">
              <div>
                <p>
                  <strong>
                    <span style={{ fontSize: "11pt" }}>
                      Regra de Filtro de Produtos
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    Permite que a mesma empresa facture diferentes
                    servi&ccedil;os e produtos e que apenas os ligados &agrave;
                    restaura&ccedil;&atilde;o sejam considerados para
                    an&aacute;lise. O utilizador deve indicar um descritivo e a
                    tag a usar para filtrar os dados. Serão excluidas faturas
                    com o prodcode SAFT com o valor indicado.
                  </span>
                </p>
              </div>
            </InfoComponent>
            <IonItem className="pt-4">
              <IonInput
                label="Para excluir:"
                name="name"
                placeholder={rule2 ? rule2["sales_dt_rule_tag"] : ""}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                  setExclude(e.detail.value);
                }}
              ></IonInput>
            </IonItem>
            <IonButton className="ml-2 py-3" onClick={handleRule2Submit}>
              Regra 2
            </IonButton>
          </IonList>
        </IonCard>

        <IonCard>
          <IonList>
            <InfoComponent text="Text de ajuda...">
              <div>
                <p>
                  <strong>
                    <span style={{ fontSize: "11pt" }}>
                      Regra de Ajuste de data refer&ecirc;ncia&nbsp;
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    Neste tipo de regra o utilizador pode escolher a
                    mudan&ccedil;a de dia para efeitos de an&aacute;lise das
                    vendas o que significa que positivos ir&atilde;o prolongar o
                    dia em X horas enquanto valores negativos ir&atilde;o
                    antecipar o dia em X horas.&nbsp;
                  </span>
                </p>
              </div>
            </InfoComponent>
            <IonItem className="pt-4">
              <IonSelect
                label="Ajuste de hora:"
                slot="start"
                aria-label="Hora"
                interface="popover"
                placeholder={value ? value?.toString() + " horas" : ""}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                  setValue(Number(e.detail.value));
                }}
              >
                {[
                  -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8,
                ].map((hour) => (
                  <IonSelectOption
                    value={hour}
                  >{`${hour} horas`}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            <IonButton className="ml-2 py-3" onClick={handleRule3Submit}>
              Regra 3
            </IonButton>
          </IonList>
        </IonCard>
      </div>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default SaftRestaurantPage;
