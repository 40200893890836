import { useEffect, useState } from "react";
import { IonContent, IonGrid, IonCol, IonRow, IonModal } from "@ionic/react";
import { MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";

import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import MyTable from "../reusable/table/table-component";
import Loading from "../reusable/loading/loading";
import InfoComponent from "../reusable/info/info.component";
import Toaster from "../reusable/Toaster";

import "../general.styles.scss";

const initialColumns = [
  { headerName: "Nome", field: "an_nome" },
  { headerName: "Descritivo Forecast", field: "an_descr" },
  { headerName: "Estado", field: "an_obs" },
  { headerName: "Data de início", field: "an_dt_ini" },
  { headerName: "Data de fim", field: "an_dt_fim" },
  { headerName: "Análise", field: "consultar" },
];

const initialNewForecastData = {
  an_name: "",
  an_decrip: "",
};

const Forecasts = () => {
  const { get, post } = useApi();
  const darkMode = useDarkMode();
  
  const [forecast, setForecast] = useState(null);
  const [forecastAvailable, setForecastAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [newForecastData, setNewForecastData] = useState(initialNewForecastData);

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const [forecastResponse, availabilityResponse] = await Promise.all([
          get("list_forecasts", { signal: controller.signal }),
          get("forecast_requirements"),
        ]);

        setForecast(forecastResponse.data);
        setForecastAvailable(availabilityResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Toaster.show("Erro ao carregar dados de forecast", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => controller.abort();
  }, [get]);

  const addConsultarAttribute = (arr: any[]) =>
    arr.map((item) => ({
      ...item,
      consultar: item.an_obs === "Disponível" ? <MdSearch size={32} /> : "",
    }));

  const handleCreateForecast = async () => {
    setShowModal(false);
    const newForecast = {
      an_descr: newForecastData.an_decrip,
      an_nome: newForecastData.an_name,
      an_obs: "Em progresso...",
    };
    setForecast((prevForecast: any) => [...prevForecast, newForecast]);

    try {
      await post(
        `new_forecast/?name=${newForecastData.an_name}&description=${newForecastData.an_decrip}`,
        JSON.stringify(newForecastData)
      );
      Toaster.show("Forecast criado com sucesso!", "success");
    } catch (error) {
      console.error("Erro ao criar forecast:", error);
      Toaster.show("Erro ao criar forecast", "error");
    }

    setNewForecastData(initialNewForecastData);
  };

  const renderModalContent = () => {
    if (!forecastAvailable) {
      return (
        <>
          <InfoComponent>
            <p className="text-justify">
              A criação do forecast necessita de dados mais recentes. Por favor carregue os seus ficheiros de faturação até à pelo menos 6 semanas.
            </p>
          </InfoComponent>
          <div className="flex justify-between gap-x-3">
            <Link to="/app/uploadsaft" className="w-full">
              <button className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300">
                Carregar Dados
              </button>
            </Link>
            <button
              onClick={() => setShowModal(false)}
              className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
            >
              Cancelar
            </button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="mb-4 flex">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Nome
            </label>
            <input
              placeholder="Nome do Forecast"
              className="w-full bg-gray-200 border rounded py-2 px-3"
              type="text"
              value={newForecastData.an_name}
              onChange={(e) =>
                setNewForecastData({
                  ...newForecastData,
                  an_name: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Descrição
          </label>
          <textarea
            placeholder="Descrição do Forecast"
            className="w-full bg-gray-200 border rounded py-2 px-3"
            value={newForecastData.an_decrip}
            onChange={(e) =>
              setNewForecastData({
                ...newForecastData,
                an_decrip: e.target.value,
              })
            }
          />
        </div>
        <div className="flex justify-between gap-x-3">
          <button
            onClick={handleCreateForecast}
            disabled={!newForecastData.an_name || !newForecastData.an_decrip}
            className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
          >
            Criar
          </button>
          <button
            onClick={() => setShowModal(false)}
            className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
          >
            Cancelar
          </button>
        </div>
      </>
    );
  };

  return (
    <DarkModeProvider>
      <IonContent className="ion-padding">
        <IonRow className="paddingButton">
          <button
            onClick={() => setShowModal(true)}
            className="text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
          >
            + Criar Forecast
          </button>
        </IonRow>
        <IonGrid>
          <IonRow
            style={{
              paddingBottom: "70px",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <IonCol>
              {forecast && (
                <MyTable
                  rowData={addConsultarAttribute(forecast)}
                  columnDefs={initialColumns}
                  link="/app/forecasts/forecast?id="
                  linkIndex={4}
                  trueLinkIndex={0}
                  title="Análises de Forecast"
                  darkMode={darkMode}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <Loading isOpen={loading} />
      </IonContent>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <form
          className="bg-white p-10 shadow-md"
          style={{
            background: darkMode ? "#422655" : "",
            height: "100%",
            overflowY: "auto",
            paddingBottom: "50px",
          }}
        >
          <div className="text-2xl mb-10 font-semibold text-blue-dark text-center">
            Criar Forecast
          </div>
          {renderModalContent()}
        </form>
      </IonModal>
    </DarkModeProvider>
  );
};

export default Forecasts;