// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changeScale {
  position: absolute;
  top: 8px;
  right: 30px;
  height: 40px;
  width: 95px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.changeScaleIcon:hover {
  color: rgb(72, 72, 238);
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-background);
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: var(--scrollbar-background);
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-background);
  border-radius: 10px;
}

.helpButton {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.help-description {
  text-align: justify;
  position: absolute;
  top: 40px;
  left: 10px;
  width: 300px;
  padding: 10px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/components/App/reusable/charts/chart-component.styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EAGA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,sCAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAKA;EACE,qBAAA;EACA,kDAAA;AAFF;;AAMA;EACE,WAAA;EACA,6CAAA;EACA,mBAAA;AAHF;;AAOA;EACE,6CAAA;EACA,mBAAA;AAJF;;AAQA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAGA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;AAPF;;AAUA;EACE,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,0CAAA;AAPF","sourcesContent":[".changeScale {\n  position: absolute;\n  top: 8px;\n  right: 30px;\n  //color: #ffffff;\n  //background-color: #7d3695;\n  height: 40px;\n  width: 95px;\n  border-radius: 5px;\n  transition: background-color 0.3s ease;\n}\n\n.changeScaleIcon:hover {\n  color: rgb(72, 72, 238);\n}\n\n\n.scrollbar {\n  scrollbar-width: thin;\n  scrollbar-color: var(--scrollbar-thumb-background);\n\n}\n\n.scrollbar::-webkit-scrollbar {\n  width: 10px;\n  background-color: var(--scrollbar-background);\n  border-radius: 10px;\n\n}\n\n.scrollbar::-webkit-scrollbar-thumb {\n  background: var(--scrollbar-thumb-background);\n  border-radius: 10px;\n}\n\n\n.helpButton {\n  position: absolute;\n  top: 10px;\n  left: 10px;\n  width: 24px;\n  height: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  //background-color:  #7d3695;\n  //color: #ffffff;\n  font-weight: bold;\n  font-size: 18px;\n  border-radius: 50%;\n  cursor: pointer;\n}\n\n.help-description {\n  text-align: justify;\n  position: absolute;\n  top: 40px;\n  left: 10px;\n  width: 300px;\n  padding: 10px;\n  background-color: #ffffff;\n  color: #000000;\n  border-radius: 5px;\n  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
