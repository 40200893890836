import React, { useEffect, useState, useCallback, useRef } from 'react';
import { IonContent, IonGrid, IonCol, IonRow } from "@ionic/react";
import ChartComponent from "../reusable/charts/chartComponent";
import ChartFunc from "../reusable/charts/chartFunc";
import MyTable from "../reusable/table/table-component";
import SelectComponent from "../reusable/select/select";
import Loading from "../reusable/loading/loading";
import NWeeksHelp from "../reusable/NWeeksHelp";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import NWeeksFormatter from "../reusable/select/nweeks";
import "../general.styles.scss";

const INITIAL_CHART_STATE = {};
const INITIAL_CHART_FUNC_STATE = {
  func: '',
  option: {},
  drilldown_data: []
};
const INITIAL_COLUMNS = [
  { headerName: 'Name', field: 'name' },
  { headerName: 'Category', field: 'category' },
  { headerName: 'Price', field: 'price' },
  { headerName: 'Variation', field: 'variation' }
];

const GeneralGraphs = () => {
  const darkMode = useDarkMode();
  const { get } = useApi();
  const [chartData, setChartData] = useState({
    q11: INITIAL_CHART_FUNC_STATE,
    q12: INITIAL_CHART_STATE,
    q21: INITIAL_CHART_STATE,
    q22: INITIAL_CHART_STATE,
    q31: INITIAL_CHART_STATE,
    q32: INITIAL_CHART_STATE,
  });
  const [columnsD1Q22] = useState(INITIAL_COLUMNS);
  const [nweeks, setNweeks] = useState(4);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef();

  const fetchChartData = useCallback(async (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `dash1_v2_${endpoint}/${queryString ? `?${queryString}` : ''}`;

    try {
      setLoading(true);
      const response = await get(url);
      setChartData(prevData => ({
        ...prevData,
        [endpoint]: response.data
      }));
    } catch (error) {
      console.error(`Error fetching data for ${endpoint}:`, error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const endpoints = ['q11', 'q12', 'q21', 'q22', 'q31', 'q32'];
    endpoints.forEach(endpoint => fetchChartData(endpoint, { nweeks }));
  }, [nweeks, fetchChartData]);

  const renderChart = (ChartComponent, option, props = {},) => (
    <ChartComponent
      option={option}
      chartRef={chartRef}
      {...props}
    />
  );

  return (
    <IonContent>
      <IonGrid className='padding'>
        <div className="px-14 py-4 flex items-center">
          <div className="w-[30%]">
            <SelectComponent
              formatter={NWeeksFormatter}
              darkMode={darkMode}
              onChange={setNweeks}
            />
          </div>
          <div className="w-[70%]">
            <NWeeksHelp nweeks={nweeks} />
          </div>
        </div>
        <div className="pb-30 px-50 grid xl:grid-cols-2 gap-4 ">
          {renderChart(ChartFunc, chartData.q11.option, {
            func: chartData.q11.func,
            drilldown_data: chartData.q11.drilldown_data,
          })}
          {renderChart(ChartComponent, chartData.q12)}
          {renderChart(ChartComponent, chartData.q21)}
          <MyTable
            rowData={chartData.q22}
            columnDefs={columnsD1Q22}
            title="Produtos com maior variação"
            darkMode={darkMode}
          />
          {renderChart(ChartComponent, chartData.q31)}
          {renderChart(ChartComponent, chartData.q32)}
        </div>
      </IonGrid>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default GeneralGraphs;