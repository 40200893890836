import React, { useEffect, useState } from "react";
import * as Components from '../../../components/App/signIn-signUP/signInUp-component';
import SignIn from "../../../components/Auth/sign-forms/sign-in.component";
import { axiosPrivate } from "../../../utils/api/axios";
import useAuth from "../../../utils/hooks/useAuth";
import { useHistory, useLocation } from "react-router";

import axios from '../../../utils/api/axios';
import { IonLoading } from "@ionic/react";
import CategoryFormatter from "../../../components/App/reusable/select/categories";
import Loading from "../../../components/App/reusable/loading/loading";
import Toaster from "../../../components/App/reusable/Toaster";


function SignInSignUp() {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false)
    const { auth, setAuth } = useAuth();
    const history = useHistory();
    const location = useLocation<{ from: string }>(); // Explicitly type the location state
    const from =
        location.state?.from && typeof location.state.from === 'string'
            ? location.state.from
            : '/app/homepage';
    const [signIn, setSignIn] = useState(true);
    const [show, setShow] = useState(false);
    const [see, setSee] = useState(true);

    const toggleSignIn = () => {
        setSignIn(!signIn);
    };

    const toggleShow = () => {
        setShow(!show);
    };

    const toggleSee = () => {
        setSee(false);
        setTimeout(() => setSee(true), 400);

    }

    const handleSubmit = async () => {
        setLoading(true);
        if (name === "" || password === "") {
            //setMessage("Por favor, tente mais tarde.");
            Toaster.show("Por favor, preencha os campos.", "error");
            setLoading(false);
            return;
        }
        try {
            await signInUser();
            setLoading(false);
        } catch (error) {
            console.log(error)
            switch (error.code) {
                case "auth/wrong-password":
                    // Toaster.show("A password para esta conta está errada.");
                    setLoading(false);
                    //setMessage("A password para esta conta está errada.");
                    break;
                case "auth/user-not-found":
                    // Toaster.show("Não existe nenhum utilizador com este nome registado.");
                    setLoading(false);
                    //setMessage("Não existe nenhum utilizador com este  name registado.");
                    break;
                default:
                    // Toaster.show("Por favor, tente mais tarde.");
                    // console.log("HERE AJOHUD")

                    setLoading(false);
                    //setMessage("Por favor, tente mais tarde.");
                    console.log(error.message);
            }
        }
    };


    const LOGIN_URL = "login";
    const LOGS_URL = "/create_log"

    const signInUser = async () => {
        if (!name || !password) {
            throw new Error();
        }
        try {

            const response = await axios.post('/login', {
                username: name,
                password: password
            });

            const accessToken = response?.data?.access;
            const roles = response?.data?.roles;
            setAuth((prevAuth) => ({
                ...prevAuth,
                user: name,
                pwd: password,
                roles: roles,
                accessToken: accessToken,
            }));
            //logs
            const logData = {
                name: name,
                log_type: 'LOGIN',
                log_oper_status: 'SUCCESS',
                log_oper_descr: 'teste',
                log_obs: 'dummyObs',
            };
            try {
                const responseLogs = await axiosPrivate.post(LOGS_URL, logData, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
            }
            catch (err) {
                console.log("Error creating log: ", err);
            }
            setName('');
            setPassword('');

            history.replace("/app/homepage");
            //window.location.reload();
        } catch (err) {
            const logData = {
                name: name,
                log_type: 'LOGIN',
                log_oper_status: 'ERROR',
                log_oper_descr: 'teste',
                log_obs: 'dummyObs',
            };
            const responseLogs = await axiosPrivate.post(LOGS_URL, logData, {
            });
            setLoading(false);
            if (!err?.response) {
                throw new Error();
            } else if (err.response?.status === 400) {
                throw new Error();
            } else if (err.response?.status === 401) {
                throw new Error();
            } else if (err.response?.status === 404) {
                Toaster.show("Utilizador ou password incorreta.", "error");
                throw new Error();
            } else {
                Toaster.show("Por favor tente mais tarde.", "error");
                throw new Error();
            }
        }

    };

    const redirectToSite = () => {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfpq_YkuGPGYwm8UaPXRBDOI191Gz4gEjW8qaeWXCfX06zahQ/viewform?usp=sf_link';
    };


    return (
        <div style={{ background: "transparent", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh", margin: "-20px 0 50px" }}>
            <Components.Container>
                <Components.SignUpContainer signIn={signIn}>
                    <Components.Form >
                        <Components.Title>Crie uma conta</Components.Title>
                        {/* <Components.Input type='text' placeholder='Nome' onChange={(e) => setName(e.target.value)} />
                        <Components.Input type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                        <Components.InputPass color={see} signIn={signIn} show={show} onClick={() => toggleShow()} onChange={(e) => setPassword(e.target.value)} placeholder='Password' /> */}
                        <Components.Button onClick={redirectToSite} signIn={signIn} ></Components.Button>
                    </Components.Form>
                </Components.SignUpContainer>

                <Components.SignInContainer signIn={signIn}>
                    <Components.Form>
                        <Components.Title>Iniciar sessão</Components.Title>
                        <Components.Input type='text' placeholder='Nome de utilizador' onChange={(e) => setName(e.target.value)} />
                        <Components.InputPass color={see} signIn={signIn} show={show} onClick={() => toggleShow()} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
                        <Components.Button onClick={handleSubmit} signIn={signIn} ></Components.Button>
                        {/* <Components.Anchor href='#'>Esqueceu-se da password?</Components.Anchor> */}
                    </Components.Form>
                </Components.SignInContainer>

                <Components.OverlayContainer signIn={signIn}>
                    <Components.Overlay signIn={signIn}>

                        <Components.LeftOverlayPanel signIn={signIn}>
                            <Components.Title color="white">Bem-Vindo de volta!</Components.Title>
                            <Components.Paragraph>
                                Para fazer login clique no botão abaixo!
                            </Components.Paragraph>
                            <Components.GhostButton onClick={() => { toggleSignIn(); toggleSee() }}>
                                Login
                            </Components.GhostButton>
                        </Components.LeftOverlayPanel>

                        <Components.RightOverlayPanel signIn={signIn}>
                            <Components.Title color="white">Bem-Vindo!</Components.Title>
                            <Components.Paragraph>
                                Preencha o formulário e comece já a analisar a sua cozinha
                            </Components.Paragraph>
                            <Components.GhostButton onClick={() => { toggleSignIn(); toggleSee() }}>
                                Aderir
                            </Components.GhostButton>
                        </Components.RightOverlayPanel>

                    </Components.Overlay>
                </Components.OverlayContainer>
                <Loading isOpen={loading} />
            </Components.Container>
        </div>
    );
}

export default SignInSignUp;
