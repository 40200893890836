import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonToolbar,
} from "@ionic/react";
import ChangePassword from "../../components/Auth/change-password/change-password.component";
import "./change-password.styles.scss";

const ChangePasswordPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Voltar" defaultHref="/app/profile" />
          </IonButtons>
          <IonTitle className="change-pwd-title">Alterar Palavra-chave</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="change-pwd-content">
        <ChangePassword />
      </IonContent>
    </IonPage>
  );
};

export default ChangePasswordPage;
