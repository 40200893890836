import React, { useEffect, useState } from 'react';
import { IonContent, IonGrid, IonCol, IonRow, IonModal } from "@ionic/react";
import { FaPencilAlt } from "react-icons/fa";
import { MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";


import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import Loading from "../reusable/loading/loading";

import "../general.styles.scss";
import Toaster from '../reusable/Toaster';

interface Plan {
    an_id: string;
    an_nome: string;
    an_descr: string;
    an_obs: string;
    an_dt_ini: string;
    an_dt_fim: string;
    price_ini: number;
}

interface ShopListItem {
    recipe_id: string;
    recipe_name: string;
}

const Plannings: React.FC = () => {
    const { get, post } = useApi();
    const [plans, setPlans] = useState<Plan[]>([]);
    const [loading, setLoading] = useState(true);
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showModalCreateInfo, setShowModalCreateInfo] = useState(false);
    const [inputValues, setInputValues] = useState<{ [key: string]: { item: ShopListItem; num_doses: number } }>({});
    const [newPlan, setNewPlan] = useState({ name: "", description: "" });
    const [anId, setAnId] = useState("");
    const [shopList, setShopList] = useState<ShopListItem[]>([]);

    const darkMode = useDarkMode();

    useEffect(() => {
        fetchPlans();
    }, []);

    const fetchPlans = async () => {
        setLoading(true);
        try {
            const response = await get("list_plans");
            setPlans(response.data);
        } catch (error) {
            console.error(error);
            Toaster.show("Erro ao carregar listas de planeamento", "error");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (recipeId: string, value: { item: ShopListItem; num_doses: number }) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [recipeId]: value
        }));
    };

    const handleCreatePlan = async () => {
        setShowModalCreate(false);
        const newCustomer: Plan = {
            an_id: "", // Add an_id property with an empty string value
            an_nome: newPlan.name,
            an_descr: newPlan.description,
            an_obs: "Em progresso...",
            an_dt_ini: "Em progresso...",
            an_dt_fim: "Em progresso...",
            price_ini: 0,
        };
        setPlans(prevPlans => [...prevPlans, newCustomer]);
        try {
            await post(`create_planning/?name=${newPlan.name}&description=${newPlan.description}`, {});
        } catch (error) {
            console.error("Erro ao criar analise:", error);
        } finally {
            setLoading(false);
            fetchPlans();
            setNewPlan({ name: "", description: "" });
        }
    };

    const handleConfigurePlan = async (item: Plan) => {
        setAnId(item.an_id);
        setShowModalCreateInfo(true);
        setLoading(true);
        try {
            const response = await get("shopping_list");
            setShopList(response.data);
        } catch (error) {
            console.error("Erro buscar LISTA DE COMPRAS:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCreatePlanCustom = async () => {
        setShowModalCreateInfo(false);
        const newPlanData = {
            name: newPlan.name,
            recipes: inputValues
        };
        try {
            await post(`plan_create/?an_id=${anId}`, newPlanData);
        } catch (error) {
            console.error("Erro ao criar analise:", error);
        } finally {
            fetchPlans();
        }
    };

    const handleDisplayPlan = (plan: string) => {
        const match = plan.match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
    };

    const renderTable = (items: any[], columns: string[], renderRow: (item: any) => JSX.Element) => (
        <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
            <thead className="bg-blue-dark text-white rounded">
                <tr>
                    {columns.map(column => (
                        <th
                            key={column}
                            style={{ background: darkMode ? "#422655" : "" }}
                            className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                        >
                            {column}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody style={{ backgroundColor: darkMode ? "#262853" : "" }} className="bg-white divide-y divide-gray-200">
                {items.map(renderRow)}
            </tbody>
        </table>
    );

    return (
        <DarkModeProvider>
            <IonContent className="ion-padding">
                <IonRow className="paddingButton">
                    <button
                        onClick={() => setShowModalCreate(true)}
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
                    >
                        + Criar Planeamento
                    </button>
                </IonRow>
                <IonGrid>
                    <IonRow style={{ paddingBottom: "30px", paddingLeft: "50px", paddingRight: "50px" }}>
                        <IonCol>
                            {plans.length > 0 ? (
                                renderTable(
                                    plans,
                                    ["Nome", "Descritivo", "Estado", "Actions"],
                                    (item: Plan) => (
                                        <tr key={item.an_id}>
                                            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                                                {item.an_nome}
                                            </td>
                                            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                                                {item.an_descr}
                                            </td>
                                            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                                                {item.an_obs.includes("disponivel") ? "Disponível" : "Por configurar"}
                                            </td>
                                            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                                                <div className="flex space-x-4">
                                                    {!item.an_obs.includes("disponivel") ? (
                                                        <button
                                                            className="hover:scale-125 transition-transform duration-30"
                                                            onClick={() => handleConfigurePlan(item)}
                                                        >
                                                            <FaPencilAlt title="Configurar" size={24} />
                                                        </button>
                                                    ) : (
                                                        <Link to={`/app/planning/custom/${handleDisplayPlan(item.an_obs)}`} className="hover:scale-125 transition-transform duration-30">
                                                            <MdSearch title="Consultar" size={32} />
                                                        </Link>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )
                            ) : (
                                <div style={{ color: darkMode ? "white" : "black" }} className="text-center text-2xl mt-10">
                                    Sem planeamentos de compras. Crie um novo planeamento.
                                </div>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Loading isOpen={loading} />
            </IonContent>

            <IonModal isOpen={showModalCreate} onDidDismiss={() => setShowModalCreate(false)}>
                <div className="overflow-auto p-10">
                    <h2
                        style={{
                            color: darkMode ? "white" : "black",
                            paddingBottom: "20px",
                            textAlign: "center",
                        }}
                    >
                        Criar planeamento de compras
                    </h2>

                    <div className="flex flex-col gap-8">
                        <div className='flex flex-col gap-2'>
                            <label className={`text-xl font-bold ${darkMode ? "text-white" : "text-black"}`}>Nome</label>
                            <input
                                type="text"
                                placeholder="Nome do Planeamento"
                                value={newPlan.name}
                                style={{
                                    color: darkMode ? "white" : "black",
                                    backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                                }}
                                onChange={(e) =>
                                    setNewPlan({ ...newPlan, name: e.target.value })
                                }
                                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className={`text-xl font-bold ${darkMode ? "text-white" : "text-black"}`}>Descrição</label>
                            <textarea
                                placeholder="Descrição do Planeamento"
                                value={newPlan.description}
                                style={{
                                    color: darkMode ? "white" : "black",
                                    backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                                }}
                                onChange={(e) =>
                                    setNewPlan({
                                        ...newPlan,
                                        description: e.target.value,
                                    })
                                }
                                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
                            />
                        </div>
                        <div className="flex justify-between gap-x-3 pt-5">
                            <button
                                onClick={handleCreatePlan}
                                disabled={
                                    newPlan.name === "" ||
                                    newPlan.description === ""
                                }
                                style={{ background: darkMode ? "#422655" : "" }}
                                className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
                            >
                                Criar
                            </button>
                            <button
                                onClick={() => setShowModalCreate(false)}
                                style={{ background: darkMode ? "#422655" : "" }}
                                className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </IonModal>

            <IonModal isOpen={showModalCreateInfo} onDidDismiss={() => setShowModalCreateInfo(false)}>
                <h2
                    style={{
                        color: darkMode ? "white" : "black",
                        paddingBottom: "20px",
                        textAlign: "center",
                    }}
                >
                    Configurar planeamento de compras
                </h2>
                <IonCol style={{ padding: "50px", paddingTop: "0px" }}>
                    <div className="pb-5">
                        <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                            <thead className="bg-blue-dark text-white rounded">
                                <tr>
                                    <th
                                        style={{ background: darkMode ? "#422655" : "" }}
                                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                                    >
                                        Nome da receita
                                    </th>
                                    <th
                                        style={{ background: darkMode ? "#422655" : "" }}
                                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                                    >
                                        Número de doses
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                style={{ backgroundColor: darkMode ? "#262853" : "" }}
                                className="bg-white divide-y divide-gray-200"
                            >
                                {shopList.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td
                                            style={{ color: darkMode ? "white" : "black" }}
                                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                                        >
                                            {item.recipe_name}
                                        </td>
                                        <td
                                            style={{ color: darkMode ? "white" : "black" }}
                                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                                        >
                                            <input
                                                className="bg-white"
                                                placeholder="0"
                                                id="number"
                                                type="number"
                                                min={0}
                                                value={inputValues[item.recipe_id]?.["num_doses"] || ''}
                                                onChange={(e) => handleInputChange(item.recipe_id, { item, num_doses: Number(e.target.value) })}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between gap-x-3 pt-5">
                        <button
                            onClick={handleCreatePlanCustom}
                            style={{ background: darkMode ? "#422655" : "" }}
                            className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
                        >
                            Criar
                        </button>
                        <button
                            onClick={() => setShowModalCreateInfo(false)}
                            style={{ background: darkMode ? "#422655" : "" }}
                            className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
                        >
                            Cancelar
                        </button>
                    </div>
                </IonCol>
            </IonModal>
        </DarkModeProvider>
    );
};

export default Plannings;