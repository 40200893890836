import { useEffect, useState } from "react";
import InfoComponent from "./info/info.component";

const NWeeksHelp = ({
    nweeks,
    dateLastSales = null,
}: {
    nweeks: number;
    dateLastSales?: Date;

}) => {
    const [date, setDate] = useState(dateLastSales ? new Date(dateLastSales) : new Date());
    const [dateIni, setDateIni] = useState(new Date().toISOString());
    const [dateFim, setDateFim] = useState(new Date().toISOString());

    useEffect(() => {
        const lastSunday = getLastSunday(date)
        const dataFim = lastSunday.toISOString().split('T')[0]; // Formats the date as "YYYY-MM-DD"
        const dataFimDate = new Date(dataFim);
        dataFimDate.setDate(dataFimDate.getDate() - (nweeks) * 7 + 1); // Add nweeks * 7 days to dataIni
        const dataIni = dataFimDate.toISOString().split('T')[0]; // Formats the date as "YYYY-MM-DD"
        setDateIni(dataIni);
        setDateFim(dataFim);
    }, [date, nweeks]);


    function getLastSunday(date) {
        const currentDate = new Date(date.getTime());
        const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)


        // Calculate last Sunday
        const lastSunday = new Date(currentDate.setDate(currentDate.getDate() - (dayOfWeek)));

        return lastSunday;
    }

    function getWeekNumber(date: Date): number {
        // Copy date so we don't modify the original
        const currentDate = new Date(date.getTime());

        // Set to nearest Thursday: current date + 4 - current day number (add 6 if Sunday)
        currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));

        // Get first day of year
        const yearStart = new Date(currentDate.getFullYear(), 0, 1);

        // Calculate full weeks to nearest Thursday
        const weekNumber = Math.ceil((((currentDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

        return weekNumber;
    }

    

    return (
        <InfoComponent>
            <div>
                <p>Período {nweeks} semanas inicia-se na data {dateIni} (semana {getWeekNumber(new Date(dateIni))}) e termina na data {dateFim} (semana {getWeekNumber(new Date(dateFim))}) </p>
                <p>Semana é o número de ordem considerando que um ano tem 52/53 semanas</p>
            </div>
        </InfoComponent>
    );
};

export default NWeeksHelp;
