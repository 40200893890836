import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";

import { useState, useEffect } from "react";
import { IonAlert, IonContent, IonModal, IonText } from "@ionic/react";
import { Link } from "react-router-dom";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import "../../general.styles.scss";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import RecipeForm from "./recipe-create";

import AssignProd from "./assign-prod";
import InfoComponent from "../../reusable/info/info.component";
import { BiFoodMenu } from "react-icons/bi";

import Loading from "../../reusable/loading/loading";
import Paginate from "../../reusable/pagination/paginate";
import Toaster from "../../reusable/Toaster";

const MenuList = () => {
  const {get, post} = useApi();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [modalEdit, setModalVisibleEdit] = useState(false);
  const [modalAddProd, setModalAddProd] = useState(false);
  const [editable, setEditable] = useState(0);
  const [changedList, setChangedList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isOpenDelete, setIsOpenDelete] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await get(
          `recipe_list?page=${currentPage}`
        );
        setData(response.data);
        setTotalPages(response.data["results"].total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [currentPage, changedList]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const deleteRecipe = async (recipe_id: string) => {
    try {
      const response = await post(
        `/recipe_delete/`,
        JSON.stringify({ recipe_id: recipe_id })
      );

      if (response.status === 200) {
        Toaster.show("Receita apagada com sucesso.", "success");
        setLoading(true);
        setChangedList(!changedList);
      } else {
        Toaster.show("Erro ao apagar receita.", "error");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      Toaster.show("Erro ao apagar receita. Tente novamente mais tarde.", "error");
    }
  };
  const darkMode = useDarkMode();
  return (
    <IonContent>
      {data && (
        <div
          style={{ paddingBottom: "20px" }}
          className="w-[80%] m-auto text-black"
        >
          <div className="flex items-center justify-between mt-10">
            <IonText
              style={{ color: darkMode ? "white" : "black" }}
              className="text-4xl font-semibold text-blue-dark"
            >
              Receitas
            </IonText>
            <div>
              <button
                onClick={() => setModalVisibleCreate(true)}
                style={{ background: darkMode ? "#422655" : "" }}
                className="text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
              >
                + Nova Receita
              </button>
            </div>
          </div>

          <InfoComponent text="">
            <p>
              Lista de receitas do restaurante com classe correspondente. A cada
              receita deve ser associado um produto da carta, deste modo a
              aplicação monitoriza o desempanho de vendas da receita.
            </p>
          </InfoComponent>

          {data && data["results"]["recipes"].length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded select-text">
              <thead className="bg-blue-dark rounded text-white">
                <tr>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Nome
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Classe
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Produto da carta
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Estado
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody
                style={{ backgroundColor: darkMode ? "#262853" : "" }}
                className="bg-white divide-y divide-gray-200"
              >
                {data &&
                  data["results"]["recipes"].map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {item.recipe_name}
                      </td>
                      <td
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {item.recipe_class}
                      </td>
                      <td
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {!data["results"]["prods"][index]?.rest_prod_desc ? <button
                          onClick={() => {
                            setModalAddProd(true);
                            setEditable(item.recipe_id);
                          }}
                          className="hover:scale-105 transition-transform duration-300"
                        >
                          {
                            "Adicionar produto..."}
                        </button> : data["results"]["prods"][index]?.rest_prod_desc}
                      </td>
                      <td
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {item.recipe_status}
                      </td>
                      <td
                        style={{ color: darkMode ? "white" : "black" }}
                        className="px-6 py-2 whitespace-no-wrap text-gray-600"
                      >
                        {formatDate(item.ztimestamp)}
                      </td>
                      <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        <div className="flex space-x-4">
                          <Link
                            className="hover:scale-125 transition-transform duration-300"
                            to={`/app/menu/recipe/tec?id=${item.recipe_id}`}
                          >
                            <BiFoodMenu
                              title="Ver Ficha Tecnica"
                              style={{ color: darkMode ? "white" : "black" }}
                              className="text-blue-dark text-2xl" />
                          </Link>
                          <Link
                            className="hover:scale-125 transition-transform duration-300"
                            to={`/app/menu/recipe?id=${item.recipe_id}`}
                          >
                            <IoIosLink
                              title="Ver Receita"
                              style={{ color: darkMode ? "white" : "black" }}
                              className="text-blue-dark text-2xl"
                            />
                          </Link>
                          <button className="hover:scale-125 transition-transform duration-300">
                            <FaPencilAlt
                              title="Editar"
                              onClick={() => {
                                setModalVisibleEdit(true);
                                setEditable(item.recipe_id);
                              }}
                              className="text-blue-500 text-2xl"
                            />
                          </button>
                          <button
                            onClick={() => setIsOpenDelete(item.recipe_id)}
                            className="hover:scale-125 transition-transform duration-30"
                          >
                            <FaTrash
                              title="Eliminar"
                              className="text-red-500 text-2xl"
                            />
                          </button>
                          <IonAlert
                            header="Atenção"
                            isOpen={
                              isOpenDelete !== -1 &&
                              isOpenDelete === item.recipe_id
                            }
                            subHeader="Eliminar item."
                            message={`Estará a eliminar o item ${item.recipe_name}. Deseja continuar?`}
                            buttons={[
                              {
                                text: "Cancelar",
                                role: "cancelar",
                                handler: () => {
                                },
                              },
                              {
                                text: "OK",
                                role: "confirmar",
                                handler: () => {
                                  deleteRecipe(item.recipe_id);
                                },
                              },
                            ]}
                            onDidDismiss={() => setIsOpenDelete(-1)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div
              style={{ color: darkMode ? "white" : "black" }}
              className="text-center text-2xl text-blue-dark"
            >
              Não existem Receitas.
            </div>
          )}
        </div>
      )}

      {totalPages >= 2 && <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "50px",
        }}
      >
        <Paginate
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          loading={!data}
        />
      </div>}

      <IonModal
        isOpen={modalCreate}
        onDidDismiss={() => setModalVisibleCreate(false)}
      >
        <div style={{ overflow: "auto" }}>
          <RecipeForm
            modalCloser={setModalVisibleCreate}
            change={changedList}
            setChange={setChangedList}
          />
        </div>
      </IonModal>

      <IonModal
        isOpen={modalEdit}
        onDidDismiss={() => setModalVisibleEdit(false)}
      >
        <div style={{ overflow: "auto" }}>
          <RecipeForm
            recipe_id={editable}
            modalCloser={setModalVisibleEdit}
            change={changedList}
            setChange={setChangedList}
          />
        </div>
      </IonModal>

      <IonModal
        isOpen={modalAddProd}
        onDidDismiss={() => setModalAddProd(false)}
      >
        <div style={{ overflow: "auto" }}>
          <AssignProd
            recipe_id={editable}
            modalCloser={setModalAddProd}
            change={changedList}
            setChange={setChangedList}
          />
        </div>
      </IonModal>
      <Loading isOpen={loading}></Loading>
    </IonContent>
  );
};

export default MenuList;
