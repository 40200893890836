// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-content {
  --background: #fff url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center / cover no-repeat;
}

.sign-up-container {
  background-color: white;
  padding: 1rem;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 24px -10px rgba(0, 0, 0, 0.75);
}
.sign-up-container .sign-up-title {
  font-size: 1.2rem;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  color: #917FB3;
  margin: 0 !important;
  height: -moz-fit-content;
  height: fit-content;
}
.sign-up-container .sign-in-label {
  padding-bottom: 1rem;
}
.sign-up-container .btn-container {
  padding-top: 1.5rem;
}

.sign-up-title {
  text-align: center;
  margin-right: 5rem;
}

@media (min-width: 300px) {
  .sign-up-container {
    width: 95%;
    height: auto;
  }
  .sign-up-container .sign-up-title.ios {
    padding: 1rem;
  }
  .sign-up.container.ios {
    height: -moz-fit-content;
    height: fit-content;
  }
  .sign-up-list.ios {
    margin-top: 2.5rem;
  }
}
@media (min-width: 800px) {
  .sign-up-container {
    width: 50vh;
    height: 60vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/signup-styles.scss"],"names":[],"mappings":"AAAA;EACE,mFAAA;AACF;;AAEA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,kDAAA;AACF;AACE;EACE,iBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAAA;EACA,oBAAA;EACA,wBAAA;EAAA,mBAAA;AACJ;AAEE;EACE,oBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,kBAAA;AAFF;;AAKA;EACE;IACE,UAAA;IACA,YAAA;EAFF;EAIE;IACE,aAAA;EAFJ;EAMA;IACE,wBAAA;IAAA,mBAAA;EAJF;EAOA;IACE,kBAAA;EALF;AACF;AAQA;EACE;IACE,WAAA;IACA,YAAA;EANF;AACF","sourcesContent":[".sign-up-content {\n  --background: #fff url(\"../../assets/images/background.webp\") center / cover no-repeat;\n}\n\n.sign-up-container {\n  background-color: #ffff;\n  padding: 1rem;\n  border-radius: 20px;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  box-shadow: 0px 0px 24px -10px rgba(0, 0, 0, 0.75);\n\n  .sign-up-title {\n    font-size: 1.2rem;\n    padding-top: 0.5rem;\n    padding-bottom: 1.5rem;\n    text-align: center;\n    color: #917FB3;\n    margin: 0 !important;\n    height: fit-content;\n  }\n\n  .sign-in-label {\n    padding-bottom: 1rem;\n  }\n\n  .btn-container {\n    padding-top: 1.5rem;\n  }\n}\n\n.sign-up-title {\n  text-align: center;\n  margin-right: 5rem;\n}\n\n@media (min-width: 300px) {\n  .sign-up-container {\n    width: 95%;\n    height: auto;\n\n    .sign-up-title.ios{\n      padding: 1rem;\n    }\n  }\n\n  .sign-up.container.ios{\n    height: fit-content;\n  }\n\n  .sign-up-list.ios{\n    margin-top: 2.5rem;\n  }\n}\n\n@media (min-width: 800px) {\n  .sign-up-container {\n    width: 50vh;\n    height: 60vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
