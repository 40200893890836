import { useEffect, useState } from "react";
import { IonContent } from "@ionic/react";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { IoRestaurant } from "react-icons/io5";
import Page from "../page";
import { axiosPrivate } from "../../../utils/api/axios";
import { useParams } from "react-router";

import Toaster from "../../../components/App/reusable/Toaster";

type Ticket = {
  ticket_id: number;
  ticket_title: string;
  ticket_type: string;
  ticket_priority: string;
  rest_site: number;
  ticket_user: string;
  ticket_status: string;
  zdate: string;
};

type Message = {
  texto: string;
  sender: string;
  zdate: string;
};

const status = [
  { label: "Aberto", value: "OPEN" },
  { label: "Em progresso", value: "PROGRESS" },
  { label: "Fechado", value: "CLOSED" },
  // { label: "Test", value: "TEST" },
];

const TicketDetail = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "RECIPES PAGE",
  });

  const { id } = useParams<{ id: string }>();
  const [openAnswer, setOpenAnswer] = useState<boolean>(false);
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState<string>("");
  const [newStatus, setNewStatus] = useState<string>("");
  const [updateStatus, setUpdateStatus] = useState<boolean>(false);

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await axiosPrivate.get(`/tickets/${id}`);
        setTicket(response.data.ticket);
        setMessages(response.data.messages);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTicket();
  }, []);

  const sendMessage = async () => {
    try {
      const response = await axiosPrivate.post(`/tickets/add_message`, {
        ticket_id: id,
        message: message,
      });
      if (response) {
        setMessages([...messages, response.data]);
        Toaster.show("Mensagem enviada com sucesso!", "success");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      Toaster.show("Erro ao enviar mensagem!", "error");
    } finally {
      setOpenAnswer(false);
      setMessage("");
    }
  };

  const changeStatus = async () => {
    try {
      const response = await axiosPrivate.post(
        `/tickets/change_ticket_status`,
        {
          ticket_id: id,
          status: newStatus,
        }
      );

      if (response) {
        Toaster.show("Status alterado com sucesso!", "success");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      Toaster.show("Erro ao alterar o status!", "error");
    }
  };

  return (
    <Page title={"Ticket Detail"} icon={<IoRestaurant size={32} />}>
      <IonContent>
        {ticket && (
          <div className="mx-auto px-4 w-[70%]">
            <div className="bg-white rounded-lg shadow-lg p-6 mt-6">
              <h2 className="text-2xl font-bold mb-2">{ticket.ticket_title}</h2>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <span className="font-bold">Type:</span> {ticket.ticket_type}
                </div>
                <div>
                  <span className="font-bold">Priority:</span>{" "}
                  {ticket.ticket_priority}
                </div>
                <div>
                  <span className="font-bold">Restaurant:</span>{" "}
                  {ticket.rest_site}
                </div>
                {updateStatus ? (
                  <div className="w-1/2 pl-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Status
                    </label>
                    <select
                      className="w-full bg-gray-200 border rounded py-2 px-3"
                      onChange={(e) => {
                        setNewStatus(e.target.value);
                      }}
                    >
                      {status.map((priority, index) => (
                        <option key={index} value={priority.value}>
                          {priority.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div>
                    <span className="font-bold">Status:</span>{" "}
                    {ticket.ticket_status}
                  </div>
                )}
                <div>
                  <span className="font-bold">User:</span> {ticket.ticket_user}
                </div>
                <div>
                  <span className="font-bold">Date:</span> {ticket.zdate}
                </div>
              </div>
              <button
                onClick={() => {
                  if (!updateStatus) {
                    setUpdateStatus(true);
                  } else {
                    changeStatus();
                    setUpdateStatus(false);
                  }
                }}
                type="submit"
                className="bg-blue-dark w-full p-5 hover:bg-blue-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
              >
                {updateStatus ? "Guardar" : "Aletrar Status"}
              </button>
            </div>
            <div className="bg-white rounded-lg shadow-lg px-6 py-2 mt-6">
              <h3 className="text-xl font-bold mb-2">Messages</h3>
              {messages &&
                messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-4 space-y-2 ${
                      message.sender === "USER" ? "mr-auto" : "ml-auto"
                    } w-4/5`}
                  >
                    <div className="border rounded-lg px-4">
                      <h4 className="text-sm text-gray-600 mb-2">
                        {message.sender}
                      </h4>
                      <p>{message.texto}</p>
                      <p className="text-right text-gray-500 text-sm my-2">
                        {message.zdate}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            {openAnswer && (
              <div>
                <textarea
                  className="w-full mt-5 bg-gray-200 border rounded py-2 px-3"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            )}
            <button
              onClick={() => {
                if (!openAnswer) {
                  setOpenAnswer(true);
                } else {
                  sendMessage();
                }
              }}
              type="submit"
              className="bg-blue-dark w-full p-5 hover:bg-blue-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
            >
              {openAnswer ? "Submeter" : "Continuar Conversa"}
            </button>
          </div>
        )}
      </IonContent>
    </Page>
  );
};

export default TicketDetail;
