import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import { useLocation } from "react-router";
import "../general.styles.scss";
import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import Loading from "../reusable/loading/loading";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import Toaster from "../reusable/Toaster";

const Plannings = () => {
  const { get } = useApi();
  const [plans, setPlans] = useState({
    q2: null,
    q3: null,
    q4: null,
  });
  const [plan_config, setPlanConfig] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const darkMode = useDarkMode();

  const idPlan = location.pathname.match(/\/app\/planning\/custom\/(\d+)/)?.[1] || 0;


  useEffect(() => {
    const fetchPlans = async () => {
      const controller = new AbortController();
      try {
        const response = await get(`get_plan/?an_id=${idPlan}`, {
          signal: controller.signal,
        });
        setPlanConfig(response.data.recipe_doses);
        const data = {
          q2: response.data.q2,
          q3: response.data.q3,
          q4: response.data.q4,
        };

        setPlans(data);
      } catch (error) {
        console.error(error);
        Toaster.show("Erro ao carregar análises de consumidor", "error");
      } finally {
        setLoading(false);
      }
      return () => controller.abort();
    };

    fetchPlans();
  }, [idPlan]);

  const renderTableHeaderConfig = () => (
    <thead className="bg-blue-dark text-white rounded">
      <tr>
        {["Nome da Receita", "Número de doses"].map((header) => (
          <th
            key={header}
            style={{ background: darkMode ? "#422655" : "" }}
            className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderTableBodyConfig = () => (
    <tbody
      style={{ backgroundColor: darkMode ? "#262853" : "" }}
      className="bg-white divide-y divide-gray-200"
    >
      {plan_config.map((item, index) => (
        <tr key={index}>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item[2]}
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item[1]}
          </td>
        </tr>
      ))}
    </tbody>
  );

  const renderTableHeaderQ2 = () => (
    <thead className="bg-blue-dark text-white rounded">
      <tr>
        {["Nome", "Quantidade(kg)", "Preço(kg)", "Custo Estimado"].map((header) => (
          <th
            key={header}
            style={{ background: darkMode ? "#422655" : "" }}
            className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderTableBodyQ2 = () => (
    <tbody
      style={{ backgroundColor: darkMode ? "#262853" : "" }}
      className="bg-white divide-y divide-gray-200"
    >
      {plans.q2.map((item, index) => (
        <tr key={index}>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.ingr_name}
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.qt_ingr_kg}
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.price_avg}€
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.custo_estimado}€
          </td>
        </tr>
      ))}
    </tbody>
  );

  const renderTableHeaderQ3 = () => (
    <thead className="bg-blue-dark text-white rounded">
      <tr>
        {["Nome", "Quantidade(kg)", "Fornecedor", "Preço(kg)", "Data do Preço", "Custo Estimado"].map((header) => (
          <th
            key={header}
            style={{ background: darkMode ? "#422655" : "" }}
            className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderTableBodyQ3 = () => (
    <tbody
      style={{ backgroundColor: darkMode ? "#262853" : "" }}
      className="bg-white divide-y divide-gray-200"
    >
      {plans.q3.map((item, index) => (
        <tr key={index}>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.ingr_name}
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.qt_ingr_kg}
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.sku_prov_name}
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.price}€
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {item.price_date}
          </td>
          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
            {parseFloat(item.custo_estimado.toFixed(2))}€
          </td>
        </tr>
      ))}
    </tbody>
  );

  const renderTableHeaderQ4 = () => (
    <thead className="bg-blue-dark text-white rounded">
      <tr>
        {["Nome", "Fornecedor", "Preço", "Custo Estimado", "Rank"].map((header) => (
          <th
            key={header}
            style={{ background: darkMode ? "#422655" : "" }}
            className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderTableBodyQ4 = () => (
    <tbody
      style={{ backgroundColor: darkMode ? "#262853" : "" }}
      className="bg-white divide-y divide-gray-200"
    >
      {plans.q4.map((item, index) => {
        const getBackgroundColor = (rank) => {
          switch (rank) {
            case 1:
              return "rgba(144, 238, 144, 0.5)";
            case 2:
              return "rgba(173, 255, 47, 0.5)";
            case 3:
              return "rgba(255, 238, 140, 0.5)";
            default:
              return "";
          }
        };

        return (
          <tr key={index} style={{ backgroundColor: getBackgroundColor(item.rank) }}>
            <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.sku_prod_name}
            </td>
            <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.sku_prov_name}
            </td>
            <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.price}€
            </td>
            <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {parseFloat(item.custo_estimado.toFixed(2))}€
            </td>
            <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.rank}
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  const renderContent = () => {
    if (loading) return <Loading isOpen={loading} />;
    if (!plans) {
      return (
        <div
          style={{ color: darkMode ? "white" : "black" }}
          className="text-center text-2xl mt-10"
        >
          Sem planeamentos de compras. Crie um novo planeamento.
        </div>
      );
    }
    return (
      <div>
        <div>
          <h1
            style={{ color: darkMode ? "white" : "black" }}
            className="text-2xl mt-10"
          >
            Planeamento à medida para a seguinte configuração
          </h1>
          <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
            {renderTableHeaderConfig()}
            {renderTableBodyConfig()}
          </table>
        </div>
        {plans.q2 && plans.q2.length > 0 && <div>
          <h1
            style={{ color: darkMode ? "white" : "black" }}
            className="text-2xl mt-10"
          >
            Quantidades usadas nas receitas - Valores médios de PVP e estimativa de custo
          </h1>
          <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
            {renderTableHeaderQ2()}
            {renderTableBodyQ2()}
          </table>
        </div>}
        {plans.q3 && plans.q3.length > 0 && <div>
          <h1
            style={{ color: darkMode ? "white" : "black" }}
            className="text-2xl mt-10"
          >
            Lista de compras de ingredientes segundo o critério - SKU de Referência
          </h1>
          <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
            {renderTableHeaderQ3()}
            {renderTableBodyQ3()}
          </table>
        </div>}
        {plans.q4 && plans.q4.length > 0 && <div>
          <h1
            style={{ color: darkMode ? "white" : "black" }}
            className="text-2xl mt-10"
          >
            Lista de compras de ingredientes segundo o critério - Top 3 melhores preços
          </h1>
          <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
            {renderTableHeaderQ4()}
            {renderTableBodyQ4()}
          </table>
        </div>}
      </div>

    );
  };

  return (
    <DarkModeProvider>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow
            style={{
              paddingBottom: "30px",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <IonCol>{renderContent()}</IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </DarkModeProvider>
  );
};

export default Plannings;