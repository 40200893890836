import Page from "../../page";
import Reprocessar from "../../../../components/App/menu/ingredients/reprocessar_ingreds";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../../components/App/reusable/loading/loading";
import { IoRestaurant } from "react-icons/io5";

const Reprocess = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU CREATE REPROCESS INGREDS PAGE",
  });

  return (
    <Page title={"Reprocessar Ingredientes"} icon={<IoRestaurant size={32} />}>
      {tokenVerified ? <Reprocessar /> : <Loading isOpen={true} />}
    </Page>
  );
};

export default Reprocess;
