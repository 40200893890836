import Page from "../page";
import ProfileRestaurant from "../../../components/App/user-setup/profile-restaurant";
import { useState } from "react";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import Loading from "../../../components/App/reusable/loading/loading";
import { IoPerson } from "react-icons/io5";


const ProfileRestaurantPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'PROFILE RESTAURANT PAGE',
  });
  return (
    <Page title={"Profile"} icon={<IoPerson size={32} />}>
      {tokenVerified ? (<ProfileRestaurant />) : <Loading isOpen={true} />}
    </Page>
  );
};

export default ProfileRestaurantPage;


