import React, { useState, useMemo } from 'react';
import { IonContent, IonGrid, IonCol, IonRow } from "@ionic/react";
import { MdSearch } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

import Page from "../page";
import Loading from "../../../components/App/reusable/loading/loading";
import MyTable from "../../../components/App/reusable/table/table-component";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import useAuth from "../../../utils/hooks/useAuth";
import "../general.styles.scss";

const QueriesPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const { auth } = useAuth();
  const darkMode = useDarkMode();

  useTokenVerifier({
    onTokenVerified: () => setTokenVerified(true),
    log: "QUERIES PAGE",
  });

  const columns = useMemo(() => [
    { headerName: "Identificador", field: "id" },
    { headerName: "Consulta", field: "consulta" },
    { headerName: "Descrição", field: "descricao" },
    { headerName: "Consultar", field: "consultar" },
  ], []);

  const baseRows = useMemo(() => [
    {
      id: "1",
      consulta: "Consulta dos dados próprios do Cliente",
      descricao: "Consultar os dados do cliente",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "2",
      consulta: "Consulta de Receitas",
      descricao: "Consultar as receitas do restaurante",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "3",
      consulta: "Consulta ultimos valores de custo de Receitas",
      descricao: "Consultar os valores do custo das receitas",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "4",
      consulta: "Consulta ultimos valores de custo de Ingredientes",
      descricao: "Consultar o valor do custo dos ingredientes",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "5",
      consulta: "Consulta ultimos valores de custo de SKUs Activos",
      descricao: "Consultar o valor do custo dos SKUs ativos",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "6",
      consulta: "Consulta ultimos valores de custo de SKUs Não Activos",
      descricao: "Consultar o valor do custo dos SKUs não ativos",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "17",
      consulta: "Consulta preços de SKUs",
      descricao: "Consultar o preço dos SKUs num determinado período de tempo",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "18",
      consulta: "Consulta preços de ingredientes",
      descricao: "Consultar o preço dos ingredientes num determinado período de tempo",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "19",
      consulta: "Consulta preços de receitas",
      descricao: "Consultar o preço dos receitas num determinado período de tempo",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "20",
      consulta: "Consulta Estatísticas Semanais SKUs",
      descricao: "Consultar as estatísticas semanais SKUs num determinado período de tempo",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "21",
      consulta: "Consulta Estatísticas Semanais Ingredientes e Receitas",
      descricao: "Consultar as estatísticas semanais de ingredientes e receitas num determinado período de tempo",
      consultar: <FaSearch title="Consultar" />,
    },
  ], []);

  const backofficeRows = useMemo(() => [
    {
      id: "7",
      consulta: "Consulta de logs dos Scripts",
      descricao: "Consultar os logs dos scripts",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "8",
      consulta: "Consulta de logs dos Utilizadores",
      descricao: "Consultar os logs dos utilizadores",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "9",
      consulta: "Consulta da Recolha Diária de dados recolhidos pelos scrapers por fornecedores",
      descricao: "Consultar os dados de Recolha Diária recolhidos pelos scrapers por fornecedores",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "10",
      consulta: "Consulta do processo de Recolha Diária de Preços SKU por fornecedores",
      descricao: "Consultar os dados do processo de Recolha Diária de Preços SKU por fornecedores",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "11",
      consulta: "Consulta de todos os SKUs e SKUs classificados via GPT",
      descricao: "Consultar todos os SKUs",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "12",
      consulta: "Consulta de SKUs novos e SKUs classificados via GPT",
      descricao: "Consultar os SKUs novos e SKUs classificados via GPT",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "13",
      consulta: "Consulta de Preço de Ingredientes - Valores Diários",
      descricao: "Consultar os Preço de Ingredientes - Valores Diários",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "14",
      consulta: "Consulta de Preço de Ingredientes - Valores Semanais",
      descricao: "Consultar os Preço de Ingredientes - Valores Semanais",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "15",
      consulta: "Consulta de Preços de Receitas diários",
      descricao: "Consultar os Preços de Receitas diários",
      consultar: <FaSearch title="Consultar" />,
    },
    {
      id: "16",
      consulta: "Consulta de Preços de Receitas - Valores Semanais",
      descricao: "Consultar os Preços de Receitas - Valores Semanais",
      consultar: <FaSearch title="Consultar" />,
    }
  ], []);

  const rows = useMemo(() => {
    let allRows = [...baseRows];
    if (auth.roles.includes("backoffice")) {
      allRows = [...allRows, ...backofficeRows];
    }
    return allRows.sort((a, b) => Number(a.id) - Number(b.id));
  }, [auth.roles, baseRows, backofficeRows]);

  if (!tokenVerified) {
    return <Loading isOpen={true} />;
  }

  return (
    <Page title="Consultas" icon={<MdSearch size={32} />}>
      <IonContent>
        <IonGrid>
          <IonRow style={{ paddingBottom: "100px" }} className="padding">
            <IonCol size="12">
              <MyTable
                rowData={rows}
                columnDefs={columns}
                link="/app/consultas/consulta?id="
                linkIndex={3}
                trueLinkIndex={0}
                title="Consultas Disponiveis"
                darkMode={darkMode}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </Page>
  );
};

export default QueriesPage;