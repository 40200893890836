class MonthsFormatter {
    static provs = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    static default = 'Jan';
  
    static format(input: string): string {
      return input;
    }
  
    static getAll() {
      return this.provs;
    }
  }
  export default MonthsFormatter;