
import Sales from "../../../components/App/sales/sales-analysis-longTerm";
import Page from "../page";
import { useState } from "react";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import Loading from "../../../components/App/reusable/loading/loading";
import { MdReceipt } from "react-icons/md";

const SalesPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'SALES PAGE - LONGTERM',
  });
  return (
      <Page title={"Análise de Vendas - Longo Prazo"} icon={<MdReceipt size={32}/>}>
      {tokenVerified ? (<Sales />) : <Loading isOpen={true} />}
      </Page>
  );
};

export default SalesPage;
