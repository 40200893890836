

  export let darkMode : boolean = false;
  export const toggleDarkModeHandler = () => {
      
    document.body.classList.toggle("dark");
    handleToggler();
    
  }

  const handleToggler = () => {
    if(darkMode){
      darkMode = false;

    } else {
      darkMode = true;

    }
  }

  