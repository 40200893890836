import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import { IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import toast from 'react-hot-toast';
import InfoComponent from "../../reusable/info/info.component";
import Toaster from "../../reusable/Toaster";


const AssignProd = ({ recipe_id = 0, modalCloser, setChange, change }) => { // loadingSetter

    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedProd, setselectedProd] = useState(undefined);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();
        const getGraph = async (endpoint, params = {}) => {
            const url = params
                ? `${endpoint}/?${Object.entries(params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join("&")}`
                : `${endpoint}`;
            try {
                const response = await axiosPrivate.get(url, {
                    signal: controller.signal
                });
                setData(response.data);
                // loadingSetter(false);
            } catch (error) {
                Toaster.show('Erro ao listar produtos.', "error")
            }
        };
        if (search !== "") {
            getGraph("rest_prods_list", { search: search });
        }

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [search]);

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            // loadingSetter(true);
            setSearch(e.target.value);
        }
    };

    const handleCheckboxChange = (prod_id) => {
        setselectedProd(prod_id);
    };

    const handleAssign = async () => {

        // loadingSetter(true);
        if (!selectedProd) {
            Toaster.show("Selecione um produto", "error");
            // loadingSetter(false);
            return;
        }

        const prodToAssign = {
            rest_prod: selectedProd,
            recipe: recipe_id,
            ass_state: "ACTIVE"
        };

        try {
            const response = await axiosPrivate.post(`/prod_asign_create_or_update/`,
                JSON.stringify(prodToAssign),
            );

            if (response.status === 200 || response.status === 201) {
                Toaster.show('Produto associado com sucesso.', "success")

            } else {
                Toaster.show('Erro ao associar produto.',  "error")
                // loadingSetter(false);
            }
        } catch (error) {
            console.error("Error:", error);
            Toaster.show('Erro ao associar produto.', "error")
            // loadingSetter(false);
        }
        setChange(!change);
    };

    const darkMode = useDarkMode();

    return (

        <><IonGrid>

            <IonRow>
                <div style={{ width: "100%" }} className={`search ${darkMode ? 'dark' : 'light'}`}>
                    <IonSearchbar
                        value={search}
                        onKeyDown={handleKeyUp}
                        placeholder="Pesquisar produtos"
                        style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }} />
                </div>
            </IonRow>

            <InfoComponent text="">
                <p>Associe um produto da sua carta à receita. Isto permite à aplicação estudar a performance da suas receitas.</p>
            </InfoComponent>
            
            <IonRow>
                {data.length > 0 && (
                    <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                        <thead className='bg-blue-dark text-white rounded'>
                            <tr>
                                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Nome</th>
                                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Select</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: darkMode ? '#262853' : '' }} className="bg-white divide-y divide-gray-200">
                            {data.map((prod) => (
                                <tr key={prod.rest_prod_id}>
                                    <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                                        {prod.rest_prod_desc}
                                    </td>
                                    <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                                        <input
                                            type="checkbox"
                                            checked={selectedProd === prod.rest_prod_id}
                                            onChange={() => handleCheckboxChange(prod.rest_prod_id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </IonRow>

            <IonRow>
                <button
                    type="submit"
                    onClick={() => { modalCloser(false); handleAssign(); }}
                    className="bg-blue-dark w-full p-5 hover:bg-blue-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
                >
                    Associar produto da carta
                </button>
            </IonRow>
        </IonGrid></>

    );
}

export default AssignProd;