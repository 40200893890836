// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.goBackIcon {
  position: absolute;
  top: 10%;
  left: 10%;
  cursor: pointer;
  font-size: 48px;
}`, "",{"version":3,"sources":["webpack://./src/components/App/reusable/goBack/back.styles.scss"],"names":[],"mappings":"AAIA;EACC,kBAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EACA,eAAA;AAHD","sourcesContent":["\n\n\n\n.goBackIcon{\n\tposition: absolute;\n\ttop: 10%;\n\tleft: 10%;\n\tcursor: pointer;\n\tfont-size: 48px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
