
import FoodCostRecipesComponent from "../../../components/App/foodcost/foodcostRecipes";
import Page from "../page";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../components/App/reusable/loading/loading";
import { IoPricetag } from "react-icons/io5";
const FoodCostRecipes = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'FOODCOST RECIPES PAGE',
  });

  return (
      <Page title={"Custo das Receitas e Ingredientes"} icon={<IoPricetag size={32}/>}>
        {tokenVerified ? (<FoodCostRecipesComponent />) : <Loading isOpen={true} />}
      </Page>
  );
};
export default FoodCostRecipes;
