import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import { IonCol, IonGrid, IonIcon, IonLoading, IonRow, IonSearchbar } from "@ionic/react";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import toast from 'react-hot-toast';
import InfoComponent from "../../reusable/info/info.component";
import Loading from "../../reusable/loading/loading";
import Paginate from "../../reusable/pagination/paginate";
import { IoSearchCircleOutline } from "react-icons/io5";
import SelectComponent from "../../reusable/select/select";
import CategoryFormatter from "../../reusable/select/categories";
import RangeFormatter from "../../reusable/select/range";
import ProviderFormatter from "../../reusable/select/provider";
import Toaster from "../../reusable/Toaster";



const SkuCreate = ({ ingrName, modalCloser, setChange, change }) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ingrId = queryParams.get('id');
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(ingrName);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [category, setCategory] = useState("Bebidas");
  const [priceRange, setPriceRange] = useState("<= 1€");
  const [prov, setProv] = useState("Pingo Doce");
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSkuList = async (search: string) => {
      setLoading(true);
      const categoryRange = RangeFormatter.rangeToCategory(priceRange)
      const url = `sku_list_create?search=${search}&category=${category}&price_range=${categoryRange}&provider=${prov}&page=${currentPage}`;
      try {
        const response = await axiosPrivate.get(url);
        setData(response.data.data);
        setTotalPages(response.data.total_pages);

        if (!response.data.message) {
          toast('A sua pesquisa não retornou resultados. Sugerimos as abaixo.', {
            icon: <IoSearchCircleOutline style={{ color: darkMode ? "white" : "black" }} size={32} />
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

      }
    };

    getSkuList(search);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [search, category, currentPage, priceRange, prov]);

  const handleKeyUp = (e) => {

    if (e.key === 'Enter') {
      setLoading(true);
      if (e.target.value === "") {
        setLoading(false);
        return;
      }
      setSearch(e.target.value);
    }
  };

  const handleCheckboxChange = (sku_id) => {
    setSelectedSkus((prevSelectedSkus) => {
      if (prevSelectedSkus.find((item) => item.id === sku_id)) {
        return prevSelectedSkus.filter((item) => item.id !== sku_id);
      } else {
        return [...prevSelectedSkus, { id: sku_id, quantity: 0 }];
      }
    });
  };

  const handleAssign = async () => {
    setLoading(true);
    if (selectedSkus.length === 0) {
      Toaster.show("Selecione pelo menos um sku", "error");
      setLoading(false);
      return;
    }
    for (const sku of selectedSkus) {
      const skuToAssign = {
        assign_sku_id: 0,
        sku: sku.id,
        ingr: ingrId,
        ass_state: "ACTIVE"
      };
      try {
        const response = await axiosPrivate.post(`/sku_assign_create_or_update/`,
          JSON.stringify(skuToAssign),
        );

        if (response.status === 200 || response.status === 201) {
          Toaster.show('SKU associado com sucesso.', "success")

        } else {
          Toaster.show(`Erro ao associar SKU`, "error")
          setLoading(false);
          break;
        }
      } catch (error) {
        console.error("Error:", error);
        Toaster.show('Erro ao associar SKU.', "error")
        setLoading(false);
      }
    }
    setChange(!change);
  };

  const darkMode = useDarkMode();



  return (

    <IonGrid>
      <IonRow>
        <div style={{ width: "100%" }} className={`search ${darkMode ? 'dark' : 'light'}`}>
          <IonSearchbar
            value={search}
            onKeyDown={handleKeyUp}
            placeholder="Pesquisar por SKU"
            style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }} />
        </div>
        <IonCol
          style={{ justifyContent: "center", display: "flex" }}
        >
          <SelectComponent
            formatter={CategoryFormatter}
            darkMode={darkMode}
            onChange={setCategory}
          />
        </IonCol>
        <IonCol
          style={{ justifyContent: "center", display: "flex" }}
        >
          <SelectComponent
            formatter={RangeFormatter}
            darkMode={darkMode}
            onChange={setPriceRange}
          />
        </IonCol>
        <IonCol
          style={{ justifyContent: "center", display: "flex" }}
        >
          <SelectComponent
            formatter={ProviderFormatter}
            darkMode={darkMode}
            onChange={setProv}
          />
        </IonCol>
        <IonCol size="12"
          style={{ justifyContent: "center", display: "flex" }}
          className="pt-8"
        >
          <button
            type="submit"
            onClick={() => { modalCloser(false); handleAssign(); }}
            className="bg-blue-dark p-5 hover:bg-blue-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
          >
            Guardar Associação
          </button>
        </IonCol>
      </IonRow>

      <InfoComponent text="">
        <p>Pesquise e associe produtos do mercado ao seu ingrediente.</p>
      </InfoComponent>

      {data && (
        <IonRow style={{ paddingTop: "20px", paddingBottom: "50px" }}>
          <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
            <thead className='bg-blue-dark text-white rounded'>
              <tr>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Nome</th>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Select</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: darkMode ? '#262853' : '' }} className="bg-white divide-y divide-gray-200">
              {data.map((sku) => (
                <tr key={sku.sku_id}>
                  <td
                    style={{ color: darkMode ? "white" : "black" }}
                    className="px-6 py-2 whitespace-no-wrap text-gray-600"
                  // onMouseEnter={() => handleSkuHover(sku.sku_packing)}
                  // onMouseLeave={() => handleSkuHover("")}
                  >
                    <span>{sku.sku_prod_name}</span>
                    <p></p>
                    <span>{sku.last_sku_price}€ à data {sku.last_date_sku_price}</span>
                    <p></p>
                    <span>Fornecedor: {sku.sku_prov_name}</span>

                  </td>
                  <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                    <input
                      type="checkbox"
                      checked={selectedSkus.find((item) => item.id === sku.sku_id) !== undefined}
                      onChange={() => handleCheckboxChange(sku.sku_id)}
                    />
                  </td>
                </tr>

              ))}
            </tbody>
          </table>
          {data.length >= 20 && <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "20px" }}>
            <Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} loading={!data} />
          </div>}

        </IonRow>

      )}
      <Loading isOpen={loading} />

    </IonGrid>

  );
}

export default SkuCreate;