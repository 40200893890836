import {
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonToggle
} from "@ionic/react";
import { darkMode } from "../Profile/Settings";
import { toggleDarkModeHandler } from "../Profile/Settings";
//import { Redirect, useHistory } from "react-router";
import { useEffect, useState } from "react";
//import useAuth from "../../utils/hooks/useAuth";
//import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
//import { toast } from 'react-hot-toast';
import ProfileMenu from "../../components/App/profileMenu/profileMenu";
import { CatProvider } from "../../utils/contexts/dataCat.context";
import { ClassProvider } from "../../utils/contexts/dataClass.context";
import { FaMoon } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";




const Page = ({ title, icon, children }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState<any>(null);

  const presentPopover = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    setPopoverEvent(event.nativeEvent);
    setShowPopover(true);
  };
  /*const history = useHistory();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();*/

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the width state whenever the window is resized
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Add event listener for window resize and remove it on component unmount
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*const LOGS_URL = "/create_log"
  const AUTH_URL = "/auth/jwt/verify"
 
 
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const verifyToken = async () => {
      console.log("verifyToken!")
      try {
        const response = await axiosPrivate.post(AUTH_URL, {
          token: auth?.accessToken,
        }, {
          signal: controller.signal,
        });
 
        if (response.status < 300) {
          onTokenVerified();
          const logData = {
            username: '',
            log_type: {log},
            log_oper_status: 'SUCCESS',
            log_oper_descr: 'teste',
            log_obs: 'dummyObs',
          };
          //console.log("Sending create log request!");
          const responseLogs = await axiosPrivate.post(LOGS_URL, logData, {
 
          });
          //console.log("Response status: ", response.status);
        }
      } catch (error) {
        console.error('Error refreshing token (Home): ', error);
        const logData = {
          username: 'Anonymous',
          log_type: {log},
          log_oper_status: 'TOKEN INVALID',
          log_oper_descr: 'teste',
          log_obs: 'dummyObs',
        };
        const responseLogs = await axiosPrivate.post(LOGS_URL, logData, {
        });
        Toaster.show('Ocorreu um erro e foi necessário reiniciar a página. Pedimos desculpa pelo incómodo.')
        history.goBack();
        window.location.reload();
      }
    }
 
    //if (auth && Object.values(auth).some(value => typeof value === 'string' && value.trim() !== '')) {
    verifyToken();
    //}
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [auth]);*/


  return (
    <IonPage >
      <CatProvider>
        <ClassProvider>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonButtons slot="end">
            <FaMoon className="mx-2" size={24}/>
              <IonToggle
                slot="end"
                color="medium"
                aria-label="Medium toggle"
                checked={darkMode}
                onIonChange={(toggleDarkModeHandler)}
              />
              <IonButton onClick={presentPopover}>
                <IoPerson size={24}/>
              </IonButton>
              <ProfileMenu setShowPopover={setShowPopover} popoverEvent={popoverEvent} showPopover={showPopover} />
            </IonButtons>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {width >= 550 ? <IonTitle>{title}</IonTitle> : <IonIcon style={{ fontSize: '32px' }} icon={icon} slot="start" />}
            </div>
          </IonToolbar>
        </IonHeader>
        {children}
        </ClassProvider>
      </CatProvider>
    </IonPage>
  );
};

export default Page;
