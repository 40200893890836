import {
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonModal,
  IonInput,
  IonTextarea,
  IonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "../general.styles.scss";
import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import MyTable from "../reusable/table/table-component";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

import Loading from "../reusable/loading/loading";
import { MdSearch } from "react-icons/md";
import Toaster from "../reusable/Toaster";

const Customers = () => {
  const axiosPrivate = useAxiosPrivate();
  const [customers, setcustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const customersUrl = "list_analysis";

  function addConsultarAttribute(arr) {
    for (let i = 0; i < arr.length; i++) {
      arr[i].consultar = <MdSearch size={32} />;
    }
    return arr;
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getGraph = async () => {
      try {
        const response = await axiosPrivate.get(customersUrl, {
          signal: controller.signal,
        });
        setcustomers(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        Toaster.show("Erro ao carregar análises de consumidor", "error");
      }
    };
    getGraph();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [change]);

  const [columns, setColumns] = useState([
    { headerName: "Descritivo Customer", field: "an_descr" },
    { headerName: "Estado", field: "an_obs" },
    { headerName: "Data de início", field: "an_dt_ini" },
    { headerName: "Data de fim", field: "an_dt_fim" },
    { headerName: "Análise", field: "consultar" },
  ]);
  const [newCustomerData, setNewCustomerData] = useState({
    name: "",
    description: "",
    end: "",
    start: "",
  });

  const [toastMessage, setToastMessage] = useState("");

  const handleCreateCustomer = async () => {
    setShowModal(false);
    setShowAlert(true);
    const newCustomer = {
      an_descr: newCustomerData.name,
      an_obs: "Em progresso...",
      an_dt_ini: "Em progresso...",
      an_dt_fim: "Em progresso...",
      price_ini: 0,
    };
    setcustomers((prevCustomer) => [...prevCustomer, newCustomer]);
    try {
      await axiosPrivate.post(
        "customer_analisys/?name=" +
          newCustomerData.name +
          "&description=" +
          newCustomerData.description +
          "&start=" +
          newCustomerData.start +
          "&end=" +
          newCustomerData.end,
        {
          body: JSON.stringify(newCustomerData),
        }
      );
    } catch (error) {
      console.error("Erro ao criar analise:", error);
      setToastMessage(
        "Erro ao criar analise: " + error.message || "Desconhecido"
      );
      setShowAlert(true);
    } finally {
      setLoading(false);
      setShowAlert(true);
      setChange(!change);
      setNewCustomerData({
        name: "",
        description: "",
        end: "",
        start: "",
      });
      setToastMessage("Analise customer criado com sucesso!");
    }
  };

  const darkMode = useDarkMode();

  return (
    <DarkModeProvider>
      <IonContent className="ion-padding">
        <IonRow className="paddingButton">
          <button
            onClick={() => setShowModal(true)}
            style={{ background: darkMode ? "#422655" : "" }}
            className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
          >
            + Criar análise
          </button>
        </IonRow>
        <IonGrid>
          <IonGrid>
            <IonRow
              style={{
                paddingBottom: "30px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <IonCol>
                {!loading && customers.length > 0 ? (
                  <MyTable
                    rowData={addConsultarAttribute(customers)}
                    columnDefs={columns}
                    link={`/app/customers/customer?id=`}
                    linkIndex={4}
                    trueLinkIndex={0}
                    title={"Análises de Consumidor"}
                    darkMode={darkMode}
                  />
                ) : (
                  <div
                    style={{ color: darkMode ? "white" : "black" }}
                    className="text-center text-2xl mt-10"
                  >
                    Sem análises de consumidor. Crie uma nova análise.
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonGrid>
        <Loading isOpen={loading} />
      </IonContent>
      {/* Modal */}
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <h2
          style={{
            color: darkMode ? "white" : "black",
            paddingBottom: "20px",
            textAlign: "center",
          }}
        >
          Criar análise de consumidor
        </h2>
        <IonCol style={{ padding: "50px", paddingTop: "0px" }}>
          <div className="pb-5">
            <label className="text-sm text-gray-700">Nome</label>
            <IonInput
              placeholder="Nome da Análise"
              style={{
                color: darkMode ? "white" : "black",
                backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
              }}
              value={newCustomerData.name}
              onIonChange={(e) =>
                setNewCustomerData({ ...newCustomerData, name: e.detail.value })
              }
            />
          </div>
          <div className="pb-5">
            <label className="text-sm text-gray-700">Descrição</label>
            <IonTextarea
              placeholder="Descrição da Análise"
              style={{
                color: darkMode ? "white" : "black",
                backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
              }}
              value={newCustomerData.description}
              onIonChange={(e) =>
                setNewCustomerData({
                  ...newCustomerData,
                  description: e.detail.value,
                })
              }
            />
          </div>
          <div className="flex justify-between gap-x-3">
            <div style={{ width: "100%" }}>
              <label className="text-sm text-gray-700">Data de início</label>
              <IonInput
                className="flex-grow"
                style={{
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                }}
                type="date"
                placeholder="Start Date"
                value={newCustomerData.start}
                onIonChange={(e) =>
                  setNewCustomerData({
                    ...newCustomerData,
                    start: e.detail.value.toString(),
                  })
                }
              ></IonInput>
            </div>
            <div style={{ width: "100%" }}>
              <label className="text-sm text-gray-700">Data de fim</label>
              <IonInput
                style={{
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                  width: "100%",
                }}
                type="date"
                pattern="yyyy-MM-dd"
                placeholder="End Date"
                value={newCustomerData.end}
                onIonChange={(e) =>
                  setNewCustomerData({
                    ...newCustomerData,
                    end: e.detail.value.toString(),
                  })
                }
              ></IonInput>
            </div>
          </div>
          <div className="flex justify-between gap-x-3 pt-5">
            <button
              onClick={handleCreateCustomer}
              disabled={
                newCustomerData.name === "" ||
                newCustomerData.description === ""
              }
              style={{ background: darkMode ? "#422655" : "" }}
              className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
            >
              Criar
            </button>
            <button
              onClick={() => setShowModal(false)}
              style={{ background: darkMode ? "#422655" : "" }}
              className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
            >
              Cancelar
            </button>
          </div>
        </IonCol>
      </IonModal>
      <IonToast
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        message={toastMessage}
        position="top"
        color="success"
        duration={3000}
      />
    </DarkModeProvider>
  );
};

export default Customers;
