
class ClasseFormatter {



    static Classes = [
     'Select a Classe', 'Classe X'];
 
    static default = 'Select a Classe';
 
    static format(input: string): string {
     const capitalized = input.charAt(0).toUpperCase() + input.slice(1);
 
     const replaced = capitalized.replace(/_/g, ' e ');
 
     return replaced;
   }
 
   static getAll() {
     return this.Classes;
   }
 
   static setAll(Classes){
     this.Classes = Classes;
   }
 
 }
 export default ClasseFormatter;