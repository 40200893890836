class RecipeStatus {
    static status = ['IMPORTED', 'IGNORED', 'INCOMPLETE', 'ACTIVE'];
    static default = 'INCOMPLETE';

    static format(input: string): string {
        return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
    }

    static getAll() {
        return this.status;
    }
}
export default RecipeStatus;
