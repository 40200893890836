
import Page from "../page";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../components/App/reusable/loading/loading";
import { IoCart } from "react-icons/io5";
import CreateEvent from "../../../components/App/events/create-event";


const EventsCreate = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'CREATE EVENTS',
  });
  return (
      <Page title={"Criar Eventos"} icon={<IoCart size={32}/>}>
        {tokenVerified ? (<CreateEvent />) : <Loading isOpen={true} />}
      </Page>
  )
};

export default EventsCreate;
