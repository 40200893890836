import { createContext, useContext, useState, useEffect } from "react";
import { useApi } from "../hooks/useAxiosPrivate";
import ClasseFormatter from "../../components/App/reusable/select/classes";

export interface ClassInterface {
    Classes: any[];
    setClass?: (classes: any[]) => void;
}

const DataContext = createContext<ClassInterface>({ Classes: [], setClass: () => { } });

export const ClassProvider = ({ children }) => {
    const { get, setCache, getCache } = useApi();
    const [Classes, setClass] = useState<any[]>([]);

    useEffect(() => {
        fetchData()
            .then(data => setClass(data))
            .catch(error => console.error("Error fetching data:", error));
    }, []);

    const fetchData = async () => {
        const controller = new AbortController();
        try {
            const cachedData = getCache(`classes`);
            if (cachedData) {
                ClasseFormatter.setAll(cachedData);
                return cachedData;
            }
            const response = await get("list-prod-categories", {
                signal: controller.signal
            });

            if (response.status !== 201) {
                console.log(response);
                throw new Error('Failed to fetch categories data');
            }

            const data = await response.data;
            const prodCategories = data.map(item => item.prod_category);
            setCache(`classes`, data);
            ClasseFormatter.setAll(prodCategories);
            return data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("Unauthorized, retrying...");
                // Retry logic
                return await retry(fetchData);
            } else {
                console.log("Error categories fetch:", error.message);
                throw error;
            }
        }
    };

    const retry = async (func) => {
        try {
            return await func();
        } catch (error) {
            console.log(`Retry failed. Retrying.`);
            return await retry(func);
        }
    };

    return (
        <DataContext.Provider value={{ Classes, setClass }}>
            {children}
        </DataContext.Provider>
    );
};

export const useCat = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error("useClass must be used within a ClassProvider");
    }
    return context;
};
