import { toast, ToastOptions } from 'react-hot-toast';

type ToastType = 'success' | 'error' | 'loading' | 'custom';

class CustomToast {
  private static instance: CustomToast;

  private constructor() {}

  public static getInstance(): CustomToast {
    if (!CustomToast.instance) {
      CustomToast.instance = new CustomToast();
    }
    return CustomToast.instance;
  }

  public show(message: string, type: ToastType, options?: ToastOptions): void {
    toast.dismiss(); // Dismiss all existing toasts

    const defaultOptions: ToastOptions = { duration: 5000 };
    const mergedOptions = { ...defaultOptions, ...options };

    switch (type) {
      case 'success':
        toast.success(message, mergedOptions);
        break;
      case 'error':
        toast.error(message, mergedOptions);
        break;
      case 'loading':
        toast.loading(message, mergedOptions);
        break;
      case 'custom':
        toast(message, mergedOptions);
        break;
      default:
        toast(message, mergedOptions);
    }
  }

  public dismiss(): void {
    toast.dismiss();
  }
}

export default CustomToast.getInstance();