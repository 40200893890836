import {
  IonContent,
  IonCard,
  IonItem,
  IonList,
  IonInput,
  InputChangeEventDetail,
} from "@ionic/react";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import Loading from "../reusable/loading/loading";
import Toaster from "../reusable/Toaster";


const ProfileRestaurantPage = () => {

  // request & handle response
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  // Profile fileds
  const [profile, setprofile] = useState(Object);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone, setPhone] = useState("");

  // Restaurant fields
  const [restaurant, setrestaurant] = useState(Object);
  const [rest_name, setRest_name] = useState("");
  const [rest_nicp, setRest_nicp] = useState("");
  const [rest_address, setRest_address] = useState("");
  const [rest_email, setRest_email] = useState("");
  const [rest_postal_code, setRest_postal_code] = useState("");
  const [rest_city, setRest_city] = useState("");
  const [rest_country, setRest_country] = useState("");
  const [rest_contact_phone, setRest_contact_phone] = useState("");
  const [rest_longitude, setRest_longitude] = useState(0);
  const [rest_latitude, setRest_latitude] = useState(0);

  // Submit profile info
  const handleProfileSubmit = async () => {
    setLoading(true);

    const data = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,

    };
    try {
      const response = await axiosPrivate.post('/profile-update/',
        JSON.stringify(data),
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show('Perfil atualizado com sucesso.', 'success')
        setChange(!change);
        setLoading(false);
      } else {
        Toaster.show('Erro ao atualizar perfil.', 'error')
        setLoading(false);
      }
    } catch (error) {
      Toaster.show('Erro ao atualizar perfil.', 'error')
      setLoading(false);
    }
  }

  // Submit restaurant info
  const handleRestaurantSubmit = async () => {
    setLoading(true);

    const data = {
      rest_name: rest_name,
      rest_nipc: rest_nicp,
      rest_address: rest_address,
      rest_email: rest_email,
      rest_postal_code: rest_postal_code,
      rest_city: rest_city,
      rest_coutry: rest_country,
      rest_contact_phone: rest_contact_phone,
      rest_longitude: rest_longitude,
      rest_latitude: rest_latitude
    };

    try {
      const response = await axiosPrivate.post('/restaurant-update/',
        JSON.stringify(data),
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show('Restaurante atualizado com sucesso.', 'success')
        setChange(!change);
        setLoading(false);
      } else {
        Toaster.show('Erro ao atualizar restaurante.', 'error')
        setLoading(false);
      }
    } catch (error) {
      Toaster.show('Erro ao atualizar restaurante.', 'error')
      setLoading(false);
    }
  }

  // Get profile info
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getter = async (endpoint) => {
      setLoading(true);
      try {
        const response = await axiosPrivate.get(endpoint, { signal: controller.signal });
        const responseData = Array.isArray(response.data) ? response.data[0] : response.data;
        console.log(responseData);
        eval(`set${endpoint}(responseData)`);
      } catch (error) {
        console.error("error", error);
      }
      setLoading(false);
    };
    getter("profile");
    getter("restaurant");

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [change]);

  useEffect(() => {
    if(restaurant){
      setRest_name(restaurant.rest_name);
      setRest_nicp(restaurant.rest_nipc);
      setRest_address(restaurant.rest_address);
      setRest_email(restaurant.rest_email);
      setRest_postal_code(restaurant.rest_postal_code);
      setRest_city(restaurant.rest_city);
      setRest_country(restaurant.rest_coutry);
      setRest_contact_phone(restaurant.rest_contact_phone);
      setRest_longitude(restaurant.rest_longitude);
      setRest_latitude(restaurant.rest_latitude);
    }
  }, [restaurant]);

  useEffect(() => {
    if(profile){
      setFirst_name(profile.first_name);
      setLast_name(profile.last_name);
      setPhone(profile.phone);
    }
  }, [profile]);
  
  const darkMode = useDarkMode();

  return (
    <IonContent>
      <div className="w-[60%] m-auto my-10">
        <h1 className="ml-2">Campos do perfil</h1>
        <IonCard>
          <IonList >
            <IonItem>
              <IonInput
                label="Primeiro nome:"
                name="name"
                placeholder={profile.first_name}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { console.log("FIRSTNAME" + e.detail.value); setFirst_name(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="Último nome:"
                name="name"
                placeholder={profile.last_name}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { console.log("LASTNAME" + e.detail.value); setLast_name(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="Número de telemóvel:"
                name="name"
                type="number"
                min={0}
                placeholder={profile.phone}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { console.log("PHONE " + e.detail.value); setPhone(e.detail.value) }}
              ></IonInput>
            </IonItem>
          </IonList>
        </IonCard>
        <button onClick={handleProfileSubmit} style={{ background: darkMode ? "#422655" : '' }} className="text-white bg-blue-dark p-3 ml-2 rounded hover:scale-110 transition-transform duration-300">
          Atualizar Perfil
        </button>

        <h1 className="ml-2">Campos do restaurante</h1>
        <IonCard>
          <IonList>
            <IonItem>
              <IonInput
                label="Nome do restaurante:"
                name="name"
                placeholder={restaurant.rest_name}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_name(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="NICP:"
                name="name"
                type="number"
                min={0}
                placeholder={restaurant.rest_nipc}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_nicp(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="Morada:"
                name="name"
                placeholder={restaurant.rest_address}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_address(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="Email:"
                name="name"
                placeholder={restaurant.rest_email}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_email(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="Código Postal:"
                name="name"
                type="number"
                min={0}
                placeholder={restaurant.rest_postal_code}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_postal_code(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="Cidade:"
                name="name"
                placeholder={restaurant.rest_city}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_city(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="País:"
                name="name"
                placeholder={restaurant.rest_coutry}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_country(e.detail.value) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="Latitude:"
                name="name"
                type="number"
                min={0}
                placeholder={restaurant.rest_latitude}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_latitude(Number(e.detail.value)) }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                label="Longitude:"
                name="name"
                type="number"
                min={0}
                placeholder={restaurant.rest_longitude}
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) => { setRest_longitude(Number(e.detail.value)) }}
              ></IonInput>
            </IonItem>
          </IonList>
        </IonCard>
        <button onClick={handleRestaurantSubmit} style={{ background: darkMode ? "#422655" : '' }} className="text-white bg-blue-dark p-3 ml-2 rounded hover:scale-110 transition-transform duration-300">
          Atualizar Restaurante
        </button>
      </div>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default ProfileRestaurantPage;