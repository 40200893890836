import React, { useCallback, useEffect, useState } from 'react';
import { IonGrid, IonCol, IonRow, IonModal, IonContent } from '@ionic/react';
import { AiFillEdit } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';
import useDarkMode from '../../../pages/Profile/usedarkmode';
import { useApi } from '../../../utils/hooks/useAxiosPrivate';
import Loading from '../reusable/loading/loading';
import InfoComponent from '../reusable/info/info.component';
import Toaster from '../reusable/Toaster';
import '../general.styles.scss';
import { FaExchangeAlt } from "react-icons/fa";


interface DataItem {
  dt_ref: string;
  sku_provider_id: string;
  sku_prov_name: string;
  total: number;
}

interface FillDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFill: () => void;
  refDate: string;
  setRefDate: (date: string) => void;
  darkMode: boolean;
}

interface DataTableProps {
  data: DataItem[];
  darkMode: boolean;
  onEditClick: (item: DataItem) => void;
  setShowModal: (show: boolean) => void;
}


const DataFilling: React.FC = () => {
  const { get, post } = useApi();
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DataItem | null>(null);
  const [refDate, setRefDate] = useState('');
  const [missingData, setMissingData] = useState(false);
  const darkMode = useDarkMode();



  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get(`list_missing_data/?missing_data=${missingData}`);
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      Toaster.show('Erro ao carregar lista de dados', 'error');
    } finally {
      setLoading(false);
    }
  }, [missingData]);

  const handleFill = async () => {
    if (!selectedItem || !refDate) {
      Toaster.show('Por favor, selecione uma data', 'error');
      return;
    }

    try {
      const fillInfo = {
        ...selectedItem,
        dt_ref: convertDateFormat(selectedItem.dt_ref),
        date: convertDateFormat(refDate),
      };
      await post('fill_data/', JSON.stringify(fillInfo));
      Toaster.show('Dados preenchidos com sucesso', 'success');
      setShowModal(false);
      setRefDate('');
      fetchData();
    } catch (error) {
      console.error('Error filling data:', error);
      Toaster.show('Erro ao preencher dados', 'error');
    }
  };

  const convertDateFormat = (dateStr: string): string => dateStr.replace(/-/g, '');

  useEffect(() => {
    fetchData();
  }, [fetchData, missingData]);



  const DataTable: React.FC<DataTableProps> = ({ data, darkMode, onEditClick, setShowModal }) => {
    const headerStyle = { background: darkMode ? '#422655' : '' };
    const bodyStyle = { backgroundColor: darkMode ? '#262853' : '' };
    const textStyle = { color: darkMode ? 'white' : 'black' };

    if (data.length === 0) {
      return null
    }

    return (
      <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
        <thead className="bg-blue-dark text-white rounded">
          <tr>
            {['Data de Referência', 'Fornecedor', 'Total de Resultados', 'Preencher'].map((header) => (
              <th key={header} style={headerStyle} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={bodyStyle} className="bg-white divide-y divide-gray-200">
          {data.map((item, index) => (
            <tr key={index}>
              <td style={textStyle} className="px-6 py-2 whitespace-no-wrap text-gray-600">{item.dt_ref}</td>
              <td style={textStyle} className="px-6 py-2 whitespace-no-wrap text-gray-600">{`${item.sku_provider_id} - ${item.sku_prov_name}`}</td>
              <td style={textStyle} className="px-6 py-2 whitespace-no-wrap text-gray-600">{item.total || 0}</td>
              <td style={textStyle} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                {!item.total ? (
                  <AiFillEdit size={32} className="cursor-pointer" color="red" title="Preencher" onClick={() => { setShowModal(true); onEditClick(item); }} />
                ) : (
                  <TiTick size={32} color="green" title="Preenchido" />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const FillDataModal: React.FC<FillDataModalProps> = ({ isOpen, onClose, onFill, refDate, setRefDate, darkMode }) => {
    return (
      <IonModal isOpen={isOpen} onDidDismiss={onClose}>
        <div className="overflow-auto p-10">
          <h2 style={{ color: darkMode ? 'white' : 'black', paddingBottom: '20px', textAlign: 'center' }}>
            Preencher dados
          </h2>
          <div className="flex flex-col gap-8">
            <div className='flex flex-col gap-2'>
              <label className={`text-xl font-bold ${darkMode ? 'text-white' : 'text-black'}`}>Data</label>
              <label className={`text-sm font-bold ${darkMode ? 'text-zinc-200' : 'text-zinc-500'}`}>
                Data que será utilizada para preencher os dados em falta
              </label>
              <input
                type="date"
                value={refDate}
                style={{
                  color: darkMode ? 'white' : 'black',
                  backgroundColor: darkMode ? '#422653d4' : '#d9d9d9',
                }}
                onChange={(e) => setRefDate(e.target.value)}
                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
              />
            </div>
            <div className="flex justify-between gap-x-3 pt-5">
              <button
                onClick={onFill}
                style={{ background: darkMode ? '#422655' : '' }}
                className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
              >
                Preencher
              </button>
              <button
                onClick={onClose}
                style={{ background: darkMode ? '#422655' : '' }}
                className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </IonModal>
    );
  };

  return (
    <IonContent>
      <div className="ion-padding">
        <IonGrid>
          {data.length > 0 && <IonRow className="pb-8 px-12">
            <IonCol size="12">
              <InfoComponent>
                <div>
                <p>{missingData ? "Dados em falta no último ano (limite de 20)" : "Dados para os últimos 15 dias"}</p>
                <p >Falta de dados apresenta zero resultados</p>
                </div>
              </InfoComponent>
              <div className='flex gap-4 items-center py-6'>
                <div onClick={() => setMissingData(!missingData)} className={`cursor-pointer  rounded-lg border border-black w-fit aspect-square p-2 transition-all duration-500 ease-in-out ${missingData ? "bg-indigo-400 text-black" : "bg-indigo-700 text-white"}`}>
                  <FaExchangeAlt size={20} title='Mudar' />
                </div>
                <label className={`${darkMode ? "text-white" : "text-black"} text-xl`}>{!missingData ? "Mudar para mostrar apenas dados em falta" : "Mudar para mostrar a lista completa"}</label>
              </div>
            </IonCol>
            <IonCol size="12">
              <h1 style={{ color: darkMode ? "white" : "black" }}>Estatísticas de recolha de dados de SKU's</h1>
            </IonCol>
            <IonCol>
              <DataTable data={data} darkMode={darkMode} onEditClick={setSelectedItem} setShowModal={setShowModal} />
            </IonCol>
          </IonRow>}
        </IonGrid>
        <FillDataModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onFill={handleFill}
          refDate={refDate}
          setRefDate={setRefDate}
          darkMode={darkMode}
        />
      </div>
      <Loading isOpen={loading} />
    </IonContent>
  );
};








export default DataFilling;