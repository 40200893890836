import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonRow, IonText } from "@ionic/react";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import InfoComponent from "../../reusable/info/info.component";
import Loading from "../../reusable/loading/loading";
import Paginate from "../../reusable/pagination/paginate";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Toaster from "../../reusable/Toaster";
import toast from "react-hot-toast";



const Validate = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState("");
  const [currentPageRecipes, setCurrentPageIngreds] = useState(1);
  const [totalPagesIngreds, setTotalPagesRecipes] = useState(1);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController();

    const getGraph = async (url) => {
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        setData(response.data.results.recipes);


        setTotalPagesRecipes(response.data.results.total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    const apiUrl = `recipe_list_not_validated?page=${currentPageRecipes}`;

    getGraph(apiUrl);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [currentPageRecipes]);


  const [toastId, setToastId] = useState(null);

  const handleValidation = async () => {



    setLoading(true);

    if (selectedRecipe === "") {
      Toaster.show("Selecione pelo menos um recipee para reprocessar.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(`/validate_recipe/`,
        JSON.stringify({ "recipe_id": selectedRecipe }),
      );
      if (response.status === 200 || response.status === 201) {
        Toaster.show('Validação efetuada com sucesso.', 'success')
        setLoading(false);
        setSelectedRecipe("");
      } else {
        Toaster.show('Erro ao validar receitas.' + response.data.message, 'error')
        setLoading(false);
      }
    } catch (error) {
      const response = error.response;
      if (response.status === 409) {
        toast((t) => (
          <span className="flex text-white">
            <div>
              <p>Erro ao validar receita. Ingredientes <b>{response.data.data.join(", ")}</b> não {response.data.data.lenght > 1 ? "têm" : "tem"} produtos/SKUs associados.</p>
              <p className="pt-2"><b>Por favor associe produtos/SKUs aos ingredientes referidos e volte a tentar.</b></p>
            </div>
            <button onClick={() => toast.dismiss(t.id)}>
              <IoMdCloseCircleOutline size={32} title="Fechar" />
            </button>
          </span>
        ), {
          duration: Infinity,
          style: {
            backgroundColor: 'red',
            color: '#fff',
          },
        });
        setLoading(false);
      } else {
        console.error("Error:", error);
        Toaster.show('Erro ao reprocesar receitas. Tente novamente mais tarde.', 'error')
        setLoading(false);
      }
    }
  };

  const darkMode = useDarkMode();



  const handleCheckboxChange = (recipe_id) => {
    setSelectedRecipe((prevSelectedRecipe) => {
      if (prevSelectedRecipe === recipe_id) {
        return ""; // deselect the recipe if it's already selected
      } else {
        return recipe_id;
      }
    });
  };



  return (
    <IonContent>
      
      <IonGrid style={{ padding: "70px", paddingTop: "50px" }}>
        <IonRow>
          <IonCol size="12">
            <IonText style={{ color: darkMode ? "white" : "black" }} className="text-4xl font-semibold mt-10 text-blue-dark">Validar Receitas</IonText>
          </IonCol>
          <IonCol size="12">
            <InfoComponent text="">
              <div>
                <p>Serão apresentadas as receitas que ainda não se encontram validadas.</p>
              </div>
            </InfoComponent>
          </IonCol>
        </IonRow>
        <div style={{ padding: "30px", paddingTop: "20px" }}>
          <IonRow>
            <IonCol size="12">
              <IonButton id="update-selected">
                Validar selecionada
              </IonButton>
            </IonCol>
            <IonAlert
              header="Atenção"
              trigger="update-selected"
              subHeader="Validar receitas selecionadas."
              message="Tem a certeza que deseja validar a receita selecionada?"
              buttons={[
                {
                  text: 'Cancelar',
                  role: 'cancelar',
                  handler: () => {

                  },
                },
                {
                  text: 'OK',
                  role: 'confirmar',
                  handler: () => {

                    handleValidation();
                  },
                },
              ]}
            >
            </IonAlert>
          </IonRow>
          <InfoComponent text="">
            <div>
              <p>Poderá validar uma ou mais receitas, isto é, alterar o estado da receita no sistema. Apenas poderá selecionar uma de cada vez.</p>
              <p>Uma receita para poder passar a estar ativa tem de ter ingredientes associadas com quantidades definidas.</p>
            </div>
          </InfoComponent>

          {data.length > 0 && (
            <IonRow >
              <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                <thead className='bg-blue-dark text-white rounded'>
                  <tr>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Nome</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Classe</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Descrição</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Estado</th>
                    <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Selecionar</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: darkMode ? '#262853' : '' }} className="bg-white divide-y divide-gray-200">
                  {data.map((recipe) => (
                    <tr key={recipe.recipe_id}>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        {recipe.recipe_name}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        {recipe.recipe_class}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        {recipe.recipe_descrip}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="py-2 whitespace-no-wrap text-gray-600">
                        {recipe.recipe_status}
                      </td>
                      <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                        <input
                          type="checkbox"
                          checked={selectedRecipe && selectedRecipe === recipe.recipe_id}
                          onChange={() => handleCheckboxChange(recipe.recipe_id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </IonRow>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
          <Paginate currentPage={currentPageRecipes} setCurrentPage={setCurrentPageIngreds} totalPages={totalPagesIngreds} loading={data.length <= 0} />
        </div>

      </IonGrid>
      <Loading isOpen={loading} />
    </IonContent>
  );
}

export default Validate;