import React, { useEffect, useState, useCallback } from 'react';
import {
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonSearchbar
} from "@ionic/react";
import "../general.styles.scss";
import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";

import MyTable from "../reusable/table/table-component";
import SelectComponent from "../reusable/select/select";
import Loading from "../reusable/loading/loading";
import NWeeksFormatter from "../reusable/select/nweeks";
import Toaster from '../reusable/Toaster';

const INITIAL_WEEKS = 4;

const columns = [
  { headerName: 'Receita', field: 'recipe_name' },
  { headerName: 'Delta', field: 'delta_r' },
  { headerName: 'Delta pct', field: 'delta_pct_r' },
  { headerName: 'Média', field: 'recipe_price_avg_r' },
  { headerName: 'Preço inicial', field: 'price_ini_r' },
  { headerName: 'Preço final', field: 'price_fim_r' },
  { headerName: 'Preço máximo', field: 'price_max_r' },
  { headerName: 'Preço mínimo', field: 'price_min_r' },
];

const FoodCostTable = () => {
  const { get } = useApi();
  const darkMode = useDarkMode();

  const [recipes, setRecipes] = useState([]);
  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchRecipes = useCallback(async () => {
    setLoading(true);
    try {
      const url = `dash3_list_recipes/?nweeks=${nweeks}`;
      const response = await get(url);
      setRecipes(response.data);
    } catch (error) {
      Toaster.show("Erro ao carregar dados de receitas", "error");
      console.error("Error fetching recipes:", error);
    } finally {
      setLoading(false);
    }
  }, [nweeks]);

  useEffect(() => {
    fetchRecipes();
  }, [fetchRecipes]);

  const filteredRecipes = recipes.filter((recipe) =>
    recipe.recipe_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <DarkModeProvider>
      <IonContent>
        <IonGrid>
          <IonRow className="padding">
            <IonCol style={{marginTop:'10px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <SelectComponent 
                formatter={NWeeksFormatter} 
                darkMode={darkMode} 
                onChange={setNweeks} 
              />
            </IonCol>
          </IonRow>
          <IonGrid className="padding">
            <div className="search">
              <IonSearchbar
                value={searchQuery}
                onIonChange={(e) => setSearchQuery(e.detail.value)}
                placeholder="Pesquisar por receita"
                style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }}
              />
            </div>
            <MyTable 
              rowData={filteredRecipes} 
              columnDefs={columns} 
              link={'/app/foodcost/recipes?item='} 
              linkIndex={0} 
              darkMode={darkMode} 
              title="Receitas" 
            />
          </IonGrid>
          <Loading isOpen={loading} />
        </IonGrid>
      </IonContent>
    </DarkModeProvider>
  );
};

export default FoodCostTable;