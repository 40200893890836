import React, { useEffect, useState, useCallback, useRef } from "react";
import { IonContent, IonGrid } from "@ionic/react";
import ChartComponent from "../reusable/charts/chartComponent";
import ChartFunc from "../reusable/charts/chartFunc";
import MyTable from "../reusable/table/table-component";
import SelectComponent from "../reusable/select/select";
import Loading from "../reusable/loading/loading";
import NWeeksHelp from "../reusable/NWeeksHelp";
import { useCat } from "../../../utils/contexts/dataCat.context";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import NWeeksFormatter from "../reusable/select/nweeks";
import CategoryFormatter from "../reusable/select/categories";
import "../general.styles.scss";

const INITIAL_CHART_STATE = {};
const INITIAL_CHART_FUNC_STATE = {
  func: '',
  option: {},
  drilldown_data: []
};

const INITIAL_COLUMNS_Q22 = [
  { headerName: 'Name', field: 'name' },
  { headerName: 'Price', field: 'price' },
  { headerName: 'Variation', field: 'variation' }
];

const INITIAL_COLUMNS_Q42 = [
  { headerName: 'Name', field: 'name' },
  { headerName: 'Category', field: 'category' },
  { headerName: 'Price', field: 'price' },
  { headerName: 'Variation', field: 'variation' }
];

const OwnBasketGraphs = () => {
  const darkMode = useDarkMode();
  const { get } = useApi();
  const categories = useCat();
  const [chartData, setChartData] = useState({
    q11: INITIAL_CHART_FUNC_STATE,
    q12: INITIAL_CHART_STATE,
    q21: INITIAL_CHART_STATE,
    q22: INITIAL_CHART_STATE,
    q31: INITIAL_CHART_STATE,
    q32: INITIAL_CHART_STATE,
    q41: INITIAL_CHART_STATE,
    q42: INITIAL_CHART_STATE,
  });

  const [nweeks, setNweeks] = useState(4);
  const [category, setCategory] = useState(categories.Categories[0] || "");
  const [loading, setLoading] = useState(false);
  const chartRef = useRef(null);

  // Set initial category when categories are available
  useEffect(() => {
    if (categories.Categories.length > 0) {
      setCategory(categories.Categories[0]);
    }
  }, [categories.Categories]);

  const fetchChartData = useCallback(
    async (endpoint, params = {}) => {
      const queryString = new URLSearchParams(params).toString();
      const url = `dash2_v2_${endpoint}/${queryString ? `?${queryString}` : ''}`;

      try {
        setLoading(true);
        const response = await get(url);
        setChartData(prevData => ({
          ...prevData,
          [endpoint]: response.data
        }));
      } catch (error) {
        console.error(`Error fetching data for ${endpoint}:`, error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    const endpoints = ['q11', 'q12', 'q21', 'q22', 'q31', 'q32', 'q41', 'q42'];
    endpoints.forEach(endpoint => {
      const params = endpoint.startsWith("q4") ? { nweeks, category } : { nweeks };
      fetchChartData(endpoint, params);
    });
  }, [nweeks, category, fetchChartData]);

  const renderChart = (ChartComponent, option, props = {}) => (
    <ChartComponent option={option} chartRef={chartRef} {...props} />
  );

  return (
    <IonContent>
      <IonGrid className="padding pb-[100px]">
        <div className="py-4 flex items-center">
          <div className="w-[30%]">
            <SelectComponent
              formatter={NWeeksFormatter}
              darkMode={darkMode}
              onChange={setNweeks}
            />
          </div>
          <div className="w-[70%]">
            <NWeeksHelp nweeks={nweeks} />
          </div>
        </div>

        <div className="grid xl:grid-cols-2 gap-4 ">
          {renderChart(ChartFunc, chartData.q11.option, {
            func: chartData.q11.func,
            drilldown_data: chartData.q11.drilldown_data,
          })}

          {renderChart(ChartComponent, chartData.q12)}

          {renderChart(ChartComponent, chartData.q21)}

          <MyTable
            rowData={chartData.q22}
            columnDefs={INITIAL_COLUMNS_Q22}
            title="Produtos com maior variação do restaurante"
            darkMode={darkMode}
          />
          {renderChart(ChartComponent, chartData.q31)}
          {renderChart(ChartComponent, chartData.q32["chart_data"])}


        </div>

        {categories.Categories.length > 0 && (
          <div >
            <div className="py-10 ">
              <SelectComponent
                formatter={CategoryFormatter}
                darkMode={darkMode}
                onChange={setCategory}
                value={category}
              />
            </div>
            <div className="grid xl:grid-cols-2 gap-4 ">
              {renderChart(ChartComponent, chartData.q41)}
              <MyTable
                rowData={chartData.q42}
                columnDefs={INITIAL_COLUMNS_Q42}
                title="Lista Top 5 de produtos da categoria com maior amplitude de preço do restaurante"
                darkMode={darkMode}
              />
            </div>
          </div>
        )}
      </IonGrid>

      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default OwnBasketGraphs;
