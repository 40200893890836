import React, { useEffect, useState, useCallback } from 'react';
import {
  IonContent,
  IonGrid,
  IonRow,
  IonSearchbar,
  IonCol,
  IonText
} from "@ionic/react";
import { useLocation } from 'react-router-dom';
import "../general.styles.scss";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";

import MyTable from "../reusable/table/table-component";
import SelectComponent from "../reusable/select/select";
import BackComponent from "../reusable/goBack/go-back-component";
import Loading from "../reusable/loading/loading";
import NWeeksFormatter from "../reusable/select/nweeks";

const INITIAL_WEEKS = 4;

const columns = [
  { headerName: 'Ingrediente', field: 'ingr_name' },
  { headerName: 'Delta', field: 'delta' },
  { headerName: 'Delta pct', field: 'delta_pct' },
  { headerName: 'Média', field: 'price_avg' },
  { headerName: 'Preço inicial(€)', field: 'price_ini' },
  { headerName: 'Preço final(€)', field: 'price_fim' },
  { headerName: 'Preço máximo(€)', field: 'price_max' },
  { headerName: 'Preço mínimo(€)', field: 'price_min' },
];

const FoodCostRecipesComponent = () => {
  const location = useLocation();
  const { get } = useApi();
  const darkMode = useDarkMode();

  const recipe = new URLSearchParams(location.search).get('item');

  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const [loading, setLoading] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchIngredientData = useCallback(async () => {
    setLoading(true);
    try {
      const url = `dash3_list_ingr/?nweeks=${nweeks}&recipe=${recipe}`;
      const response = await get(url);
      setIngredients(response.data);
    } catch (error) {
      console.error("Error fetching ingredient data:", error);
    } finally {
      setLoading(false);
    }
  }, [nweeks, recipe]);

  useEffect(() => {
    fetchIngredientData();
  }, [fetchIngredientData]);

  const filteredIngredients = ingredients.filter((ingredient) =>
    ingredient.ingr_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <IonContent className="ion-padding">
      <div className='padding'>
        <IonRow className="padding">
          <IonCol>
            <IonText className="welcome">Evolução dos preços de aquisição de bens alimentares</IonText>
          </IonCol>
          <BackComponent />
          <IonCol style={{ marginTop: "10px", display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <SelectComponent
              formatter={NWeeksFormatter}
              darkMode={darkMode}
              onChange={setNweeks}
            />
          </IonCol>
        </IonRow>
        <IonRow style={{ paddingBottom: '20px', paddingTop: "50px" }}>
          <div className="homePageText">
            <div style={{ color: darkMode ? '#f4f4f4' : "black" }} className="seeNews">
              Visão de produtos
            </div>
          </div>
        </IonRow>

        <div className="search">
          <IonSearchbar
            value={searchQuery}
            onIonChange={(e) => setSearchQuery(e.detail.value)}
            placeholder="Pesquisar por ingrediente"
            style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }}
          />
        </div>
        <MyTable
          rowData={filteredIngredients}
          link='/app/foodcost/recipes/ingr?item='
          linkIndex={0}
          columnDefs={columns}
          title={recipe}
          darkMode={darkMode}
        />
        <Loading isOpen={loading} />
      </div>
    </IonContent>
  );
};

export default FoodCostRecipesComponent;