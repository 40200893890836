
import OwnBasketGraphs from "../../../components/App/market/ownbasket-graphs";
import "./OwnBasketVision.styles.scss";
import Page from "../page";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../components/App/reusable/loading/loading";
import { IoBasket } from "react-icons/io5";

const OwnBasketPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'OWN BASKET VISION PAGE',
  });

  return (
    <Page title={"Preços - Visão Cabaz Próprio"} icon={<IoBasket size={32}/>}>
      {tokenVerified ? (<OwnBasketGraphs />) : <Loading isOpen={true} />}
    </Page>
  );
};

export default OwnBasketPage;
