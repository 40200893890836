import {
    IonContent,
    IonHeader,
    IonPage,
  } from "@ionic/react";

  import bg from "../assets/images/background.webp"
  
  const NotFoundPage: React.FC = () => {
    return (
      <IonPage>
        <IonHeader>
        </IonHeader>
        <IonContent>
        <div className="relative isolate h-full overflow-hidden py-24 sm:py-48 flex items-center justify-center">
          <div className="w-1/2">
            <img src={bg} className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center" alt="Background Image" />
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <section className="bg-white">
                <div className="mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                  <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">404</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">Cozinha Errada!</p>
                    <p className="mb-4 text-lg font-light text-gray-500">A pagina que tentou entrar não existe ou está em manutenção. </p>
                    <a href="#" className="inline-flex text-white bg-violet rounded-lg text-sm px-5 py-2.5 text-center">Volte à página principal.</a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        </IonContent>
      </IonPage>
    );
  };
  
  export default NotFoundPage;
  