
import Forecast from "../../../components/App/forecast/forecastView";
import Page from "../page";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../components/App/reusable/loading/loading";
import { IoPlayForwardCircle } from "react-icons/io5";




const ForecastView = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'FORECAST VIEW PAGE',
  });
  return (
      <Page title={"Resultados do Forecast"} icon={<IoPlayForwardCircle size={32}/>}>
        {tokenVerified ? (<Forecast />) : <Loading isOpen={true} />}
      </Page>
  );
};

export default ForecastView;
