import React, { useEffect, useState, useRef } from "react";
import { IonContent, IonGrid } from "@ionic/react";
import "../general.styles.scss";
import ChartComponent from "../reusable/charts/chartComponent";
import MyTable from "../reusable/table/table-component";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import CategoryFormatter from "../reusable/select/categories";
import NWeeksFormatter from "../reusable/select/nweeks";
import SelectComponent from "../reusable/select/select";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import Loading from "../reusable/loading/loading";
import { useCat } from "../../../utils/contexts/dataCat.context";
import NWeeksHelp from "../reusable/NWeeksHelp";

// Define types
type ChartOptions = Record<string, any>;
type ColumnDef = { headerName: string; field: string };

const FoodCostGraphs: React.FC = () => {
  const { get } = useApi();
  const categories = useCat();
  const [nweeks, setNweeks] = useState<number>(4);
  const [category, setCategory] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const darkMode = useDarkMode();

  const [columnsD5Q21] = useState<ColumnDef[]>([
    { headerName: "Receita", field: "rest_prod_desc" },
    { headerName: "Doses", field: "doses" },
    { headerName: "Custo", field: "rec_foodcost" },
    { headerName: "%", field: "weight" },
    { headerName: "% Acumulada", field: "cumulative" },
  ]);

  const [columnsD5Q41] = useState<ColumnDef[]>([
    { headerName: "Ingrediente", field: "rest_ingr_name" },
    { headerName: "Peso", field: "kg" },
    { headerName: "Custo", field: "ingr_foodcost" },
    { headerName: "%", field: "weight" },
    { headerName: "% Acumulada", field: "cumulative" },
  ]);

  const [chartOptions, setChartOptions] = useState<Record<string, ChartOptions>>({
    q11: {}, q12: {}, q21: {}, q22: {}, q31: {}, q32: {}, q41: {}
  });



  const fetchChartData = async (endpoint: string, params: Record<string, any>, signal: AbortSignal) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `dash5_${endpoint}/?${queryString}`;

    try {
      const response = await get(url, { signal });
      return { endpoint, data: response.data };
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      // Toaster.show(`Erro ao carregar dados.`, "error");
      return { endpoint, data: {} };
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    const fetchData = async () => {
      const endpoints = ['q11', 'q12', 'q21', 'q22', 'q31', 'q32', 'q41'];
      const promises = endpoints.map(endpoint =>
        fetchChartData(endpoint, { nweeks, category }, controller.signal)
      );

      try {
        const results = await Promise.all(promises);
        const newChartOptions = results.reduce((acc, { endpoint, data }) => {
          acc[endpoint] = data;
          return acc;
        }, {});

        setChartOptions(newChartOptions);
        setLoading(false);
      } catch (error) {
        // Toaster.show(`Erro ao carregar dados.`, "error");
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();

    return () => controller.abort();
  }, [nweeks, category]);

  useEffect(() => {
    if (categories.Categories.length > 0) {
      setCategory(categories.Categories[0]);
    }
  }, [categories.Categories]);

  const chartRef = useRef(null);

  const handleChartFinished = () => {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
  };

  return (
    <IonContent className="ion-padding">
      <IonGrid className="padding">
        <div className=" py-4 flex items-center">
          <div className="w-[30%]">
            <SelectComponent
              formatter={NWeeksFormatter}
              darkMode={darkMode}
              onChange={setNweeks}
            />
          </div>
          <div className="w-[70%]">
            <NWeeksHelp nweeks={nweeks} />
          </div>
        </div>
        <IonGrid>
          <div className="grid xl:grid-cols-2 gap-4">
            <ChartComponent
              chartRef={chartRef}
              option={chartOptions.q11}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />

            <ChartComponent
              chartRef={chartRef}
              option={chartOptions.q12}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />

            <MyTable
              subText="Número de doses vendidas em todo o período de análise"
              rowData={chartOptions.q21}
              columnDefs={columnsD5Q21}
              title="Ranking de custos por Prato/Receita"
              darkMode={darkMode}
            />

            <ChartComponent
              chartRef={chartRef}
              option={chartOptions.q22}
              handleChartFinished={handleChartFinished}
              ChangeScale={false}
            />
          </div>
        </IonGrid>

        {categories.Categories.length > 0 && (
          <>
            <div className="py-10">
              <SelectComponent
                formatter={CategoryFormatter}
                darkMode={darkMode}
                onChange={setCategory}
              />
            </div>
            <div className="grid xl:grid-cols-2 gap-4">
              <ChartComponent
                chartRef={chartRef}
                option={chartOptions.q31}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={chartOptions.q32}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />

              <MyTable
                rowData={chartOptions.q41}
                columnDefs={columnsD5Q41}
                title="Ranking de custos por Ingrediente"
                darkMode={darkMode}
              />
            </div>
          </>
        )}
      </IonGrid>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default FoodCostGraphs;