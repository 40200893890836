// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-title {
  text-align: center;
}

@media (min-width: 300px) {
  .form-wrapper {
    background-color: white;
    padding: 0.5rem;
    border-radius: 20px;
    width: 91%;
    height: auto;
    position: absolute;
    margin: 0 auto;
    margin-top: 1rem;
    box-shadow: 0px 0px 24px -10px rgba(0, 0, 0, 0.75);
  }
  .form-btn {
    margin: 1rem auto;
  }
}
@media (min-width: 800px) {
  .form-wrapper {
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    width: 100vh;
    height: 60vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 24px -10px rgba(0, 0, 0, 0.75);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/signup-form.styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE;IAOE,uBAAA;IACA,eAAA;IACA,mBAAA;IACA,UAAA;IACA,YAAA;IACA,kBAAA;IACA,cAAA;IACA,gBAAA;IACA,kDAAA;EALF;EAQA;IACE,iBAAA;EANF;AACF;AAUA;EACE;IACE,uBAAA;IACA,aAAA;IACA,mBAAA;IACA,YAAA;IACA,YAAA;IACA,kBAAA;IACA,SAAA;IACA,QAAA;IACA,gCAAA;IACA,kDAAA;EARF;AACF","sourcesContent":[".form-title {\n  text-align: center;\n}\n\n@media(min-width:300px) {\n  .form-wrapper {\n    // width: 100%;\n    // height: auto;\n    // border-radius: 20px;\n    // padding: 1rem 0;\n    // margin: 0 auto;\n\n    background-color: #ffff;\n    padding: 0.5rem;\n    border-radius: 20px;\n    width: 91%;\n    height: auto;\n    position: absolute;\n    margin: 0 auto;\n    margin-top: 1rem;\n    box-shadow: 0px 0px 24px -10px rgba(0, 0, 0, 0.75);\n  }\n\n  .form-btn {\n    margin: 1rem auto;\n  }\n\n}\n\n@media (min-width: 800px) {\n  .form-wrapper {\n    background-color: #ffff;\n    padding: 1rem;\n    border-radius: 20px;\n    width: 100vh;\n    height: 60vh;\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    box-shadow: 0px 0px 24px -10px rgba(0, 0, 0, 0.75);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
