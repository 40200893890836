class NWeeksFormatter {
  static weeks = [4, 6, 8];
  static default = 4;

  static format(input) {
    if (this.weeks.includes(input)) {
      return `${input} semanas`;
    } else {
      return null; // Return null or any other default value if the input is not valid
    }
  }

  static getAll() {
    return this.weeks;
  }

}

export default NWeeksFormatter;