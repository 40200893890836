import {
  IonButton,
  IonContent,
  IonText,
  IonLoading,
} from "@ionic/react";

import toast from 'react-hot-toast';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import InfoComponent from "../reusable/info/info.component";
import Loading from "../reusable/loading/loading";
import { useCat } from "../../../utils/contexts/dataClass.context";
import { use } from "echarts";
import Toaster from "../reusable/Toaster";


const RestSelectProdsPage = () => {

  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [changedList, setChangedList] = useState(false);
  const [selectedProds, setSelectedProds] = useState([]);
  const [checked, setChecked] = useState([]);

  const { Classes } = useCat();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleCategoryChange = (prod_category_id, event) => {
    setSelectedCategories(prevState => {
      const existingItemIndex = prevState.findIndex(item => item.id === prod_category_id);

      if (existingItemIndex !== -1) {
        // If the item exists, replace its category with the new one
        const updatedItem = { ...prevState[existingItemIndex], category: event.target.value };
        return prevState.map((item, index) => index === existingItemIndex ? updatedItem : item);
      } else {
        // If the item doesn't exist, add a new one
        const newCategory = { id: prod_category_id, category: event.target.value };
        return [...prevState, newCategory];
      }
    });

  };


  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();

    // Get restaurant products
    const getData = async (endpoint) => {
      setLoading(true);
      try {
        const response = await axiosPrivate.get(endpoint, { signal: controller.signal });
        setData(response.data);
        setChecked(response.data.map((item) => item.prod_is_recipe ? true : false));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getData("rest_prods_list");

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);
  const darkMode = useDarkMode();


  // Change recipes
  const postData = async (endpoint, data) => {
    try {
      const response = await axiosPrivate.post(endpoint, data);
      Toaster.show('Produtos adicionados com sucesso.', 'success')
    } catch (error) {
      Toaster.show('Erro ao adicioar produtos.', 'error')
    }
    setChangedList(!changedList);
    setSelectedProds([]);

  };

  const category_change = async () => {

    if (selectedCategories.length === 0) {
      Toaster.show('Não existem categorias selecionadas.', 'error');
      return;
    }
    setLoading(true);
    try {
      const response = await axiosPrivate.post("/update_product_categories/", selectedCategories);
      if (response.status === 201) {
        Toaster.show('Categorias alteradas com sucesso.', 'success')
        setChangedList(!changedList);
        setSelectedCategories([]);
      } else {
        Toaster.show('Erro ao alterar categorias.', 'error')
      }
    } catch (error) {
      Toaster.show('Erro ao alterar categorias.', 'error')
    }

    setLoading(false);
  };

  const handleCheckboxChange = (prod_id, index) => {
    setChecked(prevChecked => {
      const newChecked = [...prevChecked];
      newChecked[index] = !prevChecked[index];
      return newChecked;
    });

    setSelectedProds(prevSelectedProds => {
      if (prevSelectedProds.includes(prod_id)) {
        return prevSelectedProds.filter(id => id !== prod_id);
      } else {
        return [...prevSelectedProds, prod_id];
      }
    });
  };

  const getValue = (item) => {
    const selectedCategory = selectedCategories.find((categoryItem) => categoryItem.id === item.rest_prod_id);
    return selectedCategory ? selectedCategory.category : item.prod_category;
  }

  return (
    <IonContent>
      <div className='w-[60%] m-auto text-black' style={{ paddingTop: "50px" }}>
        {!loading && <><div style={{ paddingBottom: "10px" }}>

          <IonText style={{ color: darkMode ? "white" : "black" }} className="text-4xl font-semibold text-blue-dark">Receitas do restaurante</IonText>
          <InfoComponent text="">
            <p>Selecione os produtos cuja a confeção é feita no seu restaurante. Os produtos selecionados serão automaticamente introduzidos na aplicação como receitas. Deste modo terá acesso à monitorização das receitas do seu restaurante.</p>
          </InfoComponent>
          <div className="flex justify-between">
            <IonButton className="p-4" onClick={() => postData("/select_rest_prods/", { product_ids: selectedProds })}>
              Submeter
            </IonButton>
            <IonButton className="p-4" onClick={() => category_change()}>
              Atualizar Categorias
            </IonButton>
          </div>
          {data.length > 0 ? <div><table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded"  >
            <thead className='bg-blue-dark rounded text-white'>
              <tr>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Produto
                </th>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Categoria
                </th>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                  Selecionar
                </th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: darkMode ? '#262853' : '' }} className="bg-white divide-y divide-gray-200">
              {data.map((item, index) => (
                <tr key={index}>
                  <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                    {item.rest_prod_desc}
                  </td>
                  <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                    <select className="p-2 rounded-xl bg-transparent outline-none" value={getValue(item)} onChange={(event) => handleCategoryChange(item.rest_prod_id, event)}>
                      {Classes && Classes.map((classItem, classIndex) => (
                        <option key={classIndex} value={classItem.prod_category}>
                          {classItem.prod_category}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
                    <input
                      type='checkbox'
                      onChange={() => handleCheckboxChange(item.rest_prod_id, index)}
                      checked={checked[index]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table> </div> : <div style={{ color: darkMode ? "white" : "black" }} className="text-center text-2xl text-blue-dark">Não existem Produtos.</div>}
        </div>
        </>}
      </div>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default RestSelectProdsPage;