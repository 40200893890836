import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonLoading,
  IonGrid,
  IonRow,
  IonAlert,
} from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router";
import { deployURL, localURL } from "../../urls";


const url = deployURL.concat("auth/signup");

const particular = "particular";

const SignUpForm = ({ idToken, uid, username, segment }) => {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState({ loading: false, error: false });
  const [result, setResult] = useState(false);
  const [dob, setDOB] = useState("");
  const [form, setForm] = useState({
    name: "",
    address: "",
    phone: "",
    mobile: "",
    ssn: "",
    bdayDay: null,
    bdayMonth: null,
    bdayYear: null,
  });

  useEffect(() => {
    const setDate = () => {
      const string = form.bdayDay + "-" + form.bdayMonth + "-" + form.bdayYear;
      setDOB(string);
    };
    setDate();
  }, [form.bdayDay, form.bdayMonth, form.bdayYear]);


  async function submitUser() {
    try {
      setStatus({ loading: true, error: false });

      if (
        form.name === "" ||
        form.address === "" ||
        form.phone === "" ||
        form.mobile === "" ||
        form.ssn === "" ||
        form.bdayDay === "" ||
        form.bdayMonth === "" ||
        form.bdayYear === ""
      ) {
        throw new Error("blank-form");
      } else if (
        form.bdayDay < 0 ||
        form.bdayDay > 31 ||
        form.bdayMonth < 0 ||
        form.bdayMonth > 12 ||
        form.bdayYear < 1900 ||
        form.bdayYear > 2021
      ) {
        throw new Error("invalid-dob");
      } else if (form.mobile.length < 9 || form.mobile.length > 12) {
        throw new Error("invalid-mobile");
      } else if (form.phone.length < 9 || form.phone.length > 12) {
        throw new Error("invalid-phone");
      } else if (form.ssn.length < 9 || form.ssn.length > 12) {
        throw new Error("invalid-ssn");
      }

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          id: uid,
          name: form.name,
          //email: email,
          phone: form.phone,
          mobile: form.mobile,
          address: form.address,
          dob: dob,
          ssn: form.ssn,
        }),
        headers: {
          Authorization: idToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        console.log("response not ok");
        throw new Error(response.statusText);
      }
      const result = await response.text();
      setStatus({ loading: false, error: false });
      setResult(true);
      return result;
    } catch (error) {
      switch (error.message) {
        case "blank-form":
          setMessage("Por favor preencha todos os campos.");
          break;
        case "invalid-dob":
          setMessage("A data de nascimento é inválida.");
          break;
        case "invalid-mobile":
          setMessage("O número de telemóvel é inválido.");
          break;
        case "invalid-phone":
          setMessage("O número de telefone é inválido.");
          break;
        case "invalid-ssn":
          setMessage("O NIF é inválido.");
          break;
        case " Conflict":
          setMessage("Este NIF já existe.");
          break;
          case "Conflict":
          setMessage("Este NIF já existe.");
          break;
        default:
          setMessage("Por favor tente mais tarde.");
          console.log(error.message);
      }
      setStatus({ loading: false, error: true });
      if (error instanceof Error) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  if (result) {
    return <Redirect to="/app/parcels" />;
  }

  return (
    <Fragment>
      <IonList className="form-list">
        <IonItem>
          {segment === particular ? (
            <IonLabel position="floating">Nome</IonLabel>
          ) : (
            <IonLabel position="floating">Nome da Empresa</IonLabel>
          )}
          <IonInput
            placeholder="O seu nome"
            type="text"
            autoCapitalize="words"
            name="name"
            required
            onIonChange={changeHandler}
          ></IonInput>
        </IonItem>
        <IonItem>
          {segment === particular ? (
            <IonLabel position="floating">Morada</IonLabel>
          ) : (
            <IonLabel position="floating">Morada da Empresa</IonLabel>
          )}
          <IonInput
            placeholder="A sua morada"
            type="text"
            autoCapitalize="words"
            name="address"
            required
            onIonChange={changeHandler}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Telefone</IonLabel>
          <IonInput
            placeholder="O seu telefone"
            type="number"
            inputmode="numeric"
            name="phone"
            required
            onIonChange={changeHandler}
          ></IonInput>
        </IonItem>
        <IonItem>
          {segment === particular ? (
            <IonLabel position="floating">Telemóvel</IonLabel>
          ) : (
            <IonLabel position="floating">
              Telemóvel do Responsável pela Inscrição
            </IonLabel>
          )}
          <IonInput
            placeholder="O seu telemóvel"
            type="number"
            inputmode="numeric"
            name="mobile"
            required
            onIonChange={changeHandler}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonGrid>
            <IonRow>
              {segment === particular ? (
                <IonLabel>Data de nascimento</IonLabel>
              ) : (
                <IonLabel>Data da criação</IonLabel>
              )}
            </IonRow>
            <IonRow>
              <IonInput
                name="bdayDay"
                inputmode="numeric"
                autocomplete="bday-day"
                type="number"
                min="01"
                max="31"
                required
                placeholder="DD"
                onIonChange={changeHandler}
              ></IonInput>
              <IonInput
                name="bdayMonth"
                inputmode="numeric"
                autocomplete="bday-month"
                type="number"
                min="01"
                max="12"
                required
                placeholder="MM"
                onIonChange={changeHandler}
              ></IonInput>
              <IonInput
                name="bdayYear"
                inputmode="numeric"
                autocomplete="bday-year"
                type="number"
                min="1900"
                max="2022"
                required
                placeholder="AAAA"
                onIonChange={changeHandler}
              ></IonInput>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem>
          {segment === particular ? (
            <IonLabel position="floating">NIF</IonLabel>
          ) : (
            <IonLabel position="floating">NIF da Empresa</IonLabel>
          )}
          <IonInput
            placeholder="O seu NIF"
            type="number"
            inputmode="numeric"
            maxlength={9}
            name="ssn"
            required
            onIonChange={changeHandler}
          ></IonInput>
        </IonItem>
      </IonList>
      <IonButton
        className="form-btn"
        type="submit"
        fill="solid"
        expand="block"
        onClick={() => {
          submitUser();
        }}
      >
        Submeter
      </IonButton>
      <IonAlert
        isOpen={status.error}
        onDidDismiss={() => setStatus({ ...status, error: false })}
        header={"Algo correu mal"}
        message={message}
        buttons={["OK"]}
      />
      <IonLoading isOpen={status.loading} />
    </Fragment>
  );
};

export default SignUpForm;
