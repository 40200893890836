import {
  IonContent,
  IonImg,
  IonLoading,
  IonPage,
} from "@ionic/react";
import SignIn from "../../pages/App/signIn-signUp/SignIn-SignUp";
import "./signin-styles.scss";
import useAuth from "../../utils/hooks/useAuth";
import { useEffect, useState } from 'react';
import {  useHistory, useLocation } from 'react-router-dom';
import { axiosPrivate } from "../../utils/api/axios";

const LoginPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation<{ from: string }>(); // Explicitly type the location state
  const from =
    location.state?.from && typeof location.state.from === 'string'
      ? location.state.from
      : '/app/homepage';
  const history = useHistory();
  const [visible, setVisible] = useState("none");
  const { auth, setAuth } = useAuth();
  const REFRESH_URL = "/refresh"
  const LOGS_URL = "/create_log"

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const authUser = async () => {
      try {
        const response = await axiosPrivate.post(REFRESH_URL, {
          signal: controller.signal
        });
        setLoggedIn(true);
        setAuth((prev) => ({ ...prev, accessToken: response.data.access }));
        if (response.status < 300) {
          const logData = {
            username: '',
            log_type: 'LOGIN REFRESH',
            log_oper_status: 'SUCCESS',
            log_oper_descr: 'teste',
            log_obs: 'dummyObs',
          };
          const responseLogs = await axiosPrivate.post(LOGS_URL, logData, {
            headers: {
              Authorization: `Bearer ${response.data.access}`,
            },          
          });
          //console.log("Response status: ", response.status);
          history.push(from);
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        /*const logData = {
          username: 'Anonymous',
          log_type: 'LOGIN REFRESH',
          log_oper_status: 'ERROR',
          log_oper_descr: 'teste',
          log_obs: 'dummyObs',
        };
        const responseLogs = await post(LOGS_URL, logData, {
        });*/
      }
    }
    if (!loggedIn)
      authUser();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [auth]);

  const toggleVisible = () => {
    setVisible("block");
  };

  const toggleHide = () => {
    setVisible("none");
  };

  return (
    <IonPage>
      <div className="bg-white">
        <header className="absolute inset-x-0 top-0 z-50">
          <nav className="flex items-center justify-between p-6 lg:px-8 bg-white shadow-xl" aria-label="Global">
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Ai4Chef</span>
                <IonImg className="h-8 w-auto" alt="Ai4Chef" src={process.env.PUBLIC_URL + "icon-192x192.png"}/>
              </a>
            </div>
          </nav>
          <div className="lg:hidden" role="dialog" aria-modal="true" style={{ display: visible }} onClick={toggleHide}>
            <div className="fixed inset-0 z-50"></div>
            <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="" className="-m-1.5 p-1.5">
                  <span className="sr-only">Ai4Chef</span>
                  <IonImg className="h-8 w-auto" src={process.env.PUBLIC_URL + "icon-192x192.png"} alt="Ai4Chef" />
                </a>
                <button id="closeButton" type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700">
                  <span className="sr-only">Close menu</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <a href="" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Home</a>
                    <a href="/about" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Sobre</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <IonContent className="ion-padding sign-in-content">
          <SignIn />
        <IonLoading isOpen={loading} message="Redireccionando..." />
      </IonContent>
    </IonPage>
  );
};


export default LoginPage;