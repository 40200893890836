import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import { IonCol, IonContent, IonGrid, IonIcon, IonLoading, IonRow, IonSearchbar, IonText } from "@ionic/react";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import Loading from "../../reusable/loading/loading";
import Slider from 'react-input-slider';
import SelectComponent from "../../reusable/select/select";
import NWeeksFormatter from "../../reusable/select/nweeks";
import InfoComponent from "../../reusable/info/info.component";



const Pricing = () => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [nweeks, setNweeks] = useState<number>(4);
  const [pricings, setPricings] = useState([]);
  const [search, setSearch] = useState(true);

  const [state, setState] = useState({ x: 10, y: 10 });
  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    //setLoading(true);
    const getGraph = async (endpoint, params = {}) => {
      const url = params
        ? `pricing${endpoint}/?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `pricing${endpoint}`;

      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal
        });
        setPricings(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (search) {
      getGraph("", { nweeks, markup: state.x });
      setSearch(false);
    }

  }, [axiosPrivate, search]);

  const darkMode = useDarkMode();


  return (
    <IonContent className="ion-padding ">
      <IonGrid>
        <IonRow className="padding">
          <IonCol>
            <IonText className="welcome">Preço dos Pratos</IonText>
          </IonCol>
          <IonCol style={{ marginTop: "10px", display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <div className="flex justify-between gap-20 items-center">
              <SelectComponent
                formatter={NWeeksFormatter}
                darkMode={darkMode}
                onChange={setNweeks}
              />
              <div className="flex flex-col gap-2">
                <IonText style={{ color: darkMode ? '#f4f4f4' : "black" }}>Markup: {state.x}%</IonText>
                <Slider
                  axis="x"
                  x={state.x}
                  xmin={110}
                  xmax={500}
                  xstep={10}
                  onChange={({ x }) => setState(state => ({ ...state, x }))}
                />
              </div>
              <button onClick={() => setSearch(true)} className="bg-blue-dark text-white rounded-md px-4 py-2">Atualizar Pesquisa</button>
            </div>
          </IonCol>
        </IonRow>
        <IonRow  className="padding">
          <InfoComponent>
            <div>
              <p>(1) Custo máximo da dose no periodo de {nweeks} semanas</p>
              <p>(2) Custo médio da dose no periodo de {nweeks} semanas</p>
              <p>(3) Custo da dose no dia de hoje</p>
            </div>
          </InfoComponent>
        </IonRow>
        <div  className="padding" style={{ paddingBottom: '20px', paddingTop: "50px" }}>
          <div className="pb-5">
            <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
              <thead className="bg-blue-dark text-white rounded">
                <tr>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Nome do ingrediente
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Número de ingredientes
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Peso da receita(gr)
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Peso da Dose(gr)
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Custo máximo (1)
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Custo médio (2)
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Custo (3)
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Novo Preço
                  </th>
                </tr>
              </thead>
              <tbody
                style={{ backgroundColor: darkMode ? "#262853" : "" }}
                className="bg-white divide-y divide-gray-200"
              >
                {pricings["new_pricing"] && pricings["new_pricing"].map((item: any, index: any) => (
                  <tr key={index}>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {pricings["recipe_name"][index]}
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {pricings["num_ingr"][index]}
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {pricings["tt_peso"][index]}gr
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {pricings["tt_peso_dose"][index]}gr
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {pricings["v_max_dose"][index]}€
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {pricings["v_avg_fim_dose"][index]}€
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {pricings["v_day_dose"][index]}€
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {pricings["new_pricing"][index]}€
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </IonGrid>
      <Loading isOpen={loading} />
    </IonContent>


  );
}

export default Pricing;