import { useEffect, useState, useCallback } from 'react';
import {
  IonContent,
  IonGrid,
  IonRow,
  IonSearchbar,
  IonCol,
  IonText,
} from "@ionic/react";
import { useLocation } from 'react-router-dom';
import "../general.styles.scss";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";

import MyTable from "../reusable/table/table-component";
import SelectComponent from "../reusable/select/select";
import BackComponent from "../reusable/goBack/go-back-component";
import Loading from "../reusable/loading/loading";
import NWeeksFormatter from "../reusable/select/nweeks";
import Toaster from '../reusable/Toaster';

const INITIAL_WEEKS = 4;

const columns = [
  { headerName: 'Ingrediente', field: 'sku_prod_name' },
  { headerName: 'Delta', field: 'delta' },
  { headerName: 'Delta pct', field: 'delta_pct' },
  { headerName: 'Média', field: 'price_avg' },
  { headerName: 'Preço inicial', field: 'price_ini' },
  { headerName: 'Preço final', field: 'price_fim' },
  { headerName: 'Preço máximo', field: 'price_max' },
  { headerName: 'Preço mínimo', field: 'price_min' },
];

const FoodCostIngredients = () => {
  const { get } = useApi();
  const location = useLocation();
  const darkMode = useDarkMode();

  const item = new URLSearchParams(location.search).get('item');
  
  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchSkuData = useCallback(async () => {
    setLoading(true);
    try {
      const url = `dash3_list_sku/?nweeks=${nweeks}&ingr=${item}`;
      const response = await get(url);
      setRows(response.data);
    } catch (error) {
      Toaster.show("Erro ao carregar dados de SKU", "error");
      console.error("Error fetching SKU data:", error);
    } finally {
      setLoading(false);
    }
  }, [nweeks, item]);

  useEffect(() => {
    fetchSkuData();
  }, [fetchSkuData]);

  const filteredSku = rows.filter((recipe) =>
    recipe.sku_prod_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <IonContent className="ion-padding">
      <IonGrid>
        <IonRow className="padding" style={{ paddingBottom: '20px', paddingTop: '20px' }}>
          <IonCol>
            <IonText className="welcome">Evolução dos preços de aquisição de bens alimentares</IonText>
          </IonCol>
          <BackComponent />
          <IonCol style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <SelectComponent 
              formatter={NWeeksFormatter} 
              darkMode={darkMode} 
              onChange={setNweeks} 
            />
          </IonCol>
        </IonRow>
        <IonRow style={{ paddingBottom: '20px', paddingTop: "50px" }}>
          <div className="homePageText">
            <div style={{ color: darkMode ? '#f4f4f4' : "black" }} className="seeNews">
              Visão de produtos
            </div>
          </div>
        </IonRow>
      </IonGrid>
      <IonGrid className="padding">
        <div className="search">
          <IonSearchbar
            value={searchQuery}
            onIonChange={(e) => setSearchQuery(e.detail.value)}
            placeholder="Pesquisar por sku"
            style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }}
          />
        </div>
        <MyTable 
          rowData={filteredSku} 
          columnDefs={columns} 
          title={item} 
          darkMode={darkMode} 
        />
      </IonGrid>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default FoodCostIngredients;