import React, { useRef, useState } from "react";
import "./FileInput.css";
import { IonIcon } from "@ionic/react";
import { darkMode } from "../../../pages/Profile/Settings";
import { IoTrash } from "react-icons/io5";

const FileInput = ({ setFileArray, setFileName }) => {
  const inputRef = useRef();

  const getInfo = async (event) => {
    handleOnChange(event);
    const files = [...event.target.files].map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = function () {
          const fileData = reader.result; // The file data in its original binary form
          setFileArray([fileData]);
          setFileName([file.name]);
        };
        reader.readAsArrayBuffer(file);
      });
    });
    const res = await Promise.all(files);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);

  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const onChooseFile = () => {
    (inputRef.current as HTMLInputElement).click();
  };

  const removeFile = () => {
    setSelectedFile(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);

    const files = event.dataTransfer.files;
    
    if (files.length > 0) {
      setSelectedFile(files[0]);
      setFileArray([files[0]]);
      setFileName([files[0].name])
    }
  };

  return (
    <div
      className={`file-drop-zone ${dragging ? "dragging" : ""}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <input
        accept=".xml"
        type="file"
        ref={inputRef}
        onChange={(e) => getInfo(e)}
        style={{ display: "none" }}
      />

      <button
        className="file-btn"
        style={{
          color: darkMode ? "white" : "purple",
          border: darkMode ? "1.5px dashed white" : "1.5px dashed purple",
        }}
        onClick={onChooseFile}
      >
        <span
          style={{
            backgroundColor: darkMode ? "#422653" : "#E3C3DC",
            color: darkMode ? "white" : "purple",
          }}
          className="material-symbols-rounded"
        >
          Escolha ou arraste
        </span>{" "}
        Submeter Saft
      </button>

      {selectedFile && (
        <div className="selected-file">
          <p>{selectedFile.name}</p>

          <button onClick={removeFile}>
            <span className="material-symbols-rounded">
            <IoTrash size={32} className="text-red-500 text-2xl hover:scale-150 transition-transform duration-300"/>
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default FileInput;
