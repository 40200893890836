class ProviderFormatter {
  static provs = ['Pingo Doce', 'Continente', 'MiniPreço', 'MAKRO'];
  static default = 'Pingo Doce';

  static format(input: string): string {
    return input;
  }


  static getAll() {
    return this.provs;
  }
}
export default ProviderFormatter;
