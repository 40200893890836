import { useEffect } from "react";
import useAuth from "./useAuth";
import useAxiosPrivate from "./useAxiosPrivate";

const useTokenVerifier = ({ onTokenVerified, log }) => {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const AUTH_URL = "/auth/jwt/verify";
    const LOGS_URL = "/create_log";
    const verifyToken = async () => {
      try {
        const response = await axiosPrivate.post(
          AUTH_URL,
          {
            token: auth?.accessToken,
          },
          {
            signal: controller.signal,
          }
        );

        if (response.status < 300) {
          onTokenVerified();
          const logData = {
            username: auth?.user,
            log_type: log,
            log_oper_status: "SUCCESS",
            log_oper_descr: "teste",
            log_obs: "localhost",
          };
          const responseLogs = await axiosPrivate.post(LOGS_URL, logData);
        }
      } catch (error) {
        console.error("Error refreshing token (tokenVerifier): ", error);
        /*const logData = {
          username: 'Anonymous',
          log_type: { log },
          log_oper_status: 'TOKEN INVALID',
          log_oper_descr: 'teste',
          log_obs: 'dummyObs',
        };
        const responseLogs = await axiosPrivate.post(LOGS_URL, logData);*/
        //history.goBack();
        //window.location.reload();
      }
    };

    verifyToken();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
};

export default useTokenVerifier;
