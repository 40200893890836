import { Link, useHistory } from "react-router-dom";
import {
  IonItem,
  IonAlert,
  IonPopover,
  IonList,
  IonModal,
  IonGrid,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButton,
} from "@ionic/react";
import { useContext, useState } from "react";
import { axiosPrivate } from "../../../utils/api/axios";
import AuthContext from "../../../utils/contexts/AuthProvider";

import Loading from "../reusable/loading/loading";
import { darkMode } from "../../../pages/Profile/Settings";
import Toaster from "../reusable/Toaster";

const ProfileMenu = ({ setShowPopover, popoverEvent, showPopover }) => {
  const { setAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axiosPrivate.post("logout", {
        headers: { "Content-Type": "application/json" },
      });
      history.push("/login");
      window.location.reload();
    } catch (error) {
      console.error("Logout failed", error);
    }
    setLoading(false);
  };

  const handleSignOut = () => {
    setAuth(null);
    handleLogout();
  };

  const handleImport = async (bool) => {
    setLoading(true);
    let url;
    bool ? (url = `/import_ingreds/`) : (url = `/import_recipes/`);

    try {
      const response = await axiosPrivate.post(url);

      if (response.status === 200 || response.status === 201) {
        Toaster.show(
          bool
            ? "Ingredientes importados com sucesso."
            : "Receitas importadas com sucesso.",
          "success"
        );
        setLoading(false);
        closePopover();
      } else {
        Toaster.show(
          bool
            ? "Erro ao importar ingredientes. Por favor, tente mais tarde."
            : "Erro ao importar receitas. Por favor, tente mais tarde.",
          "error"
        );
        setLoading(false);
        closePopover();
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        bool
          ? "Erro ao importar ingredientes. Por favor, tente mais tarde."
          : "Erro ao importar receitas. Por favor, tente mais tarde.",
        "error"
      );
      setLoading(false);
      closePopover();
    }
  };

  const handleChange = async () => {

    if (newPassword !== confirmPassword) {
      Toaster.show("Passwords não coincidem", "error");
      return;
    }

    const data = {
      current_password: oldPassword,
      new_password: newPassword,
      re_new_password: confirmPassword,
    };

    try {
      const response = await axiosPrivate.post(
        "set_password",
        JSON.stringify(data)
      );


      if (response.status === 200) {
        Toaster.show("Password alterada com sucesso!", "success");
        setLoading(false);
        closePopover();
      } else {
        Toaster.show(response.data.message, "error");
        setLoading(false);
        closePopover();
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show("Erro ao alterar password. " + error.response.data.message, "error");
      setLoading(false);
      closePopover();
    }

    setShowModal(false);
  };

  const closePopover = () => setShowPopover(false);

  return (
    <IonPopover
      isOpen={showPopover}
      event={popoverEvent}
      onDidDismiss={closePopover}
    >
      <IonList>
        <IonItem button>
          <Link
            className={darkMode ? "text-white" : "text-black"}
            to="/app/profile"
          >
            Atualizar Restaurante/Perfil
          </Link>
        </IonItem>
        <IonItem button>
          <Link
            className={darkMode ? "text-white" : "text-black"}
            to="/app/menu/rest-prod-categories"
          >
            Escolher Classes - Menu
          </Link>
        </IonItem>
        <IonItem id="import_ingreds" button>
          <label>Importar Ingredientes - Menu</label>
        </IonItem>
        <IonItem id="import_recipes" button>
          <label>Importar Receitas - Menu</label>
        </IonItem>
        <IonItem button>
          <Link
            className={darkMode ? "text-white" : "text-black"}
            to="/app/menu/rest-select-prods"
          >
            Escolher Receitas - Menu
          </Link>
        </IonItem>
        <IonItem button>
          <Link
            className={darkMode ? "text-white" : "text-black"}
            to="/app/menu/rest-saft-rules"
          >
            Definir Filtros SAFT
          </Link>
        </IonItem>
        <IonItem button>
          <Link
            className={darkMode ? "text-white" : "text-black"}
            to="/app/tickets"
          >
            Tickets de suporte
          </Link>
        </IonItem>
        <IonItem button onClick={() => setShowModal(true)}>
          <label>Mudar Password</label>
        </IonItem>
        <IonItem
          button
          onClick={() => {
            handleSignOut();
          }}
        >
          <label>Terminar Sessão</label>
        </IonItem>
      </IonList>
      <IonAlert
        header="Atenção"
        trigger="import_ingreds"
        subHeader="Importar ingredientes"
        message={
          "Estará a importar ingredientes para o menu com respetivos skus associados. Deseja continuar?"
        }
        buttons={[
          {
            text: "Cancelar",
            role: "cancel",
            handler: () => {
              closePopover();
            },
          },
          {
            text: "Importar",
            role: "import",
            handler: () => {
              handleImport(true);
            },
          },
        ]}
        onDidDismiss={({ detail }) =>
          console.log(`Dismissed with role: ${detail.role}`)
        }
      ></IonAlert>
      <IonAlert
        header="Atenção"
        trigger="import_recipes"
        subHeader="Importar receitas"
        message={"Estará a importar receitas para o menu. Deseja continuar?"}
        buttons={[
          {
            text: "Cancelar",
            role: "cancel",
            handler: () => {
              closePopover();
            },
          },
          {
            text: "Importar",
            role: "import",
            handler: () => {
              handleImport(false);
            },
          },
        ]}
        onDidDismiss={({ detail }) =>
          console.log(`Dismissed with role: ${detail.role}`)
        }
      ></IonAlert>
      <Loading isOpen={loading} />
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Change Password</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid style={{ height: "100%", padding: "20px" }}>
            <div className="flex flex-col p-4 gap-2">
              <label>Password Atual</label>
              <input
                className="bg-transparent"
                onChange={(e) => setOldPassword(e.target.value)}
                type="text"
                placeholder="Insira Password Atual"
              ></input>
            </div>
            <div className="flex flex-col p-4 gap-2">
              <label>Nova Password</label>
              <input
                className="bg-transparent"
                onChange={(e) => setNewPassword(e.target.value)}
                type="text"
                placeholder="Insira Nova Password"
              ></input>
            </div>
            <div className="flex flex-col p-4 gap-2">
              <label>Confirme a nova Password</label>
              <input
                className="bg-transparent"
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="text"
                placeholder="Confirme a nova Password"
              ></input>
            </div>
            <IonButton
              className="p-10"
              expand="block"
              onClick={() => {
                handleChange();
              }}
            >
              Change Password
            </IonButton>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPopover>
  );
};

export default ProfileMenu;
