



import Page from "../page";
import SaftFilter from "../../../components/App/user-setup/saft-rest";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../components/App/reusable/loading/loading";
import { MdCheckBox } from "react-icons/md";




const SaftRestaurantPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'SAFT FILTER PAGE',
  });
  return (
      <Page title={"Filtrar Saft"} icon={<MdCheckBox size={32} />}>
      {tokenVerified ? (<SaftFilter />) : <Loading isOpen={true} />}
      </Page>
  );
};

export default SaftRestaurantPage;

