import {
  IonContent,
  IonButton,
  IonIcon,
  IonGrid,
  IonCol,
  IonRow,
  IonLoading,
  IonText,
  IonModal,
  IonInput,
  IonTextarea,
  IonToast,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  InputChangeEventDetail,
} from "@ionic/react";
import { useEffect, useState } from "react";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import "../general.styles.scss";
import MyTable from "../reusable/table/table-component";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import axios from "axios";
import FileInput from "./fileInput";

import Loading from "../reusable/loading/loading";
import { IoAddCircle } from "react-icons/io5";
import Toaster from "../reusable/Toaster";

const UploadSAFTForm = () => {
  const axiosPrivate = useAxiosPrivate();
  const darkMode = useDarkMode();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const [fileArray, setFileArray] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [form, setForm] = useState({
    name: "",
    description: "",
  });


  const [uploads, setUploads] = useState([]);
  const uploadsList = "upload_list";
  const generateUploadURL = "generate_upload_url/";
  const loadDataURL = "loadSaftData/";


  function formatDataItem(item) {
    const date = new Date(item.zdata);
    const formattedDate = date.toLocaleDateString('pt-PT', { 
      hour: 'numeric', 
      minute: 'numeric' 
    });
  
    return {
      ...item,
      zdata: formattedDate,
    };
  }



  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getGraph = async (endpoint) => {
      try {
        const url = `${endpoint}`;

        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        console.log(response.data);
        const dataFormatted = response.data.map(formatDataItem);
        setUploads(dataFormatted);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    const fetchData = () => {
      getGraph(uploadsList);
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [ change]);


  const handleUpload = async () => {
    submitFile();
    //setLoading(true);
    setForm({
      name: "",
      description: "",
    });
  };


  const [columns, setColumns] = useState([
    { headerName: 'Utilizador', field: 'username' },
    { headerName: 'Nome', field: 'upload_name' },
    { headerName: 'Descrição', field: 'upload_descr' },
    { headerName: 'Data do Upload', field: 'zdata' },
    { headerName: 'Ficheiro', field: 'upload_fn_orig' },
    { headerName: 'Estado', field: 'state' },

  ]);



  async function submitFile() {
    try {
      //setStatus({ loading: true, error: false });
      setLoading(true)
      if (
        form.name === "" ||
        form.description === "" ||
        fileName.length === 0
      ) {
        Toaster.show("Por favor preencha o campo em falta.", "error");
        throw new Error("blank-form");
      }
      // console.log(fileName[0]);
      // console.log(form.name);
      // console.log(form.description);
      const response = await axiosPrivate.post(generateUploadURL,
        JSON.stringify({
          filename: fileName[0],
          name: form.name,
          description: form.description
        }),
      );

      if (response.status < 200 || response.status >= 300) {

        throw new Error(response.statusText);
      }
      const result = response.data;
      const uploadUrl = result.upload_url;
      const upload = await axios.put(uploadUrl,
        fileArray[0],
        {
          headers: {
            ContentType: 'application/xml',
            'x-ms-blob-type': `BlockBlob`,
          }
        }
      );
      console.log("upload status: ", upload.status);
      if (upload.status < 200 || upload.status >= 300) {
        setLoading(false);
        throw new Error(upload.statusText);
      } else {
        const blob_path = result.blob_path;
        const loadData = axiosPrivate.post(loadDataURL,
          JSON.stringify({
            blob_path: blob_path,
          }),
        );
      }
      //setStatus({ loading: false, error: false });
      setLoading(false);
      setShowModal(false);
      setChange(!change);
      Toaster.show("Ficheiro submetido com sucesso.", "success");
      return result;
    } catch (error) {
      switch (error.message) {
        case "blank-form":
          break;
        default:
          //Toaster.show("Ocorreu um erro inesperado. Por favor tente novamente.");
          console.log(error.message);
      }
      //setStatus({ loading: false, error: true });
      setLoading(false);
      if (error instanceof Error) {
        console.log("error message: ", error.message);
        console.log("error stack: ", error.stack)
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  return (

    <DarkModeProvider>

      <IonContent className="ion-padding">
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} showBackdrop={false}>
          <div className="container" style={{ height: "100%", display: "flex", justifyContent: "center" }}>
            <div>
              <div style={{display: "flex", justifyContent: "center"}}>
                <div>
                  <h2 style={{ color: darkMode ? "white" : "black" }}>Submeter ficheiro Saft</h2>
                  <FileInput setFileArray={setFileArray} setFileName={setFileName} />
                </div>
              </div>
              <IonCard>
                <IonList >
                  <IonItem>
                    <IonInput
                      slot="end"
                      name="name"
                      value={form.name}
                      label="Nome"
                      placeholder="Nome da submissão"
                      onIonChange={(e) => setForm({ ...form, name: e.detail.value })}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonInput
                      placeholder="Descrição da submissão"
                      slot="end"
                      label="Descrição"
                      name="description"
                      value={form.description}
                      onIonChange={(e) => setForm({ ...form, description: e.detail.value })}
                    ></IonInput>
                  </IonItem>
                </IonList>
              </IonCard>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <IonButton style={{ marginTop: "30px" }} onClick={handleUpload}>Submeter</IonButton>
                  <IonButton style={{ marginTop: "30px" }} onClick={() => setShowModal(false)}>Cancelar</IonButton>
                </div>
              </div>
            </div>
          </div>
        </IonModal>
        <IonRow className="paddingButton">
          <IonButton onClick={() => setShowModal(true)}><IoAddCircle size={32}/></IonButton>
        </IonRow>
        <IonGrid>
          <div className="homePageText">
            <div className="welcome" style={{ color: darkMode ? '#f4f4f4' : "black", paddingLeft: "3rem" }}>Uploads de ficheiro SAFT - Dados de vendas</div>
          </div>
          <IonGrid>
            <IonRow style={{ paddingBottom: '70px', paddingLeft: '50px', paddingRight: '50px' }}>
              <IonCol>
                {!loading && uploads.length === 0
                  ? <IonText style={{ color: darkMode ? "white" : "black" }}>Os seus uploads aparecerão aqui. Faça um upload agora!</IonText>
                  : <MyTable rowData={uploads} columnDefs={columns} title={"Uploads"} darkMode={darkMode} />}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonGrid>
        <Loading isOpen={loading} />

      </IonContent>
    </DarkModeProvider>

  );
};



export default UploadSAFTForm;
