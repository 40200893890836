
import GeneralGraphs from "../../../components/App/market/general-graphs";
import "./HomeStyles.scss";
import Page from "../page";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../components/App/reusable/loading/loading";
import { FaList } from "react-icons/fa";

const General = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'GENERAL GRAPHS PAGE',
  });
  return (
    <Page title={"Preços - Visão Geral"} icon={<FaList size={32}/>} >
      {tokenVerified ? (<GeneralGraphs />) : <Loading isOpen={true} />}
    </Page>
  );
};

export default General;
