// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 800px) {
  .change-pwd-title {
    text-align: center;
    margin-right: 7rem;
  }
  .change-pwd-card {
    width: 50vw;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 2rem;
  }
  .change-pwd-card .change-pwd-btn {
    padding: 0 12rem;
    margin-bottom: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Profile/change-password.styles.scss"],"names":[],"mappings":"AAIA;EACE;IACE,kBAAA;IACA,kBAAA;EAHF;EAMA;IACE,WAAA;IACA,SAAA;IACA,6BAAA;IACA,gBAAA;EAJF;EAME;IACE,gBAAA;IACA,mBAAA;EAJJ;AACF","sourcesContent":[".change-pwd-content {\n  //--background: radial-gradient(circle at 50% 0%, #f1f1f1, #c9c9c9, #999);\n}\n\n@media (min-width: 800px) {\n  .change-pwd-title {\n    text-align: center;\n    margin-right: 7rem;\n  }\n\n  .change-pwd-card {\n    width: 50vw;\n    left: 50%;\n    transform: translate(-50%, 0);\n    margin-top: 2rem;\n\n    .change-pwd-btn {\n      padding: 0 12rem;\n      margin-bottom: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
