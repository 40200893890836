import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import { IonGrid,   IonRow, IonSearchbar } from "@ionic/react";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import toast from 'react-hot-toast';
import InfoComponent from "../../reusable/info/info.component";
import Loading from "../../reusable/loading/loading";
import Paginate from "../../reusable/pagination/paginate";
import { FaSearch} from "react-icons/fa";
import Toaster from "../../reusable/Toaster";



const IngredCreate = ({ assign_id = 0, modalCloser, setChange, change, recipeName }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipeId = queryParams.get('id');
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(recipeName.split(" ")[0]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("g");
  const [loading, loadingSetter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);

  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();
    const getGraph = async (endpoint, params = {}) => {
      loadingSetter(true);
      const url = params
        ? `${endpoint}/?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `${endpoint}`;
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal
        });


        if (!response.data.message) {
          toast('A sua pesquisa não retornou resultados. Sugerimos as abaixo.', {
            icon: <FaSearch style={{ color: darkMode ? "white" : "black", fontSize: '50px' }} />,
          });
        }

        if(response.data.results.data){
          setShow(true);
        }

        setData(response.data.results.data);
        
        setTotalPages(response.data.results.total_pages);
        loadingSetter(false);
      } catch (error) {
        loadingSetter(false);
        console.error(error);
      }
    };
      getGraph("ingred_list", { search: search, page: currentPage, recipeName: recipeName });


    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [search]);

  const handleKeyUp = (e) => {

    if (e.key === 'Enter') {
      loadingSetter(true);
      if (e.target.value === "") {
        loadingSetter(false);
        return;
      }
      setSearch(e.target.value);
    }
  };

  const handleCheckboxChange = (ingr) => {
    setSelectedIngredients((prevSelectedIngredients) => {
      if (prevSelectedIngredients.find((item) => item.id === ingr.ingr_id)) {
        return prevSelectedIngredients.filter((item) => item.id !== ingr.ingr_id);
      } else {
        return [...prevSelectedIngredients, { id: ingr.ingr_id, quantity: 0, order: ingr.ingr_order }];
      }
    });
  };

  const handleQuantityChange = (ingr_id, quantity) => {
    setSelectedIngredients((prevSelectedIngredients) => {
      return prevSelectedIngredients.map((item) =>
        item.id === ingr_id ? { ...item, quantity } : item
      );
    });
  };

  const handleAssign = async () => {
    loadingSetter(true);
    if (selectedIngredients.length === 0) {
      Toaster.show('Nenhum ingrediente selecionado', "error")
      loadingSetter(false);
      return;
    }
    for (const ingredient of selectedIngredients) {
      const ingredToAssign = {
        assign_id: assign_id,
        recipe: recipeId,
        ingr: ingredient.id,
        ingr_qt: ingredient.quantity,
        ingr_qt_unit: selectedUnit, // Provide a value for this field
        ingr_order: ingredient.order,
      };
      try {
        const response = await axiosPrivate.post(`/assign_create_or_update/`,
          JSON.stringify(ingredToAssign),
        );

        if (response.status === 200 || response.status === 201) {
          Toaster.show('Associação criada com sucesso.', "success")
          setChange(!change);

        } else {
          Toaster.show('Erro ao criar associação.', "error")
          loadingSetter(false);
          break;
        }
      } catch (error) {
        console.error("Error:", error);
        Toaster.show('Erro ao criar associação.', "error")
        loadingSetter(false);
      }
    }
    setChange(!change);

  };
  const handleUnitChange = (newUnit) => {
    setSelectedUnit(newUnit);
  };
  const darkMode = useDarkMode();

  return (
    <><IonGrid>
      <IonRow>
        <div style={{ width: "100%" }} className={`search ${darkMode ? 'dark' : 'light'}`}>
          <IonSearchbar
            value={search}
            onKeyDown={handleKeyUp}
            placeholder="Pesquisar por ingrediente"
            style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }} />
        </div>
      </IonRow>

      <InfoComponent text="">
        <p>Pesquise e associe ingredientes à sua receita. Forneça informação relativamente às quantidades utilizadas na confeção da receita.</p>
      </InfoComponent>

      {data.length >0 && show && (
        <IonRow style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
            <thead className='bg-blue-dark text-white rounded'>
              <tr>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Nome</th>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Classe</th>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Select</th>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Quantidade</th>
                <th style={{ background: darkMode ? "#422655" : '' }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">Unidade</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: darkMode ? '#262853' : '' }} className="bg-white divide-y divide-gray-200">
              {data.map((ingredient) => (
                <tr key={ingredient.ingr_id}>
                  <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                    {ingredient.ingr_name}
                  </td>
                  <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                    {ingredient.ingr_class}
                  </td>
                  <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
                    <input
                      type="checkbox"
                      checked={selectedIngredients.find((item) => item.id === ingredient.ingr_id) !== undefined}
                      onChange={() => handleCheckboxChange(ingredient)} />
                  </td>
                  <td style={{ color: darkMode ? "white" : "black" }} className="py-2 whitespace-no-wrap text-gray-600">
                    {selectedIngredients.find((item) => item.id === ingredient.ingr_id) !== undefined && (
                      <input
                        placeholder="Quantidade"
                        style={{ background: "transparent", maxWidth: "100px" }}
                        type="number"
                        value={selectedIngredients.find((item) => item.id === ingredient.ingr_id)?.quantity || ""}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          handleQuantityChange(ingredient.ingr_id, value);
                        }
                        } />
                    )}
                  </td>
                  <td style={{ color: darkMode ? "white" : "black" }} className="py-2 whitespace-no-wrap text-gray-600">
                    {selectedIngredients.find((item) => item.id === ingredient.ingr_id) !== undefined && (
                      <select
                        style={{ background: "transparent", maxWidth: "100px" }}
                        value={selectedUnit}
                        onChange={(e) => handleUnitChange(e.target.value)}
                      >
                        <option value="g">Gramas</option>
                        <option value="ml">Mililitros</option>
                      </select>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </IonRow>
      )}
     {(data.length >= 20 || currentPage !== 1) && <div style={{ display: "flex", justifyContent: "center", paddingBottom: "50px" }}>
        <Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} loading={!data} />
      </div>}
      <IonRow>
        <button
          type="submit"
          onClick={() => { modalCloser(false); handleAssign(); }}
          className="bg-blue-dark w-full p-5 hover:bg-blue-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
        >
          Guardar Associação
        </button>
      </IonRow>
    </IonGrid>
      <Loading isOpen={loading} /></>

  );
}

export default IngredCreate;