import Page from "../../page";
import PricingComponent from "../../../../components/App/menu/pricing/pricing";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useState } from "react";
import Loading from "../../../../components/App/reusable/loading/loading";
import { IoRestaurant } from "react-icons/io5";

const Pricing = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU PRICING PAGE",
  });

  return (
    <Page title={"Preço dos Pratos"} icon={<IoRestaurant size={32} />}>
      {tokenVerified ? <PricingComponent /> : <Loading isOpen={true} />}
    </Page>
  );
};

export default Pricing;
