class ProdsFormatter {
    static prods = [3, 5, 10];
    static default = 3;
  
    static format(input) {
      if (this.prods.includes(input)) {
        return `top ${input} produtos`;
      } else {
        return null; // Return null or any other default value if the input is not valid
      }
    }
  
    static getAll() {
      return this.prods;
    }
  
  }
  
  export default ProdsFormatter;