import React from "react";
import { Link, useHistory } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import "./back.styles.scss";
import useDarkMode from "../../../../pages/Profile/usedarkmode";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const BackComponent = () => {
  const darkMode = useDarkMode();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };
  return (
    <div>
      <IoArrowBackCircleOutline
        size={32}
        onClick={goBack}
        className="goBackIcon"
        style={{ color: darkMode ? "white" : "black" }}
      />
    </div>
  );
};

export default BackComponent;
