import React, { useState, useEffect, useRef } from 'react';
import { IonContent, IonGrid, IonCol, IonRow, IonLoading, IonSearchbar } from '@ionic/react';
import useDarkMode from '../../../pages/Profile/usedarkmode';
import { useApi } from '../../../utils/hooks/useAxiosPrivate';
import ChartComponent from '../reusable/charts/chartComponent';
import MyTable from '../reusable/table/table-component';
import SelectComponent from '../reusable/select/select';
import NWeeksFormatter from '../reusable/select/nweeks';
import KpiFormatter from '../reusable/select/kpi';
import Loading from '../reusable/loading/loading';
import CardComponent from '../reusable/cardComponent';
import InfoComponent from '../reusable/info/info.component';
import NWeeksHelp from '../reusable/NWeeksHelp';

type ChartOptions = Record<string, any>;

const FoodCostGraphs = () => {
  const { get } = useApi();
  const [loading, setLoading] = useState(true);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [nweeks, setNweeks] = useState<number>(4);
  const [kpi, setKpi] = useState('quantity');
  const chartRef = useRef(null);
  const [graphData, setGraphData] = useState<Record<string, ChartOptions>>({
    q11: {}, q12: {}, q21: {}, q22: {}, q31: {}, q32: {}, q41: {}, q42: {}, q52: {},
  });

  const [kpis, setKpis] = useState({ absolute: [0, 0, 0, 0], variation: [0, 0, 0, 0] });
  const [lastDate, setLastDate] = useState(null);
  const [columns, setColumns] = useState([
    { headerName: 'Descrição', field: 'rest_prod_desc' },
    { headerName: 'Unidades', field: 'units' },
    { headerName: 'Total de vendas', field: 'total_sales' },
    { headerName: '%', field: 'pct_sales' },
    { headerName: '% Acumulada', field: 'pct_cumulative' },
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const darkMode = useDarkMode();

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  useEffect(() => {
    const controller = new AbortController();

    const fetchGraphData = async () => {
      try {
        const promises = [
          get(`dash4_cp_q01/?nweeks=${nweeks}`),
          get(`dash4_cp_q02/?nweeks=${nweeks}`),
          get(`dash4_cp_q11/?nweeks=${nweeks}&kpi=${kpi}`),
          get('dash4_sales_last_date'),
          get(`dash4_cp_q21/?nweeks=${nweeks}&kpi=${kpi}`),
          get(`dash4_cp_q22/?nweeks=${nweeks}&kpi=${kpi}`),
          get(`dash4_cp_q31/?nweeks=${nweeks}`),
          get(`dash4_cp_q32/?nweeks=${nweeks}`),
          get(`dash4_cp_q41/?nweeks=${nweeks}&kpi=${kpi}`),
          get(`dash4_cp_q42/?nweeks=${nweeks}&kpi=${kpi}`),
          get(`dash4_cp_q52/?nweeks=${nweeks}`),
        ];

        const responses = await Promise.all(promises);
        const [
          q01Data,
          q02Data,
          q11Data,
          lastDateData,
          q21Data,
          q22Data,
          q31Data,
          q32Data,
          q41Data,
          q42Data,
          q52Data,
        ] = responses;

        setGraphData({
          q01: q01Data.data,
          q02: q02Data.data,
          q11: q11Data.data,
          q21: q21Data.data,
          q22: q22Data.data,
          q31: q31Data.data,
          q32: q32Data.data,
          q41: q41Data.data,
          q42: q42Data.data,
          q52: q52Data.data,
        });
        setLastDate(lastDateData.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const fetchKpiData = async () => {
      try {
        const response = await get(`get_sales_kpi/?nweeks=${nweeks}`);
        setKpis(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (nweeks) {
      fetchGraphData();
      fetchKpiData();
    }

    return () => {
      controller.abort();
    };
  }, [nweeks, kpi]);

  const kpiDescription = {
    quantity: 'Items vendidos representa o número de produtos individuais vendidos pelo restaurante',
    total_sales: 'Total de vendas representa o valor total de vendas gerado pelo restaurante',
    numero_faturas: 'Número de faturas representa o número total de faturas emitidas pelo restaurante',
  };



  useEffect(() => {
    if (graphData.q52 && searchQuery) {
      const filtered = graphData.q52?.filter((recipe) =>
        recipe.rest_prod_desc.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [graphData.q52, searchQuery]);

  return (
    <IonContent className="ion-padding">
      <div className="px-14 py-4 flex items-center">
        <div className="w-[30%]">
          <SelectComponent formatter={NWeeksFormatter} darkMode={darkMode} onChange={setNweeks} />
        </div>
        <div className="w-[70%]">{lastDate && <NWeeksHelp nweeks={nweeks} dateLastSales={lastDate} />}</div>
      </div>

      {kpis && (
        <div className="px-16">
          <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
            <CardComponent
              title={'Total de Vendas'}
              value={`${kpis['absolute'][1]}€`}
              variation={kpis['variation'][1]}
            />
            <CardComponent
              title={'Nº Total de Faturas'}
              value={`${kpis['absolute'][0]}`}
              variation={kpis['variation'][0]}
            />
            <CardComponent
              title={'Valor médio p/Fatura'}
              value={`${kpis['absolute'][3]}€`}
              variation={kpis['variation'][3]}
            />
            <CardComponent
              title={'Itens vendidos'}
              value={`${kpis['absolute'][2]}`}
              variation={kpis['variation'][2]}
            />
          </div>
        </div>
      )}

      <div className='padding'>
        <div className="grid xl:grid-cols-2 gap-4">
          <ChartComponent
            chartRef={chartRef}
            option={graphData.q01}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />

          <ChartComponent
            chartRef={chartRef}
            option={graphData.q02}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />

          <div className="py-4 flex items-center xl:col-span-2">
            <div className="w-[30%]">
              <SelectComponent formatter={KpiFormatter} darkMode={darkMode} onChange={setKpi} />
            </div>
            <div className="w-[70%]">
              <InfoComponent>
                <div>
                  <p>{kpiDescription[kpi]}</p>
                </div>
              </InfoComponent>
            </div>
          </div>

          <ChartComponent
            chartRef={chartRef}
            option={graphData.q21}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />

          <ChartComponent
            chartRef={chartRef}
            option={graphData.q22}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />

          <ChartComponent
            chartRef={chartRef}
            option={graphData.q41}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />

          <ChartComponent
            chartRef={chartRef}
            option={graphData.q42}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />

          <ChartComponent
            chartRef={chartRef}
            option={graphData.q31}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />

          <ChartComponent
            chartRef={chartRef}
            option={graphData.q32}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />
        </div>
        <div className="py-10 w-full">
          <IonSearchbar
            value={searchQuery}
            onIonChange={(e) => setSearchQuery(e.detail.value)}
            placeholder="Pesquisar por receita"
            style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }}
          />
          <div className='w-full'>
          <MyTable
            rowData={filteredData.length > 0 ? filteredData : graphData.q52}
            columnDefs={columns}
            darkMode={darkMode}
            title="Ranking de receitas geradas por cada produto à venda"
          />
          </div>
        </div>
      </div>

      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default FoodCostGraphs;