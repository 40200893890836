class RangeFormatter {
  static ranges = ['<= 1€', '> 1€ <= 5€', '> 5€ <= 25€', '> 25€'];
  static default = '<= 1€';
  static format(input: string): string {
    return input;
  }

  static getAll() {
    return this.ranges;
  }

  static rangeToCategory(priceRange: string) {
    switch (priceRange) {
      case '<= 1€':
        return 'A';
      case '> 1€ <= 5€':
        return 'B';
      case '> 5€ <= 25€':
        return 'C';
      case '> 25€':
        return 'D';
      default:
        return '';
    }
  }
}

export default RangeFormatter;
