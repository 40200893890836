// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myModal {
  align-items: center;
  justify-content: center;
}
.myModal::part(content) {
  width: 60%;
  height: 75%;
}`, "",{"version":3,"sources":["webpack://./src/components/App/menu/ingredients/ingreds.styles.scss"],"names":[],"mappings":"AAGE;EACE,mBAAA;EACA,uBAAA;AAFJ;AAGI;EACE,UAAA;EACA,WAAA;AADN","sourcesContent":["\n\n\n  .myModal{\n    align-items: center;\n    justify-content: center; \n    &::part(content){\n      width: 60%;\n      height: 75%; \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
