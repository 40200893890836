import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";

import { useState, useEffect } from "react";
import { IonAlert, IonContent, IonModal, IonText } from "@ionic/react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import IngredCreate from "../ingredients/ingred-create";
import IngredEdit from "../ingredients/ingred-edit";
import useDarkMode from "../../../../pages/Profile/usedarkmode";

import BackComponent from "../../reusable/goBack/go-back-component";
import InfoComponent from "../../reusable/info/info.component";
import Loading from "../../reusable/loading/loading";
import Toaster from "../../reusable/Toaster";

const RecipeDetail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipeId = queryParams.get("id");
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [modalEdit, setModalVisibleEdit] = useState(false);
  const [editable, setEditable] = useState(0);
  const [assignId, setAssignID] = useState(0);
  const [changedList, setChangedList] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(-1);
  const [quantity, setQuantity] = useState(0);
  const [quantityType, setQuantityType] = useState("gr");

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getGraph = async (endpoint, params = {}) => {
      const url = params
        ? `${endpoint}?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : `${endpoint}`;

      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        eval(`setData(response.data)`);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    getGraph("assign_list", { recipe_id: recipeId });
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);

  const deleteIngred = async (ingr_id: Number) => {
    setLoading(true);
    const body = {
      ingr_id: ingr_id,
      recipe_id: recipeId,
    };
    const response = await axiosPrivate.post(
      `/assign_delete/`,
      JSON.stringify(body)
    );
    if (response.status === 200) {
      Toaster.show("Ingrediente apagado com sucesso.",  "success");
      setLoading(false);
      setChangedList(!changedList);
    } else {
      setLoading(false);
      Toaster.show("Erro ao apagar ingrediente.", "error");
    }
  };
  const darkMode = useDarkMode();

  return (
    <IonContent>
      {data && (
        <div
          style={{ paddingBottom: "50px" }}
          className="w-[60%] mx-auto text-black"
        >
          <BackComponent></BackComponent>
          <div className="flex items-center mt-10">
            <IonText
              style={{ color: darkMode ? "white" : "black" }}
              className="text-4xl font-semibold text-blue-dark"
            >
              {data["recipes"][0].recipe_name} {data["recipes"][0].recipe_class}
            </IonText>
            <button
              onClick={() => setModalVisibleCreate(true)}
              style={{ background: darkMode ? "#422655" : "" }}
              className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
            >
              + Associar Ingrediente
            </button>
          </div>

          <InfoComponent text="">
            <p>
              Lista de ingredientes associados à receita{" "}
              {data["recipes"][0].recipe_name}. Receita criada a{" "}
              {formatDate(data["recipes"][0].ztimestamp)}.
            </p>
          </InfoComponent>

          {data && data["ingreds"].length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
              <thead className="bg-blue-dark text-white rounded">
                <tr>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Nome
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Classe
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Quantidade
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody
                style={{ backgroundColor: darkMode ? "#262853" : "" }}
                className="bg-white divide-y divide-gray-200"
              >
                {data["ingreds"].map((item: any, index: any) => (
                  <tr key={index}>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {item.ingr_name}
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {item.ingr_category}
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {data["qnt"][index].ingr_qt}{" "}
                      {data["qnt"][index].ingr_qt_unit}
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      <div className="flex space-x-4">
                        <Link
                          className="hover:scale-125 transition-transform duration-30"
                          to={`/app/menu/ingredient?id=${item.ingr_id}`}
                        >
                          <IoIosLink
                            title="Ver Ingrediente"
                            style={{ color: darkMode ? "white" : "black" }}
                            className="text-blue-dark text-2xl"
                          />
                        </Link>
                        <button
                          className="hover:scale-125 transition-transform duration-30"
                          onClick={() => {
                            setModalVisibleEdit(true);
                            setEditable(item);
                            setAssignID(data["qnt"][index].assign_ingr_id);
                            setQuantity(data["qnt"][index].ingr_qt);
                            setQuantityType(data["qnt"][index].ingr_qt_unit);
                          }}
                        >
                          <FaPencilAlt
                            title="Editar"
                            className="text-blue-500 text-2xl"
                          />
                        </button>
                        <button
                          onClick={() => setIsOpenDelete(item.ingr_id)}
                          className="hover:scale-125 transition-transform duration-30"
                        >
                          <FaTrash
                            title="Eliminar"
                            className="text-red-500 text-2xl"
                          />
                        </button>
                        <IonAlert
                          header="Atenção"
                          isOpen={
                            isOpenDelete !== -1 && isOpenDelete === item.ingr_id
                          }
                          subHeader="Eliminar item."
                          message={`Estará a eliminar o item ${item.ingr_name}. Deseja continuar?`}
                          buttons={[
                            {
                              text: "Cancelar",
                              role: "cancelar",
                              handler: () => {
                              },
                            },
                            {
                              text: "OK",
                              role: "confirmar",
                              handler: () => {
                                deleteIngred(item.ingr_id);
                              },
                            },
                          ]}
                          onDidDismiss={() => setIsOpenDelete(-1)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              style={{ color: darkMode ? "white" : "black" }}
              className="text-center text-2xl text-blue-dark"
            >
              Não existem ingredientes associados a esta receita.
            </div>
          )}
        </div>
      )}
      <IonModal
        isOpen={modalCreate}
        onDidDismiss={() => setModalVisibleCreate(false)}
        showBackdrop={false}
      >
        <div style={{ overflowY: "auto", overflowX: "hidden" }}>
          {data && (
            <IngredCreate
              modalCloser={setModalVisibleCreate}
              setChange={setChangedList}
              change={changedList}
              recipeName={data["recipes"][0].recipe_name}
            />
          )}
        </div>
      </IonModal>
      <IonModal
        isOpen={modalEdit}
        onDidDismiss={() => setModalVisibleEdit(false)}
        showBackdrop={false}
      >
        <div
          style={{
            padding: "50px 20px",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <IonText
            style={{ color: darkMode ? "white" : "black" }}
            className="text-4xl font-semibold text-blue-dark"
          >
            <p style={{ textAlign: "center" }}>
              Edite a quantidade ou unidade do ingrediente
            </p>
          </IonText>
          <IngredEdit
            qntType={quantityType}
            quantity={quantity}
            assignId={assignId}
            ingred={editable}
            modalCloser={setModalVisibleEdit}
            setChange={setChangedList}
            change={changedList}
          />
        </div>
      </IonModal>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default RecipeDetail;
