import { IonContent, IonGrid, IonCol, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import "../general.styles.scss";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import News from "../reusable/news/news";
import Loading from "../reusable/loading/loading";
import CardComponent from "../reusable/cardComponent";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const { get } = useApi();
  // Dashboard
  const [displayedText, setDisplayedText] = useState("");
  const [nProducts, setNProducts] = useState(0);
  const [nRecipes, setNRecipes] = useState(0);
  const [nPrices, setNPrices] = useState([0, 0]);
  const [nNewProducts, setNNewProducts] = useState([0, 0]);
  const [gptText, setGptText] = useState("");

  // ChatGPT
  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (gptText && index < gptText.length) {
        setDisplayedText((prevText) => prevText + gptText.charAt(index));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [gptText]);

  // Dashboard
  useEffect(() => {
    const controller = new AbortController();

    const getKPI = async (endpoint: string) => {
      setLoading(true);
      try {
        const response = await  get(endpoint, {
          signal: controller.signal,
        });
        setNProducts(response.data.totalProducts);
        setNRecipes(response.data.totalRecipes);
        setNPrices(response.data.collectedPrices);
        setNNewProducts(response.data.newProducts);
        setGptText(response.data.gptSummary);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getKPI("home_dashboard");

    return () => {
      controller.abort();
    };
  }, []);

  const darkMode = useDarkMode();

  return (
    <IonContent>
      <div className="padding">
        {!loading && (
          <IonGrid>
            <IonGrid>
              <IonRow className="pb-10">
                <IonCol size="12" size-xl="12">
                  <News darkMode={darkMode} />
                </IonCol>
                <IonGrid className="mt-5">
                  <IonRow>
                    <IonCol size="6">
                      <IonRow>
                        <IonCol size="6">
                          {nPrices && <CardComponent
                            title={"Preços recolhidos últimas 24h"}
                            value={`${nPrices[0]}`}
                            variation={nPrices[1]}
                          ></CardComponent>}
                          {nProducts && <CardComponent
                            title={"Produtos"}
                            value={`${nProducts}`}
                            showVariation={false}
                          ></CardComponent>}
                        </IonCol>
                        <IonCol size="6">
                          {nNewProducts && <CardComponent
                            title={"Novos Produtos"}
                            value={`${nNewProducts[0]}`}
                            variation={nNewProducts[1]}
                          ></CardComponent>}
                          {nRecipes && <CardComponent
                            title={"Receitas"}
                            value={`${nRecipes}`}
                            showVariation={false}
                          ></CardComponent>}
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    {displayedText && <IonCol size="6">
                      <div
                        className={`p-5 ${
                          darkMode ? "bg-[#422653]" : "bg-white"
                        } rounded shadow-sm mt-1`}
                      >
                        <div
                          className={`text-base ${
                            darkMode ? "text-gray-200" : " text-gray-400"
                          } `}
                        >
                          Assistente de AI
                        </div>
                        <div
                          className={`${
                            darkMode
                              ? "bg-[#422653] text-gray-200"
                              : " bg-gray-100 text-gray-500"
                          }  rounded mt-3 p-3`}
                        >
                          {displayedText}
                        </div>
                      </div>
                    </IonCol>}
                  </IonRow>
                </IonGrid>
              </IonRow>
            </IonGrid>
          </IonGrid>
        )}
        <Loading isOpen={loading} />
      </div>
    </IonContent>
  );
};

export default Home;
