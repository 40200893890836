
import Page from "../page";
import ProfileCat from "../../../components/App/user-setup/rest-prod-categories";
import { useState } from "react";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import Loading from "../../../components/App/reusable/loading/loading";
import { MdCheckBox } from "react-icons/md";




const RestProdCategoriesPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: 'CATEGORIES SELECT PAGE',
  });
  return (
      <Page title={"Definir categorias de produtos"} icon={<MdCheckBox size={32} />}>
      {tokenVerified ? (<ProfileCat />) : <Loading isOpen={true} />}
      </Page>
  );
};

export default RestProdCategoriesPage;
