import { createContext, useContext, useState, useEffect } from "react";
import { useApi } from "../hooks/useAxiosPrivate";
import CategoryFormatter from "../../components/App/reusable/select/categories";
import { useLocation } from "react-router-dom";

export interface CatInterface {
  [x: string]: any;
  Categories: string[];
  setCat?: (Categories: string[]) => void;
}

const DataContext = createContext<CatInterface>({
  Categories: [],
  setCat: () => {},
});

export const CatProvider = ({ children }) => {
  const { get, setCache, getCache } = useApi();
  const [Categories, setCat] = useState<string[]>([]);

  useEffect(() => {
    fetchData()
      .then((data) => setCat(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const location = useLocation().pathname.replace("/app/", "");

  const fetchData = async () => {
    try {
      const cachedData = getCache(`categories_${location}`);
      if (cachedData) {
        CategoryFormatter.setAll(cachedData);
        return cachedData;
      }
      const response = await get("/sku_category_list/", {
        params: {
          location: location,
        },
      });

      if (response.status !== 201) {
        throw new Error("Failed to fetch categories data");
      }
      
      const data = await response.data;
      setCache(`categories_${location}`, data);
      CategoryFormatter.setAll(data);
      return data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Retry logic
        return await retry(fetchData);
      } else {
        console.log("Error categories fetch:", error.message);
        throw error;
      }
    }
  };

  const retry = async (func) => {
    try {
      return await func();
    } catch (error) {
      console.log(`Retry failed. Retrying.`);
      return await retry(func);
    }
  };

  return (
    <DataContext.Provider value={{ Categories, setCat }}>
      {children}
    </DataContext.Provider>
  );
};

export const useCat = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useCat must be used within a CatProvider");
  }
  return context;
};
