import useAuth from './useAuth';
import axiosPrivate from '../api/axios';
import { Redirect, useHistory } from 'react-router-dom';

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();
    let isRefreshing = false;
    let refreshPromise = null;
    const history = useHistory();
    const refresh = async () => {

        try {
            console.log("Refresh Token Hook!");
            const response = await axiosPrivate.post('/refresh', {
            });
            setAuth((prev) => ({ ...prev, accessToken: response.data.access, roles: response.data.roles, user: response.data.username}));

            // Resolve all pending promises with the new access token
            while (pendingRequests.length > 0) {
                const request = pendingRequests.shift();
                request.resolve(response.data.access);
            }
            isRefreshing = false;
            return response.data.access;
        } catch (error) {
            // Handle refresh error (e.g., redirect to login)
            while (pendingRequests.length > 0) {
                const request = pendingRequests.shift();
                if (error.response && error.response.status === 401) {
                    request.reject(new Error('Unauthorized'));
                    history.push('/login');
                    window.location.reload();
                } else {
                    request.reject(error);
                }
            }
            isRefreshing = false;
            //throw new Error('Unauthorized');
        }
        finally {
            isRefreshing = false;
        }

    };

    const pendingRequests = [];

    const queueRequest = async () => {
        if (!isRefreshing) {
            isRefreshing = true;
            try {
                refreshPromise = refresh();
            } catch (error) {
                console.log('Error refreshing token (Queue request): ', error);
            }
        }

        return new Promise((resolve, reject) => {
            // Queue the request to resolve when the refresh is complete
            pendingRequests.push({ resolve, reject });
        });
    };

    const refreshToken = async () => {
        try {
            // Call queueRequest to start the refresh process if not already in progress
            await queueRequest();

            // Wait for the refresh to complete and return the response
            const response = await refreshPromise;
            return response;
        } catch (error) {
            // Handle refresh error (e.g., redirect to login)
            console.log('Error refreshing token: (RefreshToken)', error);
            throw error;
        }
    };

    return refreshToken;
};

export default useRefreshToken;
