// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myparcels-title {
  text-align: center;
}

@media (min-width: 800px) {
  .myparcels-list {
    width: 50vw;
    margin: 0 auto;
    margin-top: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/App/market/OwnBasketVision.styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAGA;EACE;IACE,WAAA;IACA,cAAA;IACA,gBAAA;EAAF;AACF","sourcesContent":[".myparcels-title {\n  text-align: center;\n}\n\n\n@media (min-width: 800px) {\n  .myparcels-list{\n    width: 50vw;\n    margin: 0 auto;\n    margin-top: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
