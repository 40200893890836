import { useState, useEffect, useRef } from 'react';
import { IonContent, IonGrid} from '@ionic/react';
import  { useApi } from '../../../utils/hooks/useAxiosPrivate';
import useDarkMode from '../../../pages/Profile/usedarkmode';
import ChartComponent from '../reusable/charts/chartComponent';
import SelectComponent from '../reusable/select/select';
import NWeeksFormatter from '../reusable/select/nweeks';
import MyTable from '../reusable/table/table-component';
import NWeeksHelp from '../reusable/NWeeksHelp';
import Loading from '../reusable/loading/loading';
type ChartOptions = Record<string, any>;

const MarginGraphs = () => {
  const [loading, setLoading] = useState(true);
  const [chartLoaded, setChartLoaded] = useState(false);
  const { get } = useApi();
  const [nweeks, setNweeks] = useState<number>(4);
  const [graphData, setGraphData] = useState<Record<string, ChartOptions>>({
    q11: {}, q12: {}, q21: {}, q22: {}, q31: {},
  }); 
  const [lastSalesDate, setLastSalesDate] = useState();
  const chartRef = useRef(null);
  const darkMode = useDarkMode();
  const columns = [
    { headerName: 'Nome', field: 'name' },
    { headerName: 'Margem Acumulada', field: 'margemAcc' },
    { headerName: 'Número de Doses', field: 'nDoses' },
    { headerName: 'Margem Média', field: 'margemAvg' },
  ];

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  function buildBubbleChart(data) {
    if (!data) {
      return undefined;
    }
    return {
      title: {
        text: 'Comparação de Pratos/Receitas entre semana inicial e final',
        subtext: 'Tamanho do circulo representa volume de vendas',
        left: '5%',
        top: '3%',
      },
      legend: {
        right: '3%',
        top: '10%',
        data: ['Inicio', 'Fim'],
      },
      grid: {
        left: '8%',
        top: '10%',
      },
      xAxis: {
        name: 'Margem',
        nameLocation: 'middle',
        nameGap: 30,
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      yAxis: {
        name: 'Número de doses',
        nameLocation: 'middle',
        nameGap: 25,
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
        scale: true,
      },
      series: [
        {
          name: 'Inicio',
          data: data[0],
          type: 'scatter',
          symbolSize: (data) => Math.sqrt(data[2]),
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              formatter: (param) => param.data[3].replace(/ /g, '\n'),
              padding: 0,
              fontSize: 10,
              position: 'inside',
            },
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(120, 36, 50, 0.5)',
            shadowOffsetY: 5,
            color: {
              type: 'radial',
              x: 0.4,
              y: 0.3,
              r: 1,
              colorStops: [
                { offset: 0, color: 'rgb(251, 118, 123)' },
                { offset: 1, color: 'rgb(204, 46, 72)' },
              ],
            },
          },
        },
        {
          name: 'Fim',
          data: data[1],
          type: 'scatter',
          symbolSize: (data) => Math.sqrt(data[2]),
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              formatter: (param) => param.data[3].replace(/ /g, '\n'),
              padding: 0,
              fontSize: 10,
              position: 'inside',
            },
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(25, 100, 150, 0.5)',
            shadowOffsetY: 5,
            color: {
              type: 'radial',
              x: 0.4,
              y: 0.3,
              r: 1,
              colorStops: [
                { offset: 0, color: 'rgb(129, 227, 238)' },
                { offset: 1, color: 'rgb(25, 183, 207)' },
              ],
            },
          },
        },
      ],
    };
  }

  useEffect(() => {
    const controller = new AbortController();

    const fetchGraphData = async () => {
      try {
        const promises = [
          get(`dash6_q11/?nweeks=${nweeks}`),
          get(`dash6_q12/?nweeks=${nweeks}`),
          get(`dash6_q21/?nweeks=${nweeks}`),
          get(`dash6_q22/?nweeks=${nweeks}`),
          get(`dash6_q31/?nweeks=${nweeks}`),
          get('dash4_sales_last_date'),
        ];

        const responses = await Promise.all(promises);
        const [q11Data, q12Data, q21Data, q22Data, q31Data, lastSalesDateData] = responses;

        setGraphData({
          q11: q11Data.data,
          q12: q12Data.data,
          q21: q21Data.data,
          q22: q22Data.data,
          q31: q31Data.data,
        });
        setLastSalesDate(lastSalesDateData.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    if (nweeks) {
      fetchGraphData();
    }

    return () => {
      controller.abort();
    };
  }, [nweeks]);

  return (
    <IonContent className="ion-padding">
      <IonGrid>
        <div className="px-14 flex items-center">
          <div className="w-[30%]">
            <SelectComponent formatter={NWeeksFormatter} darkMode={darkMode} onChange={setNweeks} />
          </div>
          <div className="w-[70%]">{lastSalesDate && <NWeeksHelp nweeks={nweeks} dateLastSales={lastSalesDate} />}</div>
        </div>

        <div className="grid xl:grid-cols-2 gap-4 padding">
          <ChartComponent chartRef={chartRef} option={graphData.q11} handleChartFinished={handleChartFinished} ChangeScale={false} />
          <ChartComponent chartRef={chartRef} option={graphData.q12} handleChartFinished={handleChartFinished} ChangeScale={false} />
          <ChartComponent chartRef={chartRef} option={graphData.q21} handleChartFinished={handleChartFinished} ChangeScale={false} />
          <MyTable
            rowData={graphData.q22}
            columnDefs={columns}
            darkMode={darkMode}
            title="Rank de Margem Bruta acumulada das Receitas"
          />
          <div className='xl:col-span-2'>
          <ChartComponent
            chartRef={chartRef}
            option={buildBubbleChart(graphData.q31)}
            handleChartFinished={handleChartFinished}
            ChangeScale={false}
          />
          </div>
        </div>
      </IonGrid>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default MarginGraphs;