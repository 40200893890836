import  { useEffect, useRef, useState } from 'react';
import { IonContent} from "@ionic/react";
import "../general.styles.scss";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { useCat } from "../../../utils/contexts/dataCat.context";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";

import ChartComponent from "../reusable/charts/chartComponent";
import SelectComponent from "../reusable/select/select";
import Loading from "../reusable/loading/loading";
import NWeeksHelp from "../reusable/NWeeksHelp";
import CategoryFormatter from "../reusable/select/categories";
import NWeeksFormatter from "../reusable/select/nweeks";
import Toaster from '../reusable/Toaster';

const INITIAL_WEEKS = 4;

const FoodCostGraphs = () => {
  const { Categories } = useCat();
  const { get } = useApi();
  const darkMode = useDarkMode();

  const [chartOptions, setChartOptions] = useState({
    q11: {}, q12: {}, q21: {}, q22: {}, q31: {}, q41: {}, q42: {}
  });
  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(true);
  const [chartLoaded, setChartLoaded] = useState(false);
  const chartRef = useRef(null);

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  useEffect(() => {
    if (Categories.length > 0) {
      setCategory(Categories[0]);
    }
  }, [Categories]);

  useEffect(() => {
    const fetchChartData = async () => {
      setLoading(true);
      const controller = new AbortController();

      const fetchGraph = async (endpoint, params = {}) => {
        const queryString = new URLSearchParams(params).toString();
        const url = `dash3_${endpoint}/${queryString ? `?${queryString}` : ''}`;

        try {
          const { data } = await get(url, { signal: controller.signal });
          setChartOptions(prev => ({ ...prev, [endpoint]: data }));
        } catch (error) {
          Toaster.show(`Erro ao carregar dados.`, "error");
          console.error(`Error fetching ${endpoint}:`, error);
        }
      };

      const graphsToFetch = [
        { endpoint: 'q11', params: { nweeks } },
        { endpoint: 'q12', params: { nweeks } },
        { endpoint: 'q21', params: { nweeks } },
        { endpoint: 'q22', params: { nweeks } },
        { endpoint: 'q42', params: { nweeks } },
        { endpoint: 'q41', params: { nweeks, category } },
        { endpoint: 'q31', params: { nweeks, category } },
      ];

      await Promise.all(graphsToFetch.map(({ endpoint, params }) => fetchGraph(endpoint, params)));

      setLoading(false);
      return () => controller.abort();
    };
    if (nweeks && category) {
      fetchChartData();

    }
  }, [nweeks, category]);

  const renderCharts = () => {
    const chartConfigs = [
      { option: chartOptions.q11 },
      { option: chartOptions.q12 },
      { option: chartOptions.q21 },
      { option: chartOptions.q22 },
      { option: chartOptions.q42 },
    ];

    return chartConfigs.map((config, index) => (
      <ChartComponent key={index} handleChartFinished={handleChartFinished} chartRef={chartRef} option={config.option} ChangeScale={false} />
    ));
  };

  const renderCategoryCharts = () => {
    if (Categories.length === 0) return null;

    return (
      <>
        <div className="py-10">
          <SelectComponent
            formatter={CategoryFormatter}
            darkMode={darkMode}
            onChange={setCategory}
          />
        </div>
        <div className="grid xl:grid-cols-2 gap-4 ">
          <ChartComponent chartRef={chartRef} option={chartOptions.q41} ChangeScale={false} />
          <ChartComponent handleChartFinished={handleChartFinished} chartRef={chartRef} option={chartOptions.q31} ChangeScale={false} />
        </div>
      </>
    );
  };

  return (
    <IonContent>
      <div className='padding'>
        <div className="py-4 flex items-center">
          <div className="w-[30%]">
            <SelectComponent
              formatter={NWeeksFormatter}
              darkMode={darkMode}
              onChange={setNweeks}
            />
          </div>
          <div className="w-[70%]">
            <NWeeksHelp nweeks={nweeks} />
          </div>
        </div>
        <div className="grid xl:grid-cols-2 gap-4 ">
          {renderCharts()}
        </div>
        {renderCategoryCharts()}
      </div>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default FoodCostGraphs;