class RecipeUsage {
    static usages = ['I', 'O', 'M'];
    static default = 'I';

    static format(input: string): string {
        return input;
    }

    static getAll() {
        return this.usages;
    }
}
export default RecipeUsage;
